import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from '../environment/environment'

let orgId;
let token;
let adminId;

let setTokenValues = () => {
    orgId = localStorage.getItem("orgId");
    token = localStorage.getItem("token");
    adminId = localStorage.getItem("adminId");
};
export const getTermDetailById = createAsyncThunk("termDetail/getAllTermDetail", async ({proId,term,proMasterId},thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/programMaster/${proMasterId}/program/${proId}/term/${term}/getTermDetailBidding`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);  
export const addTermDetail = createAsyncThunk(
    "term/addTermDetail",
    async (payload, thunkAPI) => {
      try {
        setTokenValues();
        
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/addTermDetail`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        let data = await response.json();
        
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  export const updateTermDetail = createAsyncThunk(
    "term/updateTermDetail",
    async (payload, thunkAPI) => {
      try {
        setTokenValues();
        
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/programMaster/${payload.programMasterId}/program/${payload.programId}/term/${payload.term}/updateTermDetail`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        let data = await response.json();
        
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  export const approveElectiveCourseBid = createAsyncThunk(
    "term/approveElectiveCourseBid",
    async ({obj,studentArr}, thunkAPI) => {
      try {
        setTokenValues();
        const payload={
          obj,
          studentArr
        }
              
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/approveElectiveCourseBid`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        let data = await response.json();
        
        if (response.status === 200) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  
  export const getElectiveBidReqByCourseId = createAsyncThunk("termDetail/getElectiveBidReqByCourseId", async ({proId,courseId},thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/program/${proId}/course/${courseId}/getElectiveBidReqByCourseId`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);  




export const termSlice = createSlice({  
    name: "term",
    initialState: {
      isFetching: false,
      isSuccess: false,
      isError: false,
      bidReqByCourse:[],
      termPlanDetail:[],
      errorMessage: "",
    
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
  
        return state;
      },
    },
    extraReducers: {
      [addTermDetail.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      },
      [addTermDetail.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [addTermDetail.pending]: (state) => {
        state.isFetching = true;
      },
      [approveElectiveCourseBid.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      },
      [approveElectiveCourseBid.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [approveElectiveCourseBid.pending]: (state) => {
        state.isFetching = true;
      },


      
      [updateTermDetail.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      },
      [updateTermDetail.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [updateTermDetail.pending]: (state) => {
        state.isFetching = true;
      },
      [getTermDetailById.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.termPlanDetail=payload
        return state;
      },
      [getTermDetailById.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getTermDetailById.pending]: (state) => {
        state.isFetching = true;
      },
      [getElectiveBidReqByCourseId.fulfilled]: (state, { payload }) => {
        state.isFetching = false;
        state.bidReqByCourse=payload
        return state;
      },
      [getElectiveBidReqByCourseId.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [getElectiveBidReqByCourseId.pending]: (state) => {
        state.isFetching = true;
      },
    },
  }); 
  
  export const { clearState } = termSlice.actions;
  
  export const termSelector = (state) => state.term;
  