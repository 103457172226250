import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import CustomUploadBox from "../../globalComponents/customUploadBox/CustomUploadBox";
import "./UploadCSVPopupStyle.css";
import { CSVLink } from "react-csv";
const UploadCSVPopup = ({
  title,
  isdisabled,
  onUploadClick,
  isOpen,
  setIsOpen,
  onChange,
  value,
  newValue,
  handleRemove,
  csvData,
}) => {
  return (
    <div className={`popupOuter ${isOpen ? "active" : ""}`}>
      <div className="uploadPopupContainer">
        <div className="popupHeader">
          <p className="popupTitle">{title}</p>
          <img
            onClick={() => {
              setIsOpen(false);
              handleRemove();
            }}
            src="/assets/icons/x-close.svg"
            alt=""
          />
        </div>
        <div className="popupBody">
          <div className="uploadFileContainer">
            <CustomUploadBox
              handleRemove={handleRemove}
              value={value}
              newValue={newValue}
              onChange={onChange}
              uid={"csv"}
            />
            <PrimaryButton
              isdisabled={isdisabled}
              onClick={onUploadClick}
              classname={"primaryBtn"}
              children={"Upload"}
            />
          </div>
          {/* <p onClick={onDownloadClick} className='downloadCsvBtn'>Download Sample File</p> */}
          <div className="mt-10">
            <CSVLink data={csvData ? csvData : ""}>
              Download Sample File
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCSVPopup;
