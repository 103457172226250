import React from 'react'
import { useState } from 'react'
import './CourseBiddingStyle.css'
import PrimaryButtonImg from '../../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
import Checkbox from '../../../components/globalComponents/checkboxes/Checkbox'
import BreadCrumbs from '../../../components/globalComponents/breadCrumbs/BreadCrumbs'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    courseSliceData,
    getAllProgramTerm,
    getAllCourseByTerm,
    clearCoursesliceState,
} from "../../../reducers/courseSlice";
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../components/globalComponents/dropdown/Dropdown'
import { getTermDetailById, termSelector, getElectiveBidReqByCourseId, approveElectiveCourseBid, updateTermDetail } from '../../../reducers/termSlice'
import PageLoader from '../../../components/PageLoader/PageLoader'



const CourseBidding = () => {
    const [toggler, setToggler] = useState(false)
    const [term, setTerm] = useState({ value: "1", apply: false })
    const [course, setCourse] = useState("");
    const [studentArr, setStudentArr] = useState([]);


    const [searchParams] = useSearchParams();
    const proId = searchParams.get("proId") ?? "";
    const proMasterId = searchParams.get("proMasterId") ?? "";
    const proName = searchParams.get("proName") ?? "";
    const layers = [proName ?? "PGP-TBM", 'All Bidding Request'];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isSuccess, isFetching, termPlanDetail, bidReqByCourse } = useSelector(termSelector);
    const {

        getTerm,
        getCourseByTerm,
        termFetched,
        courseByTerm,

    } = useSelector(courseSliceData);

    useEffect(() => {
        let obj = {
            proId: proId,
            proMasterId: proMasterId,
        };
        dispatch(getAllProgramTerm(obj));
    }, [])
    console.log(termPlanDetail, "ajjjj iddddd");

    useEffect(() => {

        if (termFetched || term.apply) {
            let obj = {
                proId: proId,
                term: term.value,
            };
            dispatch(getAllCourseByTerm(obj));
            dispatch(getTermDetailById({ ...obj, proMasterId: proMasterId, term: term.value }))
            dispatch(clearCoursesliceState());
            setTerm(prev => ({ ...prev, apply: false }));
        }
    }, [termFetched, term.apply])

    useEffect(() => {
        if (courseByTerm && getCourseByTerm?.length > 0) {

            getElectiveBidReq(getCourseByTerm[0]?.id)
            dispatch(clearCoursesliceState());
        }
    }, [courseByTerm])

    useEffect(() => {
        if (termPlanDetail?.id) {
            setToggler(termPlanDetail?.isBiddingOpen)
        }
    }, [termPlanDetail])

    const getElectiveBidReq = (id) => {
        if (course !== id) {
            setCourse(id);
            setStudentArr([]);
            dispatch(getElectiveBidReqByCourseId({ proId: proId, courseId: id }));
        }
    }


    const handleTermPlanDetails = () => {
        navigate(`/termPlanDetails?proId=${proId}&proMasterId=${proMasterId}&proName=${proName}&term=${term.value}`, {
            exact: true,
        });
    }

    const handleSaveSelected = () => {
        const obj = {
            proId,
            proMasterId,
            course,

        };

        dispatch(approveElectiveCourseBid({ obj, studentArr }))
    }
    const enableTermBidding = () => {
        console.log("rgfgfgfrrr", termPlanDetail?.minimumElective, !termPlanDetail?.minimumElective)
        const payload = {
            programMasterId: proMasterId,
            startDate: termPlanDetail?.startDate,
            endDate: termPlanDetail?.endDate,
            programId: proId,
            term: termPlanDetail?.term,
            minElectiveCourse: termPlanDetail?.minElectiveCourse,
            isBiddingOpen: !termPlanDetail?.isBiddingOpen
        }

        dispatch(updateTermDetail(payload));
    }


    const handleTermChange = (e) => {
        if (term.value !== e.target.value) setTerm({ value: e.target.value, apply: true });
    }
    return (
        <>
            {(isFetching || termFetched) && <PageLoader />}
            <div className="pageHeader">
                <BreadCrumbs layers={layers} />
                {termPlanDetail?.id && <div className="togglerContainer">
                    <p>Enable Term Bidding</p>
                    <div className={`toggler ${toggler ? 'active' : ''}`} onClick={() => { setToggler(!toggler); enableTermBidding() }}>
                        <div className="togglerBtn"></div>
                    </div>
                </div>}
            </div>
            <div className="filterBar">
                <div className="filterLeft">
                    <div className="courseBtnOuter">
                        {getTerm.length > 0 && <select className='customSelect' onChange={handleTermChange}>
                            {getTerm.map((val, index) => <option key={index} value={val}>Term {val}</option>)}
                        </select>}
                        <div className="courseBtn" >Course Name</div>
                        {
                            getCourseByTerm?.length > 0 && getCourseByTerm.map((val, index) => <div className={`courseBtn ${val.id === course ? "active" : ""}`} key={index} onClick={() => getElectiveBidReq(val.id)}>{val.courseName}</div>)}
                    </div>
                </div>
                <div className="filterRight">
                    <PrimaryButton classname={'primaryBtn'} children={'Term Plan Details'} onClick={handleTermPlanDetails} />
                </div>
            </div>
            <div className="courseBiddingTableContainer">
                <div className="courseBiddingTableOuter">
                    {console.log(bidReqByCourse)}
                    <table className="courseBiddingTable" cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th>Student Name</th>
                                <th>E-Mail ID</th>
                                <th>Course Alloted Count</th>
                                <th><div className="tableCheckbox"> <Checkbox /> Course Opted</div></th>
                            </tr>
                        </thead>
                        <tbody>

                            {bidReqByCourse.length > 0 && bidReqByCourse.map((val, index) => <tr key={index}>
                                <td className=''><div className='courseBiddingStudent'><div className={`greenDot ${val?.isBidSelected ? "active" : ""}`}></div> {val?.Student?.firstName + " " + val?.Student?.lastName}</div></td>
                                <td>{val?.Student?.officialEmail}</td>
                                <td>
                                    <div className="courseAllotedCount">
                                        <p className="primaryText pointer">9</p>
                                    </div>
                                </td>
                                <td>
                                    <Checkbox label={val?.Student?.id} onChange={setStudentArr} hide={true} name={val?.bidPoint} value={studentArr} />
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="courseBiddingFooter">
                    <p>Total Student Selected - <span>{studentArr.length}</span></p>
                    {studentArr.length > 0 && <PrimaryButtonImg img={'assets/icons/arrow-narrow-right-green.svg'} classname={'successOutlineBtn'} children={'Save Selected'} onClick={handleSaveSelected} />}
                </div>
            </div>
        </>
    )
}

export default CourseBidding