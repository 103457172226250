import React from "react";
import './customUploadBoxStyle.css';

const CustomUploadBox = ({ label,onChange ,value,handleRemove,accept,newValue ,isImportant, uid}) => {

    return (
        <div className="uploadBoxOuter">
        {console.log(value)}
            <p className="label">{label} {isImportant? <span className='impMark'>*</span>:null }</p>
            <div className="upload-box-inner">
                <label htmlFor={uid}>
                    <span className='upload-btn'>Choose File</span>
                   {!value? "No File Chosen":value }
                </label>
               {!value? <input type="file" id={uid} value={newValue} onChange={onChange} accept={accept} />: <input type="file" id={uid} disabled/> }

                <div class="upload-box-icons active">
                    {
                        value &&
                        <img src="../assets/icons/trash-01.svg" alt="" onClick={handleRemove}/>
                    }
                </div>

            </div>
        </div>
    )
}

export default CustomUploadBox