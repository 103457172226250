import React from 'react'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import './CreateClubStyle.css'
import TextField from '../../../components/globalComponents/textFields/TextField'
import TextArea from '../../../components/globalComponents/textArea/TextArea'
import Dropdown from '../../../components/globalComponents/dropdown/Dropdown'
// import UploadBox from '../../../components/UploadBox/UploadBox'
import CustomUploadBox from '../../../components/globalComponents/customUploadBox/CustomUploadBox'
import Checkbox from '../../../components/globalComponents/checkboxes/Checkbox'
const CreateClub = () => {
  return (
    <>
      <div className="pageHeaderType3">
        <div className="backBtn">
          <img src="/assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div className="formTogglers">
          <div className="formToggler active">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM14.4006 7.73345C14.6447 7.48937 14.6447 7.09364 14.4006 6.84956C14.1565 6.60548 13.7608 6.60548 13.5167 6.84956L8.95866 11.4076L6.9006 9.34956C6.65652 9.10548 6.2608 9.10548 6.01672 9.34956C5.77264 9.59364 5.77264 9.98937 6.01672 10.2334L8.51672 12.7334C8.76079 12.9775 9.15652 12.9775 9.4006 12.7334L14.4006 7.73345Z" fill="#404040" />
            </svg>

            <p>Club Details</p>
          </div>
          <div className="formToggler">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM14.4006 7.73345C14.6447 7.48937 14.6447 7.09364 14.4006 6.84956C14.1565 6.60548 13.7608 6.60548 13.5167 6.84956L8.95866 11.4076L6.9006 9.34956C6.65652 9.10548 6.2608 9.10548 6.01672 9.34956C5.77264 9.59364 5.77264 9.98937 6.01672 10.2334L8.51672 12.7334C8.76079 12.9775 9.15652 12.9775 9.4006 12.7334L14.4006 7.73345Z" fill="#404040" />
            </svg>

            <p>Upload Documents</p>
          </div>
          <div className="formToggler">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM14.4006 7.73345C14.6447 7.48937 14.6447 7.09364 14.4006 6.84956C14.1565 6.60548 13.7608 6.60548 13.5167 6.84956L8.95866 11.4076L6.9006 9.34956C6.65652 9.10548 6.2608 9.10548 6.01672 9.34956C5.77264 9.59364 5.77264 9.98937 6.01672 10.2334L8.51672 12.7334C8.76079 12.9775 9.15652 12.9775 9.4006 12.7334L14.4006 7.73345Z" fill="#404040" />
            </svg>

            <p>Club Members</p>
          </div>
        </div>
        <div className="pageHeaderRight">
          <PrimaryButton children={'Save'} classname={'successSecondaryBtn'} />
          <PrimaryButton children={'Publish'} classname={'dangerBtn'} />
          <PrimaryButton children={'Save'}  classname={'successBtn'}/>
        </div>
      </div>
      <div className="createClubContainer">
        <div className="createClubContainerOne"  >
          {/* <div className="createClubHeader">
            <div className="addClubBackgroundImg">
              <img src="https://images.pexels.com/photos/46160/field-clouds-sky-earth-46160.jpeg?auto=compress&cs=tinysrgb&w=1600" alt=""
                className='clubBackgroundImg' />

            </div>
            <div className="clubProfile">
              <img src="https://images.pexels.com/photos/13187759/pexels-photo-13187759.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load" alt="" className='clubProfilePhoto' />
              <img src="/assets/icons/camera-01.svg" alt="" className='addProfilePhoto' />
            </div>
          </div> */}
          <form className='formOuter'>
            <TextField labelName={'Club Name'} inputType={'text'} placeholder={'Create club'} />
            <TextField labelName={'Mission of your Club'} inputType={'text'} placeholder={'Mission of your Club'} />
            <TextField labelName={'Vision of your Club'} inputType={'text'} placeholder={'Vision of your Club'} />
            <Dropdown label={'ClubType'} />
            <TextArea labelName={'Why do you want to form the club?'} placeholder={'Reason for forming the club'} />
          </form>
          <div className="createClubContainerFooter flexEnd">
            <PrimaryButton classname={'secondaryBtn'} children={'Next'} />
          </div>
        </div>
        <div className="createContainerSecond" style={{ display: 'none' }}>
          <div className="createContainerSecondInner">
            <CustomUploadBox label={'Club Guiding Document'} isImportant={true} />
            <CustomUploadBox label={'Club Reports'} isImportant={true} />
            <div className="createContainerTableOuter">
              <table className="createClubTable" cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th>Club Report</th>
                    <th>Size</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Program in Finance.pdf</td>
                    <td>140 KB</td>
                    <td>5 July 2020</td>
                    <td style={{ textAlign: 'right' }}><img src="/assets/icons/trash-01.svg" alt="" className='deleteIcon' /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="createClubContainerFooter">
            <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Back'} />
            <PrimaryButton classname={'secondaryBtn'} children={'Next'} />
          </div>
        </div>
        <div className="createContainerThird" style={{ display: 'none' }} >
          <div className="filterBar">
            <div className="searchBox">
              <img src="/assets/icons/search-sm.svg" alt="" />
              <input type="text" className="searchInput" placeholder='Search ...' />
            </div>
            <div className="filterDropdwon">
              <Dropdown />
              <Dropdown />
              <Dropdown />
            </div>
          </div>
          <div className="addMembersTableOuter">
            <table className="addMemberTable" cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th><Checkbox/></th>
                  <th>Students</th>
                  <th>Course</th>
                  <th>Cohort</th>
                  <th>President</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Checkbox/></td>
                  <td>Anand Krishnan</td>
                  <td>Applied Finance</td>
                  <td>Cohort - 1</td>
                  <td><PrimaryButton classname={'successSecondaryBtnSmall'} children={"President"}/></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="createClubContainerFooter">
            <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Back'} />
          </div>
        </div>

      </div>
    </>
  )
}

export default CreateClub