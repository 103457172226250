import { configureStore } from "@reduxjs/toolkit";
import { announcementSlice } from "../announcementSlice";
import { userSlice } from "../userSlice";
import { courseSlice } from "../courseSlice";
import { adminSlice } from "../adminSlice";
import { assignmentSlice } from "../assignmentSlice";
import { gradeSlice } from "../gradeSlice";
import { feedbackSlice } from "../feedbackSlice";
import { commonSlice } from "../commonSlice";
import { termSlice } from "../termSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    announcement: announcementSlice.reducer,
    courseData: courseSlice.reducer,
    adminData: adminSlice.reducer,
    assignment: assignmentSlice.reducer,
    grade: gradeSlice.reducer,
    feedback: feedbackSlice.reducer,
    commonData: commonSlice.reducer,
    term:termSlice.reducer
  },
});
