import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import PrimaryBtnDropdown from "../../globalComponents/buttons/primaryBtnDropdown/PrimaryBtnDropdown";
import Notification from "../../notificationComponent/Notification";
import SideMenu from "../sideMenu/SideMenu";
import "./HeaderStyle.css";

const Header = ({ setLogout }) => {
  // const options = ["Mastercamps", "PGP-TBM"];
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseNotification = () => {
    setShowNotifications(false);
  };
  return (
    <>
      <Notification
        isOpened={showNotifications}
        handleCloseNotification={handleCloseNotification}
      />
      {/* <SideMenu
        setIsOpened={setShowMenu}
        isOpened={showMenu}
        setLogout={setLogout}
      /> */}
      <div className="headerOuter">
        <div className="headerLeft">
          {/* <img
            src="/assets/icons/hamburgerMenuIcon.svg"
            alt=""
            className={`pointer hamburger ${showMenu ? "active" : ""}`}
            onClick={() => setShowMenu(!showMenu)}
          /> */}
          
          <NavLink  to="/">
          <img src="/assets/icons/muLogoWhite.svg" alt="" />
          </NavLink>
        </div>
        <div className="headerCenter">
          {/* <p className="programName">Applied Finance Cohort’ 22</p>
          <p className="darkTag">Term 1</p> */}
        </div>
        <div className="headerRight">
          {/* <PrimaryBtnDropdown classname={"darkBtn"} options={options} /> */}
        </div>
      </div>
    </>
  );
};

export default Header;
