import React from 'react'
import './CheckboxStyle.css'
const Checkbox = ({ label, value, onChange, hide, select, ind, disable, getStudent,name }) => {

    // useEffect(()=>{

    //     if(getStudent) getStudent(label)
    // },[ind])


    const handleChange = (e) => {
        if ( ind!== (null || undefined) || ind===0) {
          
            if (e.target.checked === true) {
                // setGroupAssignmentArray((values)=>({...values,[index]:{...values[index],
                //     [name]:value
                //     }   }))
                // }
                // onChange(prev => [...prev, e.target.value])
                
                
                if (value[ind]) {
                    onChange(prev => ({ ...prev, [ind]: [...prev[ind], e.target.value] }))
                    
                }
                else {
                    onChange(prev => ({ ...prev, [ind]: [e.target.value] }))
                }
                
            }
            
            else {
                const arr = value[ind].filter(a => a !== e.target.value);
                onChange(prev => ({ ...prev, [ind]: arr }));
            }
        }
        else {
           
            if (e.target.checked === true) {
                onChange(prev => [...prev, e.target.value])
            }
            else {
                const arr = value.filter(a => a !== e.target.value);
                onChange(arr);
            }

        }
    }



    return (
        <>
            <div className="checkmarkContainerOuter">
                <label class="checkboxContainer">
                    {/* {console.log(getStudent ? getStudent(label) : "")} */}
                    {/* {console.log(disable)} */}

                    { ind!== (null || undefined) || ind===0 ? <input type="checkbox" value={label} onChange={handleChange} disabled={getStudent ? getStudent(label) : ""} checked={ value[ind] ? value[ind].includes(label) : false} /> : <input type="checkbox" value={label} onChange={handleChange} checked={value?.includes(label)}/>}
                    <span class="checkmark"></span>
                    {hide === true  ? "" : label}
                    {name? name : ""}
                   
                </label>
                {/* <label class="checkboxContainer">
                    {value.length === 0 ? <input type="checkbox" checked={false} value={label} onChange={handleChange} /> :
                    <input type="checkbox" value={label} onChange={handleChange} />}
                    <span class="checkmark"></span>
                    {label}
                </label> */}
            </div>
        </>
    );
};

export default Checkbox;