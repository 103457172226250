import React from 'react'
import './ClubMemberListPopupStyle.css'
const ClubMemberListPopup = ({isOpen}) => {
    return (
        <div className={`popupOuter ${isOpen && 'active'}`}>
            <div className="clubMemberContainer">
                <div className="popupHeader">
                    <p className="title">Club Members</p>
                    <img src="/assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    <div className="clubMemberListTableOuter">
                        <table className="clubMemberListTable" cellSpacing={0} cellPadding={0}>
                            <thead>
                                <tr>
                                    <th>Students</th>
                                    <th>Course</th>
                                    <th>Cohort</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Anand Krishnan</td>
                                    <td>Applied Finance</td>
                                    <td>Cohort - 1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClubMemberListPopup