import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { clearState, loginUser, userSelector } from '../../reducers/userSlice';
import { useNavigate } from 'react-router-dom';
import { COMMON_TEXT } from '../../constants/textConstants';
import './LoginStyle.css'
import PageLoader from '../../components/PageLoader/PageLoader';
import ErrorToast from '../../components/Toast/ErrorToast';

const Login = () => {

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState(false);

    //toast states
    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");

    const dispatch = useDispatch();
    const { isFetching, isSuccess, isError, errorMessage} =
    useSelector(userSelector);
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        let mEmailAddress = e.target.value;
        setEmail(e.target.value);
        const filter =
        /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (mEmailAddress === "") {
        setEmailValidation(true);
        } else if (!filter.test(mEmailAddress)) {
        setEmailValidation(true);
        } else {
        setEmailValidation(false);
        }
    }

    const handlePassChange = (e) => {
        let mPassword = e.target.value;
        setPass(e.target.value);
        if (mPassword === "") {
        setPasswordValidation(true);
        } else {
        setPasswordValidation(false);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (email && pass && email.length > 0 && pass.length > 0) {
          var data = {
            email: email,
            password: pass,
          };
          dispatch(loginUser(data));
          
        }
        else {
            setIsShowError(true);
            setErrMessage("Both fields must not be empty !");
        }
    }

    const EnterSubmit = (e) => {
       if(e.key === 'Enter'){
        if (email && pass && email.length > 0 && pass.length > 0) {
          var data = {
            email: email,
            password: pass,
          };
          dispatch(loginUser(data));
          
        }
        else {
            setIsShowError(true);
            setErrMessage("Both fields must not be empty !");
        }
      }
    }
    
    useEffect(() => {
        return () => {
          dispatch(clearState());
        };
        // eslint-disable-next-line
      }, []);
    //   let isAuth = localStorage.getItem("token");
      useEffect(() => {
        let isAuth = localStorage.getItem("token");
        if (isAuth && isAuth !== "undefined" && isAuth !== "null") {
          navigate("/", { replace: true });
        }
        // eslint-disable-next-line
      }, []);

      useEffect(() => {
        if (isError) {
          // toast.error(errorMessage);
          setIsShowError(true);
          setErrMessage(errorMessage);
          dispatch(clearState());
        }
    
    
        if (isSuccess) {
          dispatch(clearState());
          localStorage.setItem("isLoggedIn", "true");
    
        //   dispatch(checkLoginStatus(true));
          navigate("/", { replace: true });
        }
        // eslint-disable-next-line
      }, [isError, isSuccess]);

    return (
      <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
        <div className="loginContainer">
            <div className="loginContainerLeft">
                <img src="/assets/img/loginImg.png" alt="" />
                <h1 className="heading">Learning Management System</h1>
                {/* <p className="subTitleText">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id urna id enim placerat viverra eu vel nibh. </p> */}
            </div>
            <div className="loginContainerRight">
                <img src="/assets/icons/muLogoBlack.svg" alt="" />
                <p className="welcomeText">Welcome Back</p>
                {/* <p className="welcomeSubTitle">publishing industries for previewing layouts and visual mockups..</p> */}
                <div className="loginFormContainer">
                    <div className="formGroup">
                        <label htmlFor="email">Email Id</label>
                        <input onKeyPress={EnterSubmit} className='loginInput' type="email" name="email" id="email" placeholder='john.do@gmail.com' onChange={handleEmailChange} />
                    </div>
                    {emailValidation && (
                    <span className="msg-error" id="nameValidation">
                      {COMMON_TEXT.emailAlert}
                    </span>
                  )}
                    <div className="formGroup mt5">
                        <label htmlFor="password">Password</label>
                        <input onKeyPress={EnterSubmit} className='loginInput' type="password" name="password" id="password" placeholder='•••••••••' onChange={handlePassChange}/>
                    </div>
                    {passwordValidation && (
                    <span className="msg-error" id="passValidation">
                      {COMMON_TEXT.passwordAlert}
                    </span>
                  )}
                    {/* <p className="frogetLink">Forget Password?</p> */}
                    <button type='submit' onClick={onSubmit} className="signInButtton">Sign in</button>
                    {/* <button className="signInwthGoogle"> <img src="/assets/icons/googleIcon.svg" alt="" /> Sign up with Google</button> */}
                    <div className="copyrightText">
                        <p>Copyright @masters' union</p> |
                        <p>Privacy Policy</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login
