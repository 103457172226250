import React, { useState, useEffect } from "react";
import "./AddCourseStyle.css";
import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
import TextField from "../../../components/globalComponents/textFields/TextField";
// import CustomDatePicker from "../../../components/globalComponents/customDatePicker/CustomDatePicker";
import CustomUploadBox from "../../../components/globalComponents/customUploadBox/CustomUploadBox";
// import Dropdown from "../../../components/globalComponents/dropdown/Dropdown";
import moment from "moment/moment";
import TermPlanDetailsPopup from "../../../components/popups/termPlanDetailsPopup/TermPlanDetailsPopup";
import {
  addCourseRoster,
  courseSliceData,
  getAllProgramTerm,
  getAllCourseByTerm,
  getAllSessionByCourseId,
  clearState,
  addCourseSession,
  deleteCourseRoster,
  deleteCourseSession,
  editCourseRoster,
  editCourseSession,
  downloadSampleCourseCSV,
  downloadSampleSessionCSV,
  addCourseSessionCSV,
  addCourseRosterCSV,
  getAllFaculties,
  getAllProClusters,
  addTerm,
  getAllTermDetail,
} from "../../../reducers/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import PageLoader from "../../../components/PageLoader/PageLoader";
import SunEditor from "suneditor-react";
import ErrorToast from "../../../components/Toast/ErrorToast";

import * as filestack from "filestack-js";
import UploadCSVPopup from "../../../components/popups/uploadCSVPopup/UploadCSVPopup";
// import FileViewer from "../../../components/globalComponents/fileViewer/FileViewer";

const client = filestack.init("AXgXGi3jhSyiQo8NOKNfWz");

const AddCourse = () => {
  const dispatch = useDispatch();
  const {
    isSuccess,
    isError,
    errorMessage,
    isFetching,
    getTerm,
    getCourseByTerm,
    getSessionByCourse,
    courseCSVData,
    sessionCSVData,
    getAllFacultiesData,
    isCsvSuccess,
    allCluster,
    sessionSuccess,
    addTermSuccess,
    courseSuccess,
    getAllTermDetailData,
  } = useSelector(courseSliceData);
  console.log(isCsvSuccess, "isCsvSuccess");
  const [searchParams] = useSearchParams();
  const [courseId, setCourseId] = useState("");
  const proId = searchParams.get("proId") ?? "";
  const proMasterId = searchParams.get("proMasterId") ?? "";
  // const proName = searchParams.get("proName") ?? "";
  const [termId, setTermId] = useState("");
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const [url, setUrl] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const token = {};

  let courseData = {
    courseName: "",
    courseCode: "",
    hours: "",
    credits: "",
    term: termId,
    startDate: "",
    endDate: "",
    isPublished: false,
    programId: proId,
    programMasterId: proMasterId,
    description: "",
    entity:
      proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3"
        ? "MasterUnion"
        : "MasterCamp",
  };
  let sessionData = {
    name: "",
    date: "",
    fromTime: "",
    toTime: "",
    description: "",
    programId: proId,
    programMasterId: proMasterId,
  };
  let termData = {
    startDate: "",
    endDate: "",
    term: "",
    minElectiveCourse: "",
    entity:
      proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3"
        ? "MasterUnion"
        : "MasterCamp",
  };
  const [courseDetails, setCourseDetails] = useState({ ...courseData });
  const [sessionDetails, setSessionDetails] = useState({ ...sessionData });
  const [termDetails, setTermDetails] = useState({ ...termData });
  const [faculty, setFaculty] = useState("");
  const [cluster, setCluster] = useState("");
  const [courseType, setCourseType] = useState("");
  const [courseSubType, setCourseSubType] = useState("");
  const [numberOfStu, setNumberOfStu] = useState("");
  const [sessionToggle, setSessionToggle] = useState(0);
  const [courseToggle, setCourseToggle] = useState(0);
  const [formToggler, setFormToggler] = useState(false);
  const [editMenuToggler, setEditMenuToggler] = useState(false);
  const [editCourseId, setEditCourseId] = useState("");
  const [editSessionId, setEditSessionId] = useState("");
  // const [editSessionToggle, setEditSessionToggle] = useState(true);
  const [courseSessionToggle, setCourseSessionToggle] = useState(true);
  const [termCount, setTermCount] = useState([]);
  const [termToggle, setTermToggle] = useState(0);
  const [addTermToggle, setAddTermToggle] = useState(false);
  const [uploadBoxToggler, setUploadBoxToggler] = useState(false);

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  //Session
  const [newSessionEdit, setNewSessionEdit] = useState(true);

  //Add Files
  const [fileArrayData, setFileArrayData] = useState([]);
  const [addFilesCount, setaddFilesCount] = useState([0]);

  //Add CSV
  const [csvFile, setCsvFile] = useState("");

  // Term PopUp
  const [termPopupShow, setTermPopupShow] = useState(false);

  useEffect(() => {
    let obj = {
      proId: proId,
      proMasterId: proMasterId,
    };
    // dispatch(getAllProgramTerm(obj));
    dispatch(getAllTermDetail(obj));
    // dispatch(getAllFaculties());
    // dispatch(getAllProClusters(obj));
    // eslint-disable-next-line

    if (
      courseSessionToggle &&
      proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3"
    ) {
      let obj = {
        proId: proId,
        proMasterId: proMasterId,
      };
      dispatch(getAllProClusters(obj));
    }
  }, []);

  useEffect(() => {
    setCourseDetails((prevState) => ({
      ...prevState,
      term: termId,
    }));
  }, [termId]);

  useEffect(() => {
    if (getAllTermDetailData?.length > 0) {
      setTermId(getAllTermDetailData[0]?.term);
    } else {
      setTermId("");
    }
  }, [getAllTermDetailData]);

  useEffect(() => {
    if (newSessionEdit && getCourseByTerm?.length > 0) {
      let newObj = {
        proId: proId,
        courseId: getCourseByTerm?.length > 0 && getCourseByTerm[0]?.id,
      };
      // if(!courseSessionToggle){
      dispatch(getAllSessionByCourseId(newObj));
      // }
      setCourseId(getCourseByTerm[0]?.id);
    }
    // eslint-disable-next-line
  }, [getCourseByTerm]);

  useEffect(() => {
    let obj = {
      proId: proId,
      term: termId,
    };
    if (termId) {
      dispatch(getAllCourseByTerm(obj));
    }
    // eslint-disable-next-line
  }, [termId]);

  console.log(termId, "termId");

  const courseValidating = () => {
    if (courseDetails.courseName === "") return "Please fill Course Name";
    if (courseDetails.courseCode === "") return "Please fill Course Code";
    if (courseDetails.hours === "") return "Please fill Hours";
    if (courseDetails.credits === "") return "Please fill Credits";
    if (courseDetails.description === "") return "Please fill Description";
    if (courseDetails.startDate === "") return "Please fill Start Date";
    if (courseDetails.endDate === "") return "Please fill End Date";
    if (proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3") {
      if (cluster === "") return "Please select cluster";
      if (!courseSubType) return "Please select course type";
      if (
        courseSubType !== "coreCompulsory" &&
        courseSubType !== "" &&
        !numberOfStu
      )
        return "Please fill no. of students";
    }
    return false;
  };

  const sessionValidating = () => {
    if (sessionDetails.name === "") return "Please fill Name";
    if (sessionDetails.date === "") return "Please fill Date";
    if (faculty === "") return "Please fill Faculty";
    if (sessionDetails.fromTime === "") return "Please fill Start Time";
    if (sessionDetails.toTime === "") return "Please fill End Time";
    if (sessionDetails.fromTime === sessionDetails.toTime)
      return "Start Time & End Time Should Not Be Same";
    if (sessionDetails.description === "") return "Please fill Description";
    return false;
  };

  const courseDetailsHandleChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const sessionDetailsHandleChange = (e) => {
    const { name, value } = e.target;
    setSessionDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const termDetailsHandleChange = (e) => {
    const { name, value } = e.target;
    setTermDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const courseDetailsSubmit = () => {
  //   dispatch(addCourseRoster(courseDetails));
  // };

  // const sessionDetailsSubmit = () => {
  //   let facultyId = faculty?.value;
  //   dispatch(addCourseSession({ sessionDetails, courseId, facultyId }));
  // };

  const courseDetailsSubmit = () => {
    // const errormsg = false;
    const errormsg = courseValidating();
    if (errormsg) {
      setErrMessage(errormsg);
      setIsShowError(true);
    } else {
      if (proMasterId !== "53e08f7a-a03b-42f3-b181-064d1afc05e3") {
        dispatch(addCourseRoster({ courseDetails }));
      } else {
        dispatch(
          addCourseRoster({
            courseDetails,
            courseType:
              courseSubType !== ""
                ? courseSubType === "coreCompulsory"
                  ? "core"
                  : "elective"
                : "",
            courseSubType,
            cluster,
            numberOfStu,
          })
        );
      }
    }
  };
  const editCourseSubmit = () => {
    // if (editCourseId) {
    //   dispatch(editCourseRoster({ courseDetails, editCourseId }));
    // }
    if (
      proMasterId !== "53e08f7a-a03b-42f3-b181-064d1afc05e3" &&
      editCourseId
    ) {
      dispatch(editCourseRoster({ courseDetails, editCourseId }));
    } else if (
      proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3" &&
      editCourseId
    ) {
      dispatch(
        editCourseRoster({
          courseDetails,
          courseType:
            courseSubType !== ""
              ? courseSubType === "coreCompulsory"
                ? "core"
                : "elective"
              : "",
          courseSubType,
          cluster,
          numberOfStu,
          editCourseId,
        })
      );
    }
  };

  const sessionDetailsSubmit = () => {
    const errormsg = sessionValidating();
    if (errormsg) {
      setErrMessage(errormsg);
      setIsShowError(true);
    } else {
      let facultyId = faculty?.value;
      dispatch(
        addCourseSession({
          sessionDetails,
          courseId,
          facultyId,
          videoUrl: url,
          fileArrayData: fileArrayData,
          videoName: selectedKey,
        })
      );
    }
  };

  const handleRemove = () => {
    setUrl("");
    setSelectedKey("");
  };
  const handleRemoveFiles = (i) => {
    const arr = fileArrayData;
    arr.splice(i, 1);
    setFileArrayData([...arr]);
    if (
      addFilesCount.length > 1 &&
      addFilesCount.length - fileArrayData.length > 1
    ) {
      const arr1 = addFilesCount;
      arr1.pop();
      setaddFilesCount([...arr1]);
    }
  };

  useEffect(() => {
    console.log(fileArrayData);
  }, [fileArrayData]);

  const handleUploadVideo = (event) => {
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      setLoad(true);
      client
        .upload(files, {}, token)
        .then((res) => {
          if (res) {
            setSelectedKey(res.name);
            setUrl(res.url);
            setLoad(false);
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          setLoad(false);
          setIsShowError(true);
          setErrMessage(`${err.status}!`);
        });
    }
  };

  const handleUploadFiles = (e, i) => {
    if (e.target.files.length > 0) {
      const files = e.target.files[0];
      setLoad(true);
      client
        .upload(files, {}, token)
        .then((res) => {
          if (res) {
            const obj = {
              fileName: res.name,
              fileUrl: res.url,
            };
            const arr = fileArrayData;
            arr[i] = obj;
            setFileArrayData([...arr]);
            setLoad(false);
          } else {
            setLoad(false);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          setLoad(false);
          setIsShowError(true);
          setErrMessage(`${err.status}!`);
        });
    }
  };

  const courseToggleFun = ({ res, i }) => {
    setCourseToggle(i);
    setCourseId(res.id);
    setEditMenuToggler(false);
    setFormToggler(false);
    setTermId(res?.term);
    handleRemove();
    setNewSessionEdit(true);
    let obj = {
      proId: proId,
      courseId: res.id,
    };
    dispatch(getAllSessionByCourseId(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      setEditMenuToggler(false);
      setFormToggler(false);
      // setEditSessionToggle(false);
      // setCourseSessionToggle(false);
      termPopupClose();
      setNewSessionEdit(false);
      handleRemove();
      setCourseDetails({ ...courseData });
      setSessionDetails({ ...sessionData });
      setFaculty("");
      setCluster("");
      setEditCourseId("");
      setEditSessionId("");
      setTermCount([]);
      let obj = {
        proId: proId,
        term: termId,
      };
      // dispatch(getAllCourseByTerm(obj));
      let object = {
        proId: proId,
        courseId: courseId,
      };
      if (courseId) {
        // dispatch(getAllSessionByCourseId(object));
      }
      let newObj = {
        proId: proId,
        proMasterId: proMasterId,
      };
      // dispatch(getAllProgramTerm(newObj));
      handleCsvRemove();
      setUploadBoxToggler(false);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const options =
    getAllFacultiesData?.length > 0
      ? getAllFacultiesData?.map((res) => ({
          value: res.id,
          label: `${res.firstName} ${res.lastName}`,
        }))
      : [];
  const culsterOptions =
    allCluster?.length > 0
      ? allCluster?.map((res) => ({
          value: res.id,
          label: res.Cluster?.name,
        }))
      : [];
  // [
  //   { value: "fc937381-e5f7-4bd7-b367-6a7e9a71364e", label: "Divesh Malhotra" },
  //   { value: "fc937381-e5f7-4bd7-b367-6a7e9a71364f", label: "Satinder" },
  // ];

  const facultyOnChange = (e) => {
    setFaculty(e);
  };
  const culsterOnChange = (e) => {
    setCluster(e);
  };

  const editCourse = (res) => {
    setEditMenuToggler(true);
    setFormToggler(true);
    setCourseDetails((prevState) => ({
      ...prevState,
      courseName: res?.courseName,
      courseCode: res?.courseCode,
      hours: res?.hours,
      credits: res?.credits,
      description: res?.description,
      startDate: moment(res?.startDate).format("YYYY-MM-DD"),
      endDate: moment(res?.endDate).format("YYYY-MM-DD"),
    }));
    setEditCourseId(res.id);
    setCourseSubType(res?.courseSubType);
    setNumberOfStu(res?.numberOfStu);
    setCluster({
      value: res?.programClusterId,
      label: res?.ProgramCluster?.Cluster?.name,
    });
  };
  const editSession = (res) => {
    setEditMenuToggler(true);
    setFormToggler(true);
    setSessionDetails((prevState) => ({
      ...prevState,
      name: res?.name,
      date: moment(res?.date).format("YYYY-MM-DD"),
      fromTime: res?.fromTime,
      toTime: res?.toTime,
      description: res?.description,
    }));
    setFaculty({
      value: res?.facultyId,
      label: `${res?.Admin?.firstName} ${res?.Admin?.lastName}`,
    });
    setSelectedKey(res?.videoName ? res?.videoName : "");
    setFileArrayData(
      res.SessionFiles?.length > 0
        ? res.SessionFiles?.map((data) => ({
            fileName: data?.fileName,
            fileUrl: data?.fileUrl,
          }))
        : []
    );
    setaddFilesCount(
      res.SessionFiles?.length > 0
        ? res.SessionFiles?.map((data) => data?.length)
        : [0]
    );
    setCourseId(res?.courseId);
    setEditSessionId(res?.id);
    setUrl(res?.videoUrl);
    console.log(res, "abc");
  };

  const deleteCourse = (res) => {
    let obj = {
      proId: res?.programId,
      courseId: res?.id,
    };
    dispatch(deleteCourseRoster(obj));
  };

  const deleteSession = (res) => {
    let obj = {
      proId: res?.programId,
      courseId: res?.courseId,
      session: res.id,
    };
    // console.log(res,"re")
    dispatch(deleteCourseSession(obj));
  };

  // const editCourseSubmit = () => {
  //   if (editCourseId) {
  //     dispatch(editCourseRoster({ courseDetails, editCourseId }));
  //   }
  // };

  const editSessionSubmit = () => {
    let facultyId = faculty?.value;
    dispatch(
      editCourseSession({
        sessionDetails,
        courseId,
        facultyId,
        editSessionId,
        videoUrl: url,
        fileArrayData: fileArrayData,
        videoName: selectedKey,
      })
    );
  };

  const addCourse = () => {
    setFormToggler(true);
    setEditMenuToggler(false);
    setSessionDetails({ ...sessionData });
    setCourseDetails({ ...courseData });
    setEditCourseId("");
    setEditSessionId("");
    setFileArrayData([]);
    setaddFilesCount([0]);
    setFaculty("");
    setUrl("");
    setSelectedKey("");
    setCluster("");
    setCourseSubType("");
    setNumberOfStu("");
    // if (!formToggler && !courseSessionToggle) {
    //   dispatch(getAllFaculties());
    // }
  };

  const onTermClick = ({ res, i }) => {
    setTermToggle(i);
    setAddTermToggle(false);
    setFormToggler(false);
    setEditMenuToggler(false);
    setTermId(res.term);
    setNewSessionEdit(false);
    // let obj = {
    //   proId: proId,
    //   term: res,
    // };
    // dispatch(getAllCourseByTerm(obj));
  };

  const onTermClickAdd = () => {
    setTermToggle(getTerm?.length + 1);
    setAddTermToggle(true);
    setFormToggler(false);
    setEditMenuToggler(false);
    setSessionDetails({ ...sessionData });
    setCourseDetails({ ...courseData });
    setTermId(getTerm?.length + 1);
    let obj = {
      proId: proId,
      term: getTerm?.length + 1,
    };
    dispatch(getAllCourseByTerm(obj));
  };

  const sessionToggleClick = ({ data, index }) => {
    setTermId(data.term);
    setEditMenuToggler(false);
    setFormToggler(false);
    setCourseToggle(0);
    setNewSessionEdit(true);
    setSessionToggle(index);
    handleRemove();
    let obj = {
      proId: proId,
      term: data,
    };
    // dispatch(getAllCourseByTerm(obj));
    // dispatch(getAllSessionByCourseId(obj))
    setCsvFile("");
  };

  console.log(getCourseByTerm, "getCourseByTermgetCourseByTermgetCourseByTerm");

  const addCourseHeaderToggle = () => {
    setCourseSessionToggle(true);
    setTermToggle(0);
    setTermCount([]);
    setAddTermToggle(false);
    setFormToggler(false);
    setCourseId("");
    setTermId(
      getAllTermDetailData?.length > 0 ? getAllTermDetailData[0]?.term : ""
    );
    handleRemove();
    if (getAllTermDetailData?.length > 0) {
      let obj = {
        proId: proId,
        term: getAllTermDetailData[0]?.term,
      };
      // dispatch(getAllCourseByTerm(obj));
    }
  };

  console.log(courseId, "CourseIddddd");
  const addSessionHeaderToggle = () => {
    setCourseSessionToggle(false);
    setSessionToggle(0);
    // setCourseId("");
    setCourseId(getCourseByTerm?.length > 0 ? getCourseByTerm[0]?.id : "");
    setTermId(
      getAllTermDetailData?.length > 0 ? getAllTermDetailData[0]?.term : ""
    );
    setCourseToggle(0);
    setNewSessionEdit(true);
    // setEditSessionToggle(false);
    setFormToggler(false);
    handleRemove();
    if (getAllTermDetailData?.length > 0) {
      let obj = {
        proId: proId,
        term: getAllTermDetailData[0]?.term,
      };
      // dispatch(getAllCourseByTerm(obj));
      dispatch(downloadSampleSessionCSV());
      dispatch(getAllFaculties());
    }
  };

  const handleEditorChangeCourse = (content) => {
    setCourseDetails((prevState) => ({
      ...prevState,
      description: content,
    }));
  };
  const handleEditorChangeSession = (content) => {
    setSessionDetails((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleAddFiles = () => {
    let check = false;
    if (fileArrayData.length === 0) check = true;
    if (addFilesCount.length > fileArrayData.length) {
      check = true;
    }
    if (check) {
      setIsShowError(true);
      setErrMessage("Please upload previous file first!");
    } else setaddFilesCount((prev) => [...prev, +prev[prev.length - 1] + 1]);
  };

  const handleCsvUpload = (e) => {
    if (e.target.files.length > 0) setCsvFile(e.target.files[0]);
  };

  const handleCsvRemove = () => {
    setCsvFile("");
  };

  const handleCsvUploadCourse = () => {
    let obj = {
      programMaster: proMasterId,
      proId: proId,
      file: csvFile,
    };
    dispatch(addCourseRosterCSV(obj));
  };

  const handleCsvUploadSession = () => {
    let obj = {
      courseId: courseId,
      proId: proId,
      file: csvFile,
    };
    dispatch(addCourseSessionCSV(obj));
  };

  const handleCsvDownloadClick = () => {
    console.log("Hello");
  };

  useEffect(() => {
    dispatch(downloadSampleCourseCSV());
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (CSVResponse.length > 0) {
  //     if (!CSVResponse[0]?.IsSuccess) {
  //       setIsShowError(true);
  //       setErrMessage(CSVResponse[0]?.Message);
  //       dispatch(clearState());
  //     }
  //   }
  //   // setUploadBoxToggler(false);
  //   // setCsvFile("");
  // }, [CSVResponse]);

  useEffect(() => {
    if (isError) {
      setIsShowError(true);
      setErrMessage(errorMessage);
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isError]);

  console.log(getAllFacultiesData, "And", isSuccess);

  const closeCourse = () => {
    setFormToggler(false);
    setEditMenuToggler(false);
    setSessionDetails({ ...sessionData });
    setCourseDetails({ ...courseData });
    setEditCourseId("");
    setEditSessionId("");
    setFileArrayData([]);
    setaddFilesCount([0]);
    setFaculty("");
    setUrl("");
    setSelectedKey("");
    setCluster("");
    setCourseSubType("");
  };

  const courseTypeOnChange = (e) => {
    let data = e.target.value;
    setCourseSubType(data);
  };

  const termPopupOpen = () => {
    setTermPopupShow(true);
  };
  const termPopupClose = () => {
    setTermPopupShow(false);
    setTermDetails({ ...termData });
  };
  const termDetailsSubmit = () => {
    if (proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3") {
      let obj = {
        startDate: termDetails.startDate,
        endDate: termDetails.endDate,
        term: termDetails.term,
        entity: termDetails.entity,
        minElectiveCourse: termDetails.minElectiveCourse,
        programId: proId,
        programMasterId: proMasterId,
      };
      if (
        termDetails.startDate === "" ||
        termDetails.endDate === "" ||
        termDetails.term === "" ||
        termDetails.minElectiveCourse === ""
      ) {
        setIsShowError(true);
        setErrMessage("Please Fill All Field");
      } else {
        dispatch(addTerm(obj));
        console.log("done", obj);
      }
    } else {
      let newobj = {
        startDate: termDetails.startDate,
        endDate: termDetails.endDate,
        term: termDetails.term,
        entity: termDetails.entity,
        programId: proId,
        programMasterId: proMasterId,
      };
      if (
        termDetails.startDate === "" ||
        termDetails.endDate === "" ||
        termDetails.term === ""
      ) {
        setIsShowError(true);
        setErrMessage("Please Fill All Field");
      } else {
        dispatch(addTerm(newobj));
      }
    }
  };

  // Success Api Call

  useEffect(() => {
    if (addTermSuccess) {
      let obj = {
        proId: proId,
        proMasterId: proMasterId,
      };
      // dispatch(getAllProgramTerm(obj));
      dispatch(getAllTermDetail(obj));
      setTermToggle(0)
    } else if (courseSuccess) {
      let obj = {
        proId: proId,
        term: termId,
      };
      if (termId) {
        dispatch(getAllCourseByTerm(obj));
      }
    } else if (sessionSuccess) {
      let newObj = {
        proId: proId,
        courseId: courseId,
      };
      // if(!courseSessionToggle){
      dispatch(getAllSessionByCourseId(newObj));
    }
  }, [addTermSuccess, courseSuccess, sessionSuccess]);

  return (
    <>
      {(isFetching || load) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <TermPlanDetailsPopup
        show={termPopupShow}
        handleClose={termPopupClose}
        value={termDetails}
        onChange={termDetailsHandleChange}
        submit={termDetailsSubmit}
        proMasterId={proMasterId}
      />
      <UploadCSVPopup
        title={"Upload CSV"}
        isOpen={uploadBoxToggler}
        setIsOpen={setUploadBoxToggler}
        onChange={handleCsvUpload}
        value={csvFile.name}
        newValue={csvFile}
        handleRemove={handleCsvRemove}
        onUploadClick={
          courseSessionToggle ? handleCsvUploadCourse : handleCsvUploadSession
        }
        onDownloadClick={handleCsvDownloadClick}
        csvData={courseSessionToggle ? courseCSVData : sessionCSVData}
        isdisabled={csvFile === "" ? true : false}
      />
      <div className="pageHeader">
        <div className="backBtn" onClick={() => navigate(-1)}>
          <img src="/assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        {!editMenuToggler ? (
          <div className="pageTogglers">
            <div
              className={`pageToggler ${courseSessionToggle ? "active" : ""}`}
              onClick={addCourseHeaderToggle}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327ZM14.4006 7.73296C14.6447 7.48888 14.6447 7.09315 14.4006 6.84907C14.1565 6.605 13.7608 6.605 13.5167 6.84907L8.95866 11.4071L6.9006 9.34907C6.65652 9.105 6.26079 9.105 6.01672 9.34907C5.77264 9.59315 5.77264 9.98888 6.01672 10.233L8.51672 12.733C8.76079 12.977 9.15652 12.977 9.4006 12.733L14.4006 7.73296Z"
                  fill="#404040"
                />
              </svg>
              Course
            </div>
            {/* {getCourseByTerm?.length > 0 && ( */}
            <div
              className={`pageToggler ${courseSessionToggle ? "" : "active"}`}
              onClick={addSessionHeaderToggle}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327ZM14.4006 7.73296C14.6447 7.48888 14.6447 7.09315 14.4006 6.84907C14.1565 6.605 13.7608 6.605 13.5167 6.84907L8.95866 11.4071L6.9006 9.34907C6.65652 9.105 6.26079 9.105 6.01672 9.34907C5.77264 9.59315 5.77264 9.98888 6.01672 10.233L8.51672 12.733C8.76079 12.977 9.15652 12.977 9.4006 12.733L14.4006 7.73296Z"
                  fill="#404040"
                />
              </svg>
              Session
            </div>
            {/* // )} */}
          </div>
        ) : (
          <div className="pageTogglers">
            <div className="pageToggler active">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327ZM14.4006 7.73296C14.6447 7.48888 14.6447 7.09315 14.4006 6.84907C14.1565 6.605 13.7608 6.605 13.5167 6.84907L8.95866 11.4071L6.9006 9.34907C6.65652 9.105 6.26079 9.105 6.01672 9.34907C5.77264 9.59315 5.77264 9.98888 6.01672 10.233L8.51672 12.733C8.76079 12.977 9.15652 12.977 9.4006 12.733L14.4006 7.73296Z"
                  fill="#404040"
                />
              </svg>
              Edit
            </div>
          </div>
        )}

        <div className="pageHeaderBtnGrop">
          {(!courseSessionToggle ||
            proMasterId !== "53e08f7a-a03b-42f3-b181-064d1afc05e3") && (
            <PrimaryButton
              onClick={() => setUploadBoxToggler(true)}
              children={`Add ${courseSessionToggle ? "Course" : "Session"} CSV`}
              classname={"successBtn"}
            />
          )}
        </div>
      </div>

      {/* Add Course */}
      <div
        className={`addCourseContainer ${courseSessionToggle ? "active" : ""}`}
      >
        <div className="addCourseLeft">
          <p className="addCourseLeftHeading">Enter your Course Details </p>
          {getAllTermDetailData?.length > 0 &&
            getAllTermDetailData?.map((res, i) => (
              <div
                className={`sessionOverviewCard ${
                  addTermToggle === false && termToggle === i ? "active" : ""
                }`}
                key={i}
                onClick={() => onTermClick({ res, i })}
              >
                <div className="sessionCardHeader">
                  <div className="sessionCardHeaderLeft">
                    <p className="sessionHeading">Term {res?.term}</p>
                    <div className="sessionDateContainer">
                      <p>Duration:</p>
                      <p className="sessionDate">
                        {moment(res?.startDate).format("DD-MM-YYYY")}
                      </p>{" "}
                      To
                      <p className="sessionDate">
                        {moment(res?.endDate).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  <img src="/assets/icons/arrow-narrow-right.svg" alt="" />
                </div>
              </div>
            ))}
          {/* {termCount?.length > 0 &&
            termCount?.map((res, i) => (
              <div
                className={`sessionOverviewCard ${
                  addTermToggle ? "active" : ""
                }`}
                key={i}
                onClick={onTermClickAdd}
              >
                <div className="sessionCardHeader">
                  <p className="sessionHeading">Term {res}</p>
                  <img src="/assets/icons/arrow-narrow-right.svg" alt="" />
                </div>
              </div>
            ))} */}
          <div
            className="addCourseBtn"
            // onClick={() => setTermCount([getTerm?.length + 1])}
            onClick={termPopupOpen}
          >
            <img src="/assets/icons/plus-circle.svg" alt="" />
            Add Term
          </div>
        </div>
        <div className="addCourseRight">
          <div className="addCourseRightInner">
            {!formToggler &&
              getCourseByTerm?.length > 0 &&
              getCourseByTerm?.map((res, i) => (
                <div className="addedCourseCard" key={i}>
                  <div className="addCourseCardHeader">
                    <div className="successTag">Course {i + 1}</div>
                    <div className="imgGrp">
                      <img
                        src="/assets/icons/edit-02.svg"
                        alt=""
                        className="pointer"
                        onClick={() => editCourse(res)}
                      />
                      <img
                        src="/assets/icons/trash-01.svg"
                        alt=""
                        className="pointer"
                        onClick={() => deleteCourse(res)}
                      />
                    </div>
                  </div>
                  <p className="heading">{res.courseName}</p>
                </div>
              ))}
            {formToggler ? (
              <div className="addCourseBtn" onClick={closeCourse}>
                {/* <img src="/assets/icons/minus-circle.svg" alt="" /> */}
                Close
              </div>
            ) : (
              getAllTermDetailData?.length > 0 && (
                <div className="addCourseBtn" onClick={addCourse}>
                  <img src="/assets/icons/plus-circle.svg" alt="" />
                  Add Course
                </div>
              )
            )}
            <div className={`addCourseForm ${formToggler ? "active" : ""}`}>
              <div className="form2Col">
                <TextField
                  labelName={"Course Name"}
                  inputType={"text"}
                  placeholder={"Enter your Course name"}
                  name="courseName"
                  value={courseDetails?.courseName}
                  onChange={courseDetailsHandleChange}
                />
                <TextField
                  labelName={"Course Code"}
                  inputType={"text"}
                  placeholder={"Enter your Course Code"}
                  name="courseCode"
                  value={courseDetails?.courseCode}
                  onChange={courseDetailsHandleChange}
                />
              </div>
              <div className="form2Col">
                <TextField
                  labelName={"Hours"}
                  inputType={"number"}
                  placeholder={"Enter Hours"}
                  name="hours"
                  value={courseDetails?.hours}
                  onChange={courseDetailsHandleChange}
                />
                <TextField
                  labelName={"Credits"}
                  inputType={"number"}
                  placeholder={"Enter Credits"}
                  name="credits"
                  value={courseDetails?.credits}
                  onChange={courseDetailsHandleChange}
                />
              </div>
              {/* {console.log(allCluster,"hh")} */}
              {proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3" && (
                <>
                  <div className="formGrp gap-10">
                    <label>Select Cluster</label>
                    <Select
                      value={cluster}
                      onChange={culsterOnChange}
                      options={culsterOptions}
                      placeholder="Select Cluster"
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </div>
                  <div className="form2Col">
                    <div className="formGrp gap-10">
                      <label>Select Course Type</label>
                      <select
                        className="formInput"
                        id="type"
                        value={courseSubType}
                        onChange={courseTypeOnChange}
                      >
                        <option value="" hidden>
                          Select Course Type
                        </option>
                        <option value="coreCompulsory">Core Compulsory</option>
                        <option value="coreElective">Core Elective</option>
                        <option value="industry">Industry</option>
                        <option value="skill">Skill</option>
                      </select>
                    </div>
                    {courseSubType === "coreCompulsory" ||
                    courseSubType === "" ? (
                      ""
                    ) : (
                      <TextField
                        labelName={"Number Of Students"}
                        inputType={"number"}
                        placeholder={"Enter Number"}
                        name="numberOfStu"
                        value={numberOfStu}
                        onChange={(e) => setNumberOfStu(e.target.value)}
                      />
                    )}
                  </div>
                </>
              )}
              <div className="formGroup">
                {/* <textarea
                  name="description"
                  rows="5"
                  value={courseDetails?.description}
                  onChange={courseDetailsHandleChange}
                ></textarea> */}
                <label htmlFor="Email">Description</label>
                <SunEditor
                  setContents={courseDetails?.description}
                  value={courseDetails?.description}
                  onChange={handleEditorChangeCourse}
                  setDefaultStyle="height: 150px"
                  setOptions={{
                    buttonList: [
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "list",
                        "align",
                        "fontSize",
                        "formatBlock",
                        "table",
                        "fullScreen",
                        "preview",
                        "codeView",
                      ],
                    ],
                  }}
                />
              </div>
              <div className="form2Col">
                <TextField
                  inputType={"date"}
                  labelName={"Course Start Date"}
                  name="startDate"
                  value={courseDetails?.startDate}
                  onChange={courseDetailsHandleChange}
                  // min={moment(new Date()).format("YYYY-MM-DD")}
                />
                {courseDetails?.startDate ? (
                  <TextField
                    inputType={"date"}
                    labelName={"Course End Date"}
                    name="endDate"
                    value={courseDetails?.endDate}
                    onChange={courseDetailsHandleChange}
                    min={moment(courseDetails?.startDate).add(1, 'day').format("YYYY-MM-DD")}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="addCourseFooter">
            {/* <PrimaryButton children={"Save"} classname={"secondaryBtn"} /> */}
            <div></div>
            {formToggler &&
              (!editMenuToggler ? (
                <PrimaryButton
                  children={"Save"}
                  classname={"primaryBtn"}
                  onClick={courseDetailsSubmit}
                />
              ) : (
                <PrimaryButton
                  children={"Update Course"}
                  classname={"primaryBtn"}
                  onClick={editCourseSubmit}
                />
              ))}
          </div>
        </div>
      </div>

      {/* Add Session */}
      <div
        className={`addCourseContainer ${courseSessionToggle ? "" : "active"}`}
      >
        <div className="addCourseLeft">
          <p className="addCourseLeftHeading">Enter your Session Details </p>
          <div className="courseMenu">
            {getAllTermDetailData?.length > 0 &&
              getAllTermDetailData?.map((data, index) => (
                <div
                  className={`courseDetailsLeftMenuItem ${
                    sessionToggle === index ? "active" : ""
                  }`}
                  key={index}
                >
                  <div
                    className="courseDetailsLeftMenuItemInner"
                    onClick={() => sessionToggleClick({ data, index })}
                  >
                    {/* <div className="currentStatus">
                      <img src="/assets/icons/check-circle.svg" alt="" />
                      <p className="successText">Completd</p>
                    </div> */}
                    <div className="sessionCardHeader">
                      <div className="sessionCardHeaderLeft">
                        <p className="sessionHeading">Term {data?.term}</p>
                        <div className="sessionDateContainer">
                          <p>Duration:</p>
                          <p className="sessionDate">
                            {moment(data?.startDate).format("DD-MM-YYYY")}
                          </p>{" "}
                          To
                          <p className="sessionDate">
                            {moment(data?.endDate).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>
                      <img src="/assets/icons/chevron-down-black.svg" alt="" />
                    </div>
                  </div>

                  <div className="sessionList">
                    {getCourseByTerm?.length > 0 ? (
                      getCourseByTerm?.map((res, i) => (
                        <div
                          className={`sessionListItem ${
                            courseToggle === i ? "active" : ""
                          }`}
                          key={i}
                          onClick={() => courseToggleFun({ res, i })}
                        >
                          <div className="sessionListItemLeft">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625ZM0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9ZM12.7727 6.35225C12.9924 6.57192 12.9924 6.92808 12.7727 7.14775L8.27275 11.6477C8.05308 11.8674 7.69692 11.8674 7.47725 11.6477L5.22725 9.39775C5.00758 9.17808 5.00758 8.82192 5.22725 8.60225C5.44692 8.38258 5.80308 8.38258 6.02275 8.60225L7.875 10.4545L11.9773 6.35225C12.1969 6.13258 12.5531 6.13258 12.7727 6.35225Z"
                                fill="#171717"
                              />
                            </svg>

                            <p className="">{res.courseName}</p>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2254 4.55806C11.4695 4.31398 11.8652 4.31398 12.1093 4.55806L17.1093 9.55806C17.3533 9.80214 17.3533 10.1979 17.1093 10.4419L12.1093 15.4419C11.8652 15.686 11.4695 15.686 11.2254 15.4419C10.9813 15.1979 10.9813 14.8021 11.2254 14.5581L15.1584 10.625H3.33398C2.98881 10.625 2.70898 10.3452 2.70898 10C2.70898 9.65482 2.98881 9.375 3.33398 9.375H15.1584L11.2254 5.44194C10.9813 5.19786 10.9813 4.80214 11.2254 4.55806Z"
                              fill="#171717"
                            />
                          </svg>
                        </div>
                      ))
                    ) : (
                      <p className="sessionHeading">Add Course First</p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="addCourseRight">
          <div className="addCourseRightInner">
            {!formToggler &&
              getSessionByCourse?.length > 0 &&
              getCourseByTerm?.length > 0 &&
              getSessionByCourse?.map((res, i) => (
                <div className="addedCourseCard" key={i}>
                  <div className="addCourseCardHeader">
                    <div className="successTag">Session {i + 1}</div>
                    <div className="imgGrp">
                      <img
                        src="/assets/icons/edit-02.svg"
                        alt=""
                        className="pointer"
                        onClick={() => editSession(res)}
                      />
                      <img
                        src="/assets/icons/trash-01.svg"
                        alt=""
                        className="pointer"
                        onClick={() => deleteSession(res)}
                      />
                    </div>
                  </div>
                  <p className="heading">{res.name}</p>
                  <div className="sessionDetailsBoxOuter">
                    <div className="sessionDetailsBox">
                      <p className="heading">Session Date</p>
                      <p className="content">
                        {moment(res.date).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <div className="sessionDetailsBox">
                      <p className="heading">Session Start Time</p>
                      <p className="content">{res.fromTime}</p>
                    </div>
                    <div className="sessionDetailsBox">
                      <p className="heading">Session End Time</p>
                      <p className="content">{res.toTime}</p>
                    </div>
                  </div>
                  <div className="uploadedFilesOuter">
                    {res?.SessionFiles?.length > 0 && (
                      <p className="heading">Files Included</p>
                    )}
                    <div className="allFilesOuter">
                      {res?.SessionFiles?.length > 0 &&
                        res.SessionFiles?.map((data, i) => (
                          <div
                            className="fileViewerOuter"
                            key={i}
                            onClick={() => window.open(data.fileUrl)}
                          >
                            <img src="/assets/icons/zipFile.svg" alt="" />
                            <div className="fileViewContent">
                              <p className="fileName">{data.fileName}</p>
                              {/* <p className="fileSize">15 MB ZIP</p> */}
                            </div>
                          </div>
                        ))}
                      {/* <div className="fileViewerOuter">
                        <img src="/assets/icons/pdfFile.svg" alt="" />
                        <div className="fileViewContent">
                          <p className="fileName">Design.zip</p>
                          <p className="fileSize">15 MB ZIP</p>
                        </div>
                      </div>
                      <div className="fileViewerOuter">
                        <img src="/assets/icons/jpgFile.svg" alt="" />
                        <div className="fileViewContent">
                          <p className="fileName">Design.zip</p>
                          <p className="fileSize">15 MB ZIP</p>
                        </div>
                      </div> */}
                      {/* <div className="fileViewerOuter">addCourse
                        <img src="/assets/icons/zipFile.svg" alt="" />
                        <div className="fileViewContent">
                          <p className="fileName">Design.zip</p>
                          <p className="fileSize">15 MB ZIP</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            {formToggler ? (
              <div className="addCourseBtn" onClick={closeCourse}>
                {/* <img src="/assets/icons/minus-circle.svg" alt="" /> */}
                Close
              </div>
            ) : (
              getCourseByTerm?.length > 0 && (
                <div className="addCourseBtn" onClick={addCourse}>
                  <img src="/assets/icons/plus-circle.svg" alt="" />
                  Add Session
                </div>
              )
            )}
            {/* <div className="addCourseBtn" onClick={addCourse}>
              <img src="/assets/icons/plus-circle.svg" alt="" />
              Add Session
            </div> */}
            <div className={`addCourseForm ${formToggler ? "active" : ""}`}>
              <TextField
                isImportant={true}
                labelName={"Session Name"}
                inputType={"text"}
                placeholder={"Enter your Session Name"}
                name="name"
                value={sessionDetails?.name}
                onChange={sessionDetailsHandleChange}
              />
              <div className="form2Col">
                <TextField
                  inputType={"date"}
                  labelName={"Session Date"}
                  name="date"
                  value={sessionDetails?.date}
                  onChange={sessionDetailsHandleChange}
                  min={moment(new Date()).format("YYYY-MM-DD")}
                />

                {/* <Dropdown label={"Select Faculty"} /> */}
              </div>
              <div className="formGrp gap-10">
                <label>Select Faculty</label>
                <Select
                  value={faculty}
                  onChange={facultyOnChange}
                  options={options}
                  placeholder="Select Faculty"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>
              <div className="form2Col">
                <TextField
                  inputType={"time"}
                  labelName={"Session Start Time"}
                  name="fromTime"
                  value={sessionDetails?.fromTime}
                  onChange={sessionDetailsHandleChange}
                />
                <TextField
                  inputType={"time"}
                  labelName={"Session End Time"}
                  name="toTime"
                  value={sessionDetails?.toTime}
                  onChange={sessionDetailsHandleChange}
                />
              </div>
              <div className="formGroup gap5">
                {/* <textarea
                  name="description"
                  rows="5"
                  value={sessionDetails?.description}
                  onChange={sessionDetailsHandleChange}
                  placeholder="Description"
                ></textarea> */}

                <label htmlFor="Email">Description</label>
                <SunEditor
                  setContents={sessionDetails?.description}
                  value={sessionDetails?.description}
                  onChange={handleEditorChangeSession}
                  setDefaultStyle="height: 150px"
                  setOptions={{
                    buttonList: [
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "list",
                        "align",
                        "fontSize",
                        "formatBlock",
                        "table",
                        "fullScreen",
                        "preview",
                        "codeView",
                      ],
                    ],
                  }}
                />
              </div>
              <CustomUploadBox
                label={"Upload Video"}
                onChange={handleUploadVideo}
                value={selectedKey}
                newValue={selectedKey}
                handleRemove={handleRemove}
                accept={"video/mp4,video/x-m4v,video/*"}
                uid={"vid"}
              />
              <div className="multipleUploadBoxOuter">
                <div className="uploadBoxHeader">
                  <p>Upload Files</p>
                  <div onClick={handleAddFiles} className="addCourseBtn">
                    <img src="/assets/icons/plus-circle.svg" alt="" />
                    Add Files
                  </div>
                </div>
                {/* <CustomUploadBox
                  onChange={handleUpload}
                  value={selectedKey}
                  handleRemove={handleRemove}
                  accept={"video/mp4,video/x-m4v,video/*"}
                /> */}
                {addFilesCount.map((item, index) => (
                  //   <CustomUploadBox
                  //   onChange={(event) => handleUploadFiles(event,index)}
                  //   value={selectedFileKey[index]}
                  //   handleRemove={handleRemoveFiles}
                  //   accept={"video/mp4,video/x-m4v,video/*"}
                  // />
                  <div className="uploadBoxOuter">
                    <div className="upload-box-inner">
                      <label htmlFor={`upload-box${index}`}>
                        <span className="upload-btn">Choose File</span>
                        {fileArrayData[index] === undefined
                          ? "No File Chosen"
                          : fileArrayData[index].fileName}
                      </label>
                      <input
                        type="file"
                        id={`upload-box${index}`}
                        value={(e) => e.target.value}
                        onChange={(event) => handleUploadFiles(event, index)}
                        // accept={"video/mp4,video/x-m4v,video/*"}
                      />
                      <div class="upload-box-icons active">
                        {
                          fileArrayData[index] && 
                          <img
                            src="../assets/icons/trash-01.svg"
                            alt=""
                            onClick={() => handleRemoveFiles(index)}
                          />
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <TextField
                isImportant={true}
                labelName={"Video Link"}
                inputType={"url"}
                placeholder={"Paste Video Link"}
              /> */}
            </div>
          </div>
          <div className="addCourseFooter">
            {formToggler &&
              (!editMenuToggler ? (
                <>
                  {/* <PrimaryButton
                  children={"Back"}
                  classname={"darkSecondaryOutlineBtn"}
                  onClick={addCourseHeaderToggle}
                /> */}
                  <div></div>
                  <PrimaryButton
                    children={"Save"}
                    classname={"primaryBtn"}
                    onClick={sessionDetailsSubmit}
                  />
                </>
              ) : (
                <>
                  <div></div>
                  {/* <PrimaryButton
                  children={"Back"}
                  classname={"darkSecondaryOutlineBtn"}
                  onClick={addCourseHeaderToggle}
                /> */}
                  <PrimaryButton
                    children={"Update Session"}
                    classname={"primaryBtn"}
                    onClick={editSessionSubmit}
                  />
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCourse;
