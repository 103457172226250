import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environment/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

// All Course
export const getAllCourseAdmin = createAsyncThunk(
  "courseData/getAllCourseAdmin",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/programMaster/" +
          obj.proMasterId +
          "/program/" +
          obj.proId +
          "/getAllCourseAdmin",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllCourseListAdmin = createAsyncThunk(
  "courseData/getAllCourseListAdmin",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/programMaster/" +
          obj.proMasterId +
          "/program/" +
          obj.proId +
          "/getAllCourseListAdmin",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// All Programs
export const getAllProgramAdmin = createAsyncThunk(
  "courseData/getAllProgramAdmin",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/rosterOrgProWithBatch",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get Term
export const getAllProgramTerm = createAsyncThunk(
  "courseData/getAllProgramTerm",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/programMaster/" +
          obj.proMasterId +
          "/program/" +
          obj.proId +
          "/getAllProgramTerm",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// Get Course By Term
export const getAllCourseByTerm = createAsyncThunk(
  "courseData/getAllCourseByTerm",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          obj.proId +
          "/term/" +
          obj.term +
          "/getAllCourseByTerm",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get Session By Course
export const getAllSessionByCourseId = createAsyncThunk(
  "courseData/getAllSessionByCourseId",
  async ({ proId, courseId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          proId +
          "/course/" +
          courseId +
          "/getAllSessionByCourseId",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Add Course
export const addCourseRoster = createAsyncThunk(
  "courseData/addCourseRoster",
  async (
    { courseDetails, courseType, courseSubType, cluster, numberOfStu },
    thunkAPI
  ) => {
    try {
      setTokenValues();
      let payload;
      if (courseDetails?.entity === 'MasterUnion') {
        if(courseSubType ==='coreCompulsory'){
        payload = {
          ...courseDetails,
          courseType,
          courseSubType,
          programClusterId:cluster.value,
        };
      }else{
        payload = {
          ...courseDetails,
          courseType,
          courseSubType,
          programClusterId:cluster.value,
          numberOfStu
        };
      }
        console.log("if");
      } else {
        payload = courseDetails
        console.log("esle");
      }
      console.log(payload,"payload")
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/addCourseRoster",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Add Session
export const addCourseSession = createAsyncThunk(
  "courseData/addCourseSession",
  async (
    { sessionDetails, courseId, facultyId, videoUrl, fileArrayData, videoName },
    thunkAPI
  ) => {
    try {
      setTokenValues();
      let payload = {
        name: sessionDetails?.name,
        date: sessionDetails?.date,
        fromTime: sessionDetails?.fromTime,
        toTime: sessionDetails?.toTime,
        description: sessionDetails?.description,
        facultyId: facultyId,
        courseId: courseId,
        programId: sessionDetails?.programId,
        programMasterId: sessionDetails?.programMasterId,
        videoUrl: videoUrl,
        isConfirmed: false,
        fileArrayData: fileArrayData,
       
        videoName: videoName,
      };
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          sessionDetails.programId +
          "/course/" +
          courseId +
          "/addCourseSession",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete Course
export const deleteCourseRoster = createAsyncThunk(
  "courseData/deleteCourseRoster",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          obj.proId +
          "/course/" +
          obj.courseId +
          "/deleteCourseRoster",
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Delete Session
export const deleteCourseSession = createAsyncThunk(
  "courseData/deleteCourseSession",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          obj.courseId +
          "/session/" +
          obj.session +
          "/deleteCourseSession",
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Edit Course
export const editCourseRoster = createAsyncThunk(
  "courseData/editCourseRoster",
  async ({
    courseDetails,
    courseType,
    courseSubType,
    cluster,
    numberOfStu,
    editCourseId
  }, thunkAPI) => {
    try {
      setTokenValues();
      let payload;
      if (courseDetails?.entity === 'MasterUnion') {
        // payload = {
        //   ...courseDetails,
        //   courseType,
        //   courseSubType,
        //   programClusterId:cluster.value,
        //   numberOfStu
        // };
        if(courseSubType === 'coreCompulsory'){
          payload = {
            ...courseDetails,
            courseType,
            courseSubType,
            programClusterId:cluster.value,
          };
        }else{
          payload = {
            ...courseDetails,
            courseType,
            courseSubType,
            programClusterId:cluster.value,
            numberOfStu
          };
        }
        console.log("if");
      } else {
        payload = courseDetails
        console.log("esle");
      }
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          courseDetails.programId +
          "/course/" +
          editCourseId +
          "/editCourseRoster",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Edit Session
export const editCourseSession = createAsyncThunk(
  "courseData/editCourseSession",
  async (
    {
      sessionDetails,
      courseId,
      facultyId,
      editSessionId,
      videoUrl,
      fileArrayData,
      videoName,
    },
    thunkAPI
  ) => {
    try {
      setTokenValues();
      let payload = {
        name: sessionDetails?.name,
        date: sessionDetails?.date,
        fromTime: sessionDetails?.fromTime,
        toTime: sessionDetails?.toTime,
        description: sessionDetails?.description,
        facultyId: facultyId,
        courseId: courseId,
        programId: sessionDetails?.programId,
        programMasterId: sessionDetails?.programMasterId,
        videoUrl: videoUrl,
        fileArrayData: fileArrayData,
        videoName: videoName,
      };
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          sessionDetails.programId +
          "/course/" +
          courseId +
          "/session/" +
          editSessionId +
          "/editCourseSession",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Course Publish
export const updateCourseRosterStatus = createAsyncThunk(
  "courseData/updateCourseRosterStatus",
  async ({ obj, courseId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          courseId +
          "/updateCourseRosterStatus",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get Attendance Session
export const getCourseSessionAttendance = createAsyncThunk(
  "courseData/getCourseSessionAttendance",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          obj +
          "/getCourseSessionAttendance",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// getAllCourseStudent
export const getAllCourseStudent = createAsyncThunk(
  "courseData/getAllCourseStudent",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/session/" +
          obj +
          "/getAllCourseStudent",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Session Attendance
export const addSessionAttendance = createAsyncThunk(
  "courseData/addSessionAttendance",
  async ({ obj, payload }, thunkAPI) => {
    try {
      setTokenValues();
      console.log(payload, "payload");
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          obj.courseId +
          "/session/" +
          obj.sessionId +
          "/addSessionAttendance",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// getAllSessionStuAttendance
export const getAllSessionStuAttendance = createAsyncThunk(
  "courseData/getAllSessionStuAttendance",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          obj +
          "/getAllSessionStuAttendance",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// getAllSessionStuAttendance header
export const getAllSessionAfterAttendance = createAsyncThunk(
  "courseData/getAllSessionAfterAttendance",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          obj +
          "/getAllSessionAfterAttendance",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Single Course Details getCourseByIdAdmin
export const getCourseByIdAdmin = createAsyncThunk(
  "courseData/getCourseByIdAdmin",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          obj.proId +
          "/course/" +
          obj.courseId +
          "/getCourseByIdAdmin",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const downloadSampleCourseCSV = createAsyncThunk(
  "courseData/downloadSampleCourseCSV",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/downloadSampleCourseCSV",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const downloadSampleSessionCSV = createAsyncThunk(
  "courseData/downloadSampleSessionCSV",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/downloadSampleSessionCSV",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// downloadAddAttendanceCSV
export const downloadAddAttendanceCSV = createAsyncThunk(
  "courseData/downloadAddAttendanceCSV",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/downloadSampleSessionAttendanceCSV",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.text();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Upload Session Csv Data
export const addCourseSessionCSV = createAsyncThunk(
  "courseData/addCourseSessionCSV",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let formData = new FormData();
      formData.append("file", obj.file);
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          obj.proId +
          "/course/" +
          obj.courseId +
          "/addCourseSessionCSV",
        {
          method: "POST",
          headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Upload Course Csv Data
export const addCourseRosterCSV = createAsyncThunk(
  "courseData/addCourseRosterCSV",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let formData = new FormData();
      formData.append("file", obj.file);
      // formData.append('name', 'hello');
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/programMaster/" +
          obj.programMaster +
          "/program/" +
          obj.proId +
          "/addCourseRosterCSV",
        {
          method: "POST",
          headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
            // Authorization: token,
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addSessionAttendanceCSV = createAsyncThunk(
  "courseData/addSessionAttendanceCSV",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      let formData = new FormData();
      formData.append("file", obj.file);
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/course/" +
          obj.courseId +
          "/session/" +
          obj.sessionId +
          "/addSessionAttendanceCSV",
        {
          method: "POST",
          headers: {
            // Accept: "application/json",
            // "Content-Type": "application/json",
          },
          body: formData,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get All faculty
export const getAllFaculties = createAsyncThunk(
  "courseData/getAllFaculties",
  async (thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/getAllFaculties",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get All Clusters
export const getAllProClusters = createAsyncThunk(
  "courseData/getAllProClusters",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          obj.proId +
          "/getAllProClusters",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

//Add Term

export const addTerm = createAsyncThunk(
  "courseData/addTerm",
  async (payload, thunkAPI) => {
    try {
      setTokenValues();
      
      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/addTermDetail`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Get All Term Detail
export const getAllTermDetail = createAsyncThunk(
  "courseData/getAllTermDetail",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/programMaster/" +
          obj.proMasterId +
          "/program/" +
          obj.proId +
          "/getAllTermDetail",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


// Get All Student Detail
export const getStudentsByProgram = createAsyncThunk(
  "courseData/getStudentsByProgram",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          orgId +
          "/admin/" +
          adminId +
          "/program/" +
          obj.programId +
          "/getStudentsByProgram?searchTitle=&pageNo=1&pageSize=150",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const courseSlice = createSlice({
  name: "courseData",
  initialState: {
    isFetching: false,
    isCourseSliceFetching: false,
    termFetched:false,
    isFetched: false,
    isSuccess: false,
    isError: false,
    courseByTerm:false,
    errorMessage: "",
    getAllCourseData: [],
    getAllCourseListData: [],
    proData: [],
    getAllPrograms: [],
    getTerm: [],
    getCourseByTerm: [],
    getSessionByCourse: [],
    getAttendanceSession: [],
    getAllAttendanceStudent: [],
    getAttendanceListSession: [],
    getStudentSessionHeader: [],
    getSingleCourseDetail: [],
    getAllFacultiesData: [],
    courseCSVData: [],
    sessionCSVData: [],
    attendanceCSVData: [],
    programName: "",
    isCsvSuccess: "",
    allCluster: "",
    getAllTermDetailData:[],
    sessionSuccess:false,
    addTermSuccess:false,
    courseSuccess:false,
    studentListDetail:[]
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isConfirmed = false;
      state.isCsvSuccess = "";
      state.sessionSuccess=false
      state.addTermSuccess=false
      state.courseSuccess=false
      return state;
    },
    clearCoursesliceState: (state) => {
      state.isFetched = false;
      state.termFetched= false;
      state.courseByTerm =false
    }
  },
  extraReducers: {
    [addCourseRoster.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.courseSuccess=true
      return state;
    },
    [addCourseRoster.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addCourseRoster.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllCourseAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAllCourseData = payload;
      state.isConfirmed = true;
      return state;
    },
    [getAllCourseAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllCourseAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllCourseListAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAllCourseListData = payload.Data;
      state.proData = payload.ProgramData;
      state.isConfirmed = true;
      return state;
    },
    [getAllCourseListAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllCourseListAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllProgramAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAllPrograms = payload;
      return state;
    },
    [getAllProgramAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllProgramAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllProgramTerm.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.termFetched =true;
      state.getTerm = payload;
      return state;
    },
    [getAllProgramTerm.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllProgramTerm.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllCourseByTerm.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.courseByTerm= true;
      state.getCourseByTerm = payload;
      return state;
    },
    [getAllCourseByTerm.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllCourseByTerm.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllFaculties.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAllFacultiesData = payload;
      return state;
    },
    [getAllFaculties.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllFaculties.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllSessionByCourseId.fulfilled]: (state, { payload }) => {
      state.isCourseSliceFetching = false;
      state.getSessionByCourse = payload.Data;
      state.programName = payload.programData;
      state.isFetched = true;
      return state;
    },
    [getAllSessionByCourseId.rejected]: (state, { payload }) => {
      state.isCourseSliceFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllSessionByCourseId.pending]: (state) => {
      state.isCourseSliceFetching = true;
    },
    [addCourseSession.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.sessionSuccess = true;
      return state;
    },
    [addCourseSession.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addCourseSession.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCourseRoster.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.courseSuccess = true;
      return state;
    },
    [deleteCourseRoster.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteCourseRoster.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCourseSession.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.sessionSuccess = true;
      return state;
    },
    [deleteCourseSession.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteCourseSession.pending]: (state) => {
      state.isFetching = true;
    },
    [editCourseRoster.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.courseSuccess = true;
      return state;
    },
    [editCourseRoster.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [editCourseRoster.pending]: (state) => {
      state.isFetching = true;
    },
    [editCourseSession.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.sessionSuccess = true;
      return state;
    },
    [editCourseSession.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [editCourseSession.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCourseRosterStatus.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [updateCourseRosterStatus.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [updateCourseRosterStatus.pending]: (state) => {
      state.isFetching = true;
    },
    [getCourseSessionAttendance.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAttendanceSession = payload;
      return state;
    },
    [getCourseSessionAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getCourseSessionAttendance.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllCourseStudent.fulfilled]: (state, { payload }) => {
      state.isFetching = false;

      state.getAllAttendanceStudent = payload;
      return state;
    },
    [getAllCourseStudent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllCourseStudent.pending]: (state) => {
      state.isFetching = true;
    },
    [addSessionAttendance.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [addSessionAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addSessionAttendance.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllSessionStuAttendance.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAttendanceListSession = payload;
      return state;
    },
    [getAllSessionStuAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllSessionStuAttendance.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllSessionAfterAttendance.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getStudentSessionHeader = payload;
      return state;
    },
    [getAllSessionAfterAttendance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllSessionAfterAttendance.pending]: (state) => {
      state.isFetching = true;
    },
    [getCourseByIdAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getSingleCourseDetail = payload;
      return state;
    },
    [getCourseByIdAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getCourseByIdAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    [downloadSampleCourseCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.courseCSVData = payload;
      return state;
    },
    [downloadSampleCourseCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [downloadSampleCourseCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [downloadSampleSessionCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.sessionCSVData = payload;
      return state;
    },
    [downloadSampleSessionCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [downloadSampleSessionCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [downloadAddAttendanceCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.attendanceCSVData = payload;
      return state;
    },
    [downloadAddAttendanceCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [downloadAddAttendanceCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [addCourseSessionCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload?.Message || "Something went wrong";
      state.isCsvSuccess = payload.IsSuccess;
      return state;
    },
    [addCourseSessionCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [addCourseSessionCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [addCourseRosterCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload?.Message || "Something went wrong";
      state.isCsvSuccess = payload.IsSuccess;
      console.log(payload, "payloadpayload");
      return state;
    },
    [addCourseRosterCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [addCourseRosterCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [addSessionAttendanceCSV.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.errorMessage = payload?.Message || "Something went wrong";
      return state;
    },
    [addSessionAttendanceCSV.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [addSessionAttendanceCSV.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllProClusters.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.allCluster = payload;
      state.errorMessage = payload?.Message || "Something went wrong";
      return state;
    },
    [getAllProClusters.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [getAllProClusters.pending]: (state) => {
      state.isFetching = true;
    },
    [addTerm.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.addTermSuccess=true
      return state;
    },
    [addTerm.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addTerm.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllTermDetail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.getAllTermDetailData = payload;
      state.errorMessage = payload?.Message || "Something went wrong";
      return state;
    },
    [getAllTermDetail.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [getAllTermDetail.pending]: (state) => {
      state.isFetching = true;
    },
    [getStudentsByProgram.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.studentListDetail = payload;
      state.errorMessage = payload?.Message || "Something went wrong";
      return state;
    },
    [getStudentsByProgram.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      state.isError = true;
    },
    [getStudentsByProgram.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState, clearCoursesliceState } = courseSlice.actions;

export const courseSliceData = (state) => state.courseData;
