import React from 'react'
import './MyCoursesStyle.css'
import Button from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import Dropdown from '../../components/globalComponents/dropdown/Dropdown'
import ProgressBar from '../../components/globalComponents/progressBar/ProgressBar'

const MyCourses = () => {
  return (
   <div className="myCoursesOuter">
    <div className="myCoursesHeader">
        <p className="heading">My Courses</p>
        <Dropdown/>
    </div>
    <div className="myCoursesBody">
      <div className="myCourseCard">
          <div className="myCourseCardHeader">
              <p className="courseTitle">Building Marketing Strategies</p>
              <Button children={'View Sessions'} classname={'primaryBtn'} isdisabled={false}/>
          </div>
          <div className="myCourseCardBody">
            <div className="myCourseSessionDetails">
              <p>Sessions 10</p>
              <p>50%</p>
            </div>
              <ProgressBar width={'50%'}/>
          </div>
      </div>
      <div className="myCourseCard">
          <div className="myCourseCardHeader">
              <p className="courseTitle">Analyzing Financial Statements</p>
              <Button children={'View Sessions'} classname={'secondaryBtn'} isdisabled={false}/>
          </div>
          <div className="myCourseCardBody">
            <div className="myCourseSessionDetails">
              <p>Sessions 12</p>
              <p>0%</p>
            </div>
              <ProgressBar width={'0%'}/>
          </div>
      </div>
    </div>
   </div>
  )
}

export default MyCourses