import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import "./EmailPopupStyle.css";
const EmailPopup = (props) => {
  const emailContent = props.emailTemplateData;

  console.log("fdfdfdfdfdf", emailContent);
  return (
    <div className={`popupOuter ${props.show ? "active" : ""}`}>
      <div className="emailPopupContainer">
        <div className="popupHeader">
          <p className="popupTitle">Email Preview</p>
          <img
            onClick={props.handleClose}
            src="/assets/icons/x-close.svg"
            alt=""
          />
        </div>
        <div className="popupBody">
          <p>Content Needed</p>
          {/* <div className="emailsDetailsOuter">
            <div className="emailsDetails">
              <p className="heading">Subject</p>
              <p className="content">
                {emailContent && emailContent.templateSubject}
              </p>
            </div>
          </div> */}
          {/* <p className="emailHeading">Email Body</p>
          <div className="emailPreview">
            <div
              dangerouslySetInnerHTML={{
                // __html: editorState,
                __html: emailContent && emailContent.templateBody,
              }}
            />
          </div> */}
        </div>
        <div className="popupFooter flexEnd">
          <PrimaryButton
            classname={"darkSecondaryOutlineBtn"}
            children={"Cancel"}
            onClick={props.handleClose}
          />
          <PrimaryButton
            onClick={props.handleSendMail}
            classname={"dangerBtn"}
            children={"Send Mail"}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailPopup;
