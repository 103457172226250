import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import './UserListPopupStyle.css'

const UserListPopup = ({ bit, onChange, UserList, handleClick }) => {

    return (
        <div className={`popupOuter ${bit ? 'active' : ''}`}>
            <div className="userListPopupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">User List</p>
                    <img onClick={() => onChange(false)} src="/assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    <div className="userListTableOuter">
                        <table className="userListTable" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Select User</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {UserList?.map((item,index) => (
                                    <tr key={index}>
                                    <td>{item.Admin?.firstName + " " + item.Admin?.lastName}</td>
                                    <td><PrimaryButton onClick={() => handleClick(item.Admin?.id)} classname={'primaryBtnSmall'} children={'Assign'} /></td>
                                </tr>
                                ))}
                                {/* <tr>
                                    <td>Shweta Pandey</td>
                                    <td><PrimaryButton onClick={handleClick} classname={'primaryBtnSmall'} children={'Assign'} /></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserListPopup