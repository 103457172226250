import React, { useEffect } from 'react'
import './TermPlanDetailsStyle.css'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import TextField from '../../../components/globalComponents/textFields/TextField'
import Dropdown from '../../../components/globalComponents/dropdown/Dropdown'
import CustomDatePicker from '../../../components/globalComponents/customDatePicker/CustomDatePicker'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addTermDetail, termSelector, getTermDetailById, updateTermDetail,clearState } from '../../../reducers/termSlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ErrorToast from '../../../components/Toast/ErrorToast'
import PageLoader from "../../../components/PageLoader/PageLoader";


import moment from 'moment/moment'
import { clearCoursesliceState, courseSliceData, getAllProgramTerm } from '../../../reducers/courseSlice'


const TermPlanDetails = () => {

    const [searchParams] = useSearchParams();
    const proId = searchParams.get("proId") ?? "";
    const proMasterId = searchParams.get("proMasterId") ?? "";
    const proName = searchParams.get("proName") ?? "";

    const [term, setTerm] = useState({value:searchParams.get("term") ?? "",apply:false});
    const [isEdited, setIsEdited] = useState(false);
    const [toggler, setToggler] = useState(false)
    const [minimumElective, setMinimumElective] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [isShowError, setIsShowError] = useState(false);
    const errMessage = "Please fill all the required feilds"
    const dispatch = useDispatch()
    const { isSuccess, isFetching, termPlanDetail } = useSelector(termSelector);
   
    const {
        getTerm,termFetched
    } = useSelector(courseSliceData);
    const navigate = useNavigate()
console.log(termFetched,"termFetchedtermFetched");
    
    useEffect(() => {

        const obj = {
            proMasterId: proMasterId,
            proId: proId,
            term: term.value
        }
        dispatch(getTermDetailById(obj)) 
        dispatch(getAllProgramTerm(obj));
  
    }, []);

    useEffect(()=>{
        dispatch(clearCoursesliceState())
    },[getTerm])

    useEffect(() => {
        console.log("tERM PLAN DETAIL", termPlanDetail);
        if (termPlanDetail?.id) {
            setStartDate(moment(termPlanDetail.startDate).format("YYYY-MM-DD"))
            setEndDate(moment(termPlanDetail.endDate).format("YYYY-MM-DD"))

            setMinimumElective(termPlanDetail.minElectiveCourse);
            setToggler(termPlanDetail.isBiddingOpen);
            setIsEdited(true);
        }
        else {
            setEndDate("");
            setStartDate("");
            setMinimumElective("");
            setToggler(false);
            setIsEdited(false);
            setTerm(prev=>({...prev,apply:false}))
        }

    }, [termPlanDetail])

    useEffect(()=>{
      if(term.apply) { const obj = {
            proMasterId: proMasterId,
            proId: proId,
            term: term.value
        }
        dispatch(getTermDetailById(obj));
        setTerm(val=>({...val,apply:false}))
    }
    },[term])

    useEffect(()=>{
     if(isSuccess){
        dispatch(clearState());
        navigate(`/courseBidding?proId=${proId}&proMasterId=${proMasterId}&proName=${proName}`)
     }
    },[isSuccess])

    console.log(endDate);
    console.log(startDate);
    console.log(minimumElective);
    console.log(startDate);


    const courseDetailsHandleChange = (e) => {
        const { name, value } = e.target
        if (name === "startDate") setStartDate(value)
        else {
            setEndDate(value);
        }

    }

    const handleSave = () => {

        if (startDate !== "" && endDate !== "" && minimumElective !== "") {
            const payload = {
                programMasterId: proMasterId,
                startDate: startDate,
                endDate: endDate,
                programId: proId,
                term: term.value,
                minElectiveCourse: minimumElective,
                isBiddingOpen: toggler
            }

          if(isEdited)dispatch(updateTermDetail(payload));
          else{
            dispatch(addTermDetail(payload))
          }
        }
        else {
            setIsShowError(true)
        }
    }



    return (
        <>
            <ErrorToast
                show={isShowError}
                setShow={() => setIsShowError(false)}
                message={errMessage}
            />
           { isFetching && <PageLoader />}
            <div className="pageHeader">
                <div className="backBtn" onClick={()=>navigate(-1)}>
                    <img src="/assets/icons/back-btn.svg" alt="" />
                    Back to Page
                </div>
                <div className="pageTogglers">
                    <div className="pageToggler active">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5001 18.3334C15.1025 18.3334 18.8334 14.6025 18.8334 10.0001C18.8334 5.39771 15.1025 1.66675 10.5001 1.66675C5.89771 1.66675 2.16675 5.39771 2.16675 10.0001C2.16675 14.6025 5.89771 18.3334 10.5001 18.3334ZM14.9004 7.73369C15.1444 7.48961 15.1444 7.09388 14.9004 6.84981C14.6563 6.60573 14.2606 6.60573 14.0165 6.84981L9.45842 11.4079L7.40036 9.34981C7.15628 9.10573 6.76055 9.10573 6.51647 9.34981C6.2724 9.59388 6.2724 9.98961 6.51647 10.2337L9.01647 12.7337C9.26055 12.9778 9.65628 12.9778 9.90036 12.7337L14.9004 7.73369Z" fill="#1C7C54" />
                        </svg>
                        Term Plan Details
                    </div>
                </div>
                <div></div>
                {/* <div className='pageHeaderBtnGrop'>
                    <PrimaryButton children={'Save'} classname={'successBtn'} />
                </div> */}
            </div>
            <div className="termPlanDetailContainer">
                <div className="termMenuOuter">
                    <p className="termPlanDetailsHeading">Term Plan Details</p>
                    <div className="termMenu">
  
                      { getTerm?.length>0 && getTerm.map((val,index)=> <div className={`termMenuItem ${term.value===val?"active":""}`} key={index} onClick={()=>{if(val!==term)setTerm({value:val,apply:true})}}>
                            <div className="termHeader">
                                <div className="completedTag">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625ZM0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9ZM12.7727 6.35225C12.9924 6.57192 12.9924 6.92808 12.7727 7.14775L8.27275 11.6477C8.05308 11.8674 7.69692 11.8674 7.47725 11.6477L5.22725 9.39775C5.00758 9.17808 5.00758 8.82192 5.22725 8.60225C5.44692 8.38258 5.80308 8.38258 6.02275 8.60225L7.875 10.4545L11.9773 6.35225C12.1969 6.13258 12.5531 6.13258 12.7727 6.35225Z" fill="#1C7C54" />
                                    </svg>
                                    Completed
                                </div>
                                <div className="termMenuHeader">
                                    <p>Term {val} | Cohort 2</p>
                                    <img src="/assets/icons/chevron-down-black.svg" alt="" />
                                </div>
                            </div>
                            <div className="termPlanBody">
                                <div className="program">
                                    <div className="programName">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.0625C5.16852 2.0625 2.0625 5.16852 2.0625 9C2.0625 12.8315 5.16852 15.9375 9 15.9375C12.8315 15.9375 15.9375 12.8315 15.9375 9C15.9375 5.16852 12.8315 2.0625 9 2.0625ZM0.9375 9C0.9375 4.5472 4.5472 0.9375 9 0.9375C13.4528 0.9375 17.0625 4.5472 17.0625 9C17.0625 13.4528 13.4528 17.0625 9 17.0625C4.5472 17.0625 0.9375 13.4528 0.9375 9ZM12.7727 6.35225C12.9924 6.57192 12.9924 6.92808 12.7727 7.14775L8.27275 11.6477C8.05308 11.8674 7.69692 11.8674 7.47725 11.6477L5.22725 9.39775C5.00758 9.17808 5.00758 8.82192 5.22725 8.60225C5.44692 8.38258 5.80308 8.38258 6.02275 8.60225L7.875 10.4545L11.9773 6.35225C12.1969 6.13258 12.5531 6.13258 12.7727 6.35225Z" fill="#1C7C54" />
                                        </svg>
                                        Progam Name
                                    </div>
                                    <img src="/assets/icons/arrow-narrow-right-green.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        )}
                       
                    </div>
                </div>
                <div className="termFormContainer">
                    <div className="termFormOuter">
                        <div className="termForm">
                            {/* <div className="input">
                                <Dropdown label={'Select Program'} />
                            </div>
                            <div className="input">
                                <Dropdown label={'Select Term'} />
                            </div>
                            <div className="input">
                                <Dropdown label={'Select Batch'} />
                            </div> */}
                            {console.log(minimumElective, "ok bro")}
                            <div className="input">
                                <Dropdown isImportant={true} label={'Minimun Elective Course'} options={[1, 2, 3, 4, 5]} onChange={setMinimumElective} selected={minimumElective} />
                            </div>
                            <div className="input">
                                <TextField
                                    inputType={"date"}
                                    labelName={"Start Date"}
                                    isImportant={true}
                                    name="startDate"
                                    value={startDate}
                                    onChange={courseDetailsHandleChange}
                                />

                            </div>
                            <div className="input">
                                <TextField
                                    inputType={"date"}
                                    labelName={"End Date"}
                                    isImportant={true}
                                    name="endDate"
                                    value={endDate}
                                    onChange={courseDetailsHandleChange}
                                    min={startDate === "" ? moment(new Date()).format("YYYY-MM-DD") : startDate}
                                />

                            </div>
                        </div>
                        <div className="togglerContainer">
                            <p>Enable Term Bidding</p>
                            <div className={`toggler ${toggler ? 'active' : ''}`} onClick={() => setToggler(!toggler)}>
                                <div className="togglerBtn"></div>
                            </div>
                        </div>
                    </div>
                    <div className="termFormFooter">
                        <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Cancel'} onClick={()=>navigate(-1)}/>
                        <PrimaryButton classname={'primaryBtn'} children={`${isEdited ? "Update" : "Save"}`} onClick={handleSave} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermPlanDetails