import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import './QuizPopupStyle.css'
const QuizPopup = () => {
    return (
        <div className="popupOuter">
            <div className="popupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Question 1/10</p>
                    <div className="timer">
                        <p>Time: <span>09:45</span></p>
                    </div>
                    {/* <img src="/assets/icons/x-close.svg" alt="" /> */}
                </div>
                <div className="resultSection">
                        <p>Your Result: <span>8/10 Points</span></p>
                </div>
               
                <div className="popupBody">
                    
                    <p className="question">
                        Futures Trading involves trading in contracts in the derivatives markets.
                        This module covers the various intricacies involved in undergoing a futures
                        trade including margins, leverages, pricing, etc.
                    </p>
                    <div className="optionsOuter">

                        <div className="option correct">
                            <input type="radio" id="test1" name="radio-group" />
                            <label for="test1">The various intricacies involved in undergoing</label>
                        </div>

                        <div className="option wrong">
                            <input type="radio" id="test2" name="radio-group" />
                            <label for="test2">Undergoing a futures</label>
                        </div>

                        <div className="option">
                            <input type="radio" id="test3" name="radio-group" />
                            <label for="test3">This module covers the various </label>
                        </div>

                        <div className="option">
                            <input type="radio" id="test4" name="radio-group" />
                            <label for="test4">Trading involves trading</label>
                        </div>

                    </div>
                </div>
                <div className="popupFooter">
                    <PrimaryButton classname={'primaryBtn'} children={'Back'} isdisabled={true}/>
                    <div className="pointerScale">
                        <div className="point"></div>
                        <div className="point"></div>
                        <div className="point"></div>
                        <div className="point"></div>
                        <div className="point"></div>
                        <div className="point"></div>
                        <div className="point"></div>
                        <div className="point"></div>
                    </div>
                    <PrimaryButton classname={'primaryBtn'} children={'Next'} isdisabled={false}/>
                </div>
            </div>
        </div>
    )
}

export default QuizPopup