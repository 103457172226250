import React, { useState } from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import CustomDatePicker from '../../globalComponents/customDatePicker/CustomDatePicker'
import CustomUploadBox from '../../globalComponents/customUploadBox/CustomUploadBox'
import TextArea from '../../globalComponents/textArea/TextArea'
import TextField from '../../globalComponents/textFields/TextField'

const Bankloan = ({isOpen,setOpen}) => {

    const [processCount, setProcessCount] = useState(1)

    return (
        
        <>

        <div className={`popupOuter feePopup bankloan ${isOpen? 'open-popup' : '' }`}>
            <div className="popupContainer">

                <div className="popupHeader">
                    <p className="popupTitle">Send Due Fee</p>
                    <img src="/assets/icons/x-close.svg" onClick={()=> setOpen(false)} alt="" className='close-pointer'/>
                </div>

                <div className="popupBody">
                    <div className="payment-type-wrapper bank-loan">
                        <div className="bank-process">
                            <div className={`steps ${processCount >= 1 ? 'active' : ''}`}>
                                <small className='step-count'>1</small>
                                <span>Applicant Details</span>
                            </div>
                            <div className={`steps ${processCount >= 2 ? 'active' : ''}`}>
                                <small className='step-count'>2</small>
                                <span>Upload Documents</span>
                            </div>
                            <div className={`steps ${processCount === 3 ? 'active' : ''}`}>
                                <small className='step-count'>3</small>
                                <span>Loan Response</span>
                            </div>
                        </div>
                    </div>

                    <div className={`raiseTicketContainerOuter bankloan-wrapper ${processCount === 1 ? 'active' : ''}`}>
                        <div className="raiseTicketContainer">
                            <div className='bank-loan-heading'>Loan Process Documentation</div>
                            <div className="raiseTicketContainerInner">
                                <TextField labelName={'Email'} inputType={'email'} placeholder={'Enter event name'} />
                                <TextField labelName={'Learner Name'} inputType={'text'} placeholder={'Name details'} />
                                <div className="formGrp no-margin">
                                    <label htmlFor="">Select Date</label>
                                    <CustomDatePicker />
                                </div>
                                <TextField labelName={'Applicants Name'} inputType={'text'} placeholder={'Enter your name'} isImportant={true}/>
                                <TextField labelName={'Applicants Email ID'} inputType={'email'} placeholder={'Enter your amount'} isImportant={true}/>
                                <TextField labelName={'Applicants Mobile Number'} inputType={'text'} placeholder={'Enter your amount'} />
                                <TextField labelName={'Loan Amount Required'} inputType={'text'} placeholder={'Enter your amount'} />
                                <TextArea labelName={'Send Message for Approval'} placeholder={'Most personal loans have fixed interest rates, which means that your payments will stay the same every month. Personal loans are also typically unsecured, meaning there’s no collateral behind the loan. If you don’t qualify for an unsecured personal loan, you may have to use collateral to be approved, like a savings account.'} />
                                <div className="form2Col">
                                    <div className="formGrp no-margin">
                                        <label htmlFor="">Applicant's Employment <span className="impMark">*</span></label>
                                        <div className="radioGrpFlex">
                                            <div className="radioBtn">
                                                <input type="radio" id="test1" name="radio-group1" />
                                                <label for="test1">Salaried</label>
                                            </div>
                                            <div className="radioBtn">
                                                <input type="radio" id="test2" name="radio-group1" />
                                                <label for="test2">Self Employed</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGrp no-margin">
                                        <label htmlFor="">Applicant's house is <span className="impMark">*</span></label>
                                        <div className="radioGrpFlex">
                                            <div className="radioBtn">
                                                <input type="radio" id="test3" name="radio-group2" />
                                                <label for="test3">Owned</label>
                                            </div>
                                            <div className="radioBtn">
                                                <input type="radio" id="test4" name="radio-group2" />
                                                <label for="test4">Rented</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <TextField labelName={'Applicants Email ID'} inputType={'email'} placeholder={'Enter your amount'} isImportant={true}/>
                                <TextField labelName={'Applicants Company Address with pin code'} inputType={'text'} placeholder={'Enter your amount'} />
                                <TextField labelName={'Kindly select your loan tenure'} inputType={'text'} placeholder={'Enter your amount'} isImportant={true}/>
                            </div>
                        </div>
                    </div>

                    <div className={`raiseTicketContainerOuter bankloan-wrapper ${processCount === 2 ? 'active' : ''}`}>0
                        <div className="raiseTicketContainer">
                            <div className='bank-loan-heading'>Loan Process Documentation</div>
                            <div className="raiseTicketContainerInner">

                                <div className="formGrp no-margin">
                                    <label htmlFor="">Applicant's Aadhar Card <span className="impMark">*</span></label>
                                    <CustomUploadBox />
                                </div>

                                <div className="formGrp no-margin">
                                    <label htmlFor="">Applicant's PAN Card <span className="impMark">*</span></label>
                                    <CustomUploadBox />
                                </div>

                                <div className="formGrp no-margin">
                                    <label htmlFor="">Applicant's 3 month latest bank statements <span className="impMark">*</span></label>
                                    <CustomUploadBox />
                                </div>

                                <div className="formGrp no-margin">
                                    <label htmlFor="">Applicant's 3 month Salary Slips</label>
                                    <CustomUploadBox />
                                </div>

                                <div className="formGrp no-margin">
                                    <label htmlFor="">Applicant's 6 month latest bank statement <span className="impMark">*</span></label>
                                    <CustomUploadBox />
                                </div>

                                <div className="formGrp no-margin">
                                    <label htmlFor="">Applicant's business proof</label>
                                    <CustomUploadBox />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`raiseTicketContainerOuter bankloan-wrapper loan-response ${processCount === 3 ? 'active' : ''}`}>
                        <div className="raiseTicketContainer">

                            <div className="loan-reponse-wrapper">

                                <div className="loan-reponse-slot">
                                    <div className="loan-box">
                                        <p className="loan-heading">Loan Applicant Details</p>
                                        <small className="loan-status">Loan Application has been sent.</small>
                                    </div>
                                    <div className="loan-process">
                                        <span className='successSecondaryBtnSmall verified'>Submitted</span>
                                    </div>
                                </div>

                                <div className="loan-reponse-slot">
                                    <div className="loan-box">
                                        <p className="loan-heading">Document Uploaded</p>
                                        <small className="loan-status">Loan Application has been sent.</small>
                                    </div>
                                    <div className="loan-process">
                                        <span className='successSecondaryBtnSmall verified'>Submitted</span>
                                    </div>
                                </div>

                                <div className="loan-reponse-slot">
                                    <div className="loan-box">
                                        <p className="loan-heading">Loan Process Documentation</p>
                                        <small className="loan-status">Waiting for Bank Response</small>
                                    </div>
                                    <div className="loan-process">
                                        <span className='successSecondaryBtnSmall pending'>Pending</span>
                                    </div>
                                    <div className="approved active">
                                        <p className="bank-name">Bank partners: <span className='approve-text'>State Bank of India</span></p>
                                        <p className="bank-name">Loan amount: <span className='approve-text'>₹ 28,050.00</span></p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>

                <div className={`popupFooter bank-loan-footer ${processCount === 3 ? 'hide' : ''}`}>
                    {processCount === 1 && <PrimaryButton children={'Proceed to upload documents'} className={'successBtn'} onClick={() => setProcessCount(2)}/>}
                    {processCount === 2 && <PrimaryButton children={'Send for Approval'} className={'successBtn'} onClick={() => setProcessCount(3)}/>}
                </div>
                
            </div>
        </div>

        </>
    )
}

export default Bankloan