import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../components/globalComponents/breadCrumbs/BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import './FeedbackAnswerStyle.css';
import {
  feedbackData,
  getFeedbackStudentAnswer,
  getFeedbackDetailById,
  clearState
} from "../../../reducers/feedbackSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";

const FeedbackAnswer = () => {
  const param = useParams();
  const feedId = param?.fbId;
  const stuId = param?.stuId;
  const [questionArray, setQuestionArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  // const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const type = searchParams.get("type");

  // const layers = [
  //   "List of feedback",
  //   "Product Management | Session",
  // ];

  const {
    isFetching,
    isError,
    feedbackDetail,
    studentAnswer,
    feedbackAnswerIsFetching,
  } = useSelector(feedbackData);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isError) {
      // toast.error(errorMessage);
      dispatch(clearState());
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if(feedbackAnswerIsFetching || isFetching){
      setIsLoading(true);
    }
    else{
      setIsLoading(false);
    }
  }, [isFetching,feedbackAnswerIsFetching]);

  useEffect(() => {
    try {
      if (feedId === null || feedId === undefined || feedId === "null") {
        return;
      }
      const obj = {
        feedbackId: feedId,
        studentId: stuId
      }
      dispatch(getFeedbackDetailById(feedId));
      dispatch(getFeedbackStudentAnswer(obj));

    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line
  }, [feedId]);

  useEffect(() => {
    console.log("getting feedback details", feedbackDetail);
    if (
      feedbackDetail === null ||
      feedbackDetail === undefined ||
      feedbackDetail === "null"
    ) {
      return;
    }
    // setPreviewType(feedbackDetail && feedbackDetail.previewType);
    // setLayoutType(feedbackDetail && feedbackDetail.layoutType);

    var array = [];

    feedbackDetail &&
      feedbackDetail.FeedbackQuestions?.forEach((element, index) => {
        var item = {
          id: element.id,
          questionType: element.questionType,
          questionName: element.questionName,
          isMandatory: element.isMandatory,
          option: element.FeedbackQuestionOptions,
        };

        array.push(item);
      });

    // console.log("fdfddffd", array);
    setQuestionArray(array);
    // setAnswersArray(studentAnswer);

  }, [feedbackDetail]);

  useEffect(() => {
    console.log('studentAnswer:  ', studentAnswer);
    if (studentAnswer === undefined || studentAnswer === null) {
      return;
    }
    const res = Object?.values(
      studentAnswer?.reduce(
        (a, { feedbackQuestionId, feedbackOptionId, answer }) => {
          a[feedbackQuestionId] = a[feedbackQuestionId] || {
            feedbackQuestionId,
            feedbackOptionId: new Set(),
            answer,
          };
          a[feedbackQuestionId].feedbackOptionId.add(feedbackOptionId);
          return a;
        },
        {}
      )
    ).map(({ feedbackQuestionId, feedbackOptionId, answer }) => ({
      feedbackQuestionId,
      feedbackOptionId: [...feedbackOptionId],
      answer: answer,
    }));

    console.log(res);
    setFilteredAnswers(res);

  }, [studentAnswer]);

  return (

    <>
      {isLoading && <PageLoader />}
    <div className="pageHeader">

      <div className="backBtn">
         
        </div>
        <div>
          <label><b>{type} Answers</b></label>
        </div>
        <div></div>
    </div>
      <div className="main-wrapper with-card feedback-wrapper">

        {questionArray.map((item, i) => {
          const {
            id,
            questionType,
            questionName,
            // answers,
            // title,
            // desc,
            // required,
          } = item;

          let questionAnswer = filteredAnswers.filter((answer) => {
            return answer.feedbackQuestionId === id;
          });

          questionAnswer = questionAnswer[0];

          return (
            <React.Fragment key={id} >
              {questionType === "Paragraph" && (
                <div className='fullScreenWrapper'>
                  <div className="question-box">
                    <h2 className="heading-2">{questionName}</h2>
                    <p className="feedbacka-answer">
                      {questionAnswer?.answer}
                    </p>
                  </div>
                </div>
              )}

              {questionType === "Single Select" && (
                <div className='fullScreenWrapper'>
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="singleselect mt-30 col-3">
                            {item.option.map((listItem) => (
                              <label style={type !== "Quiz" ? (questionAnswer?.feedbackOptionId.includes(
                                listItem.id
                              ))  ? { background:"#FCE794" } : null : null} className={`singleselectlabel ${type === "Quiz" ?
                                
                              (questionAnswer?.feedbackOptionId.includes(
                                  listItem.id
                                ) && listItem.isCorrect === false
                                  ? "feedback-danger"
                                  : "") : null}

                              ${listItem.isCorrect === true ? "feedback-success" : ""}

                              
                              `} key={listItem.id}>
                                <input
                                  className="singleselect-btn"
                                  disabled
                                  onChange={() => { }}
                                  checked={questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )}
                                  type="radio"
                                />
                                <span className="singleselectradio" />
                                {listItem.value}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {questionType === "Multi Select" && (
                <div className='fullScreenWrapper'>
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="mt-30 checkmark-wrapper col-3">
                            {item.option.map((listItem) => (
                              <label className={`checkmark-label ${questionAnswer?.feedbackOptionId.includes(
                                listItem.id) ? 'active' : ''}`} key={listItem.id}>
                                <input
                                  type="checkbox"
                                  disabled
                                  onChange={() => { }}
                                  className="checkmark-btn"
                                  checked={questionAnswer?.feedbackOptionId.includes(
                                    listItem.id
                                  )}
                                />
                                <span className="checkmarkspan"></span>
                                {listItem.value}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {questionType === "Upload Media" && (
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>
                          <div className="mt-30 checkmark-wrapper col-3">
                             {/* <img src=  width="auto" height="auto"></img>
                              */}
                              <a href={questionAnswer?.answer} target="_blank">Click to Preview</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {console.log(questionAnswer?.answer,"pppp")}
              {questionType === "Rating" && (
                <div className='fullScreenWrapper'>
                  <div className="question-box">
                    <div className="inside-shell">
                      <div className="inside-mid card-style">
                        <div className="inside-content">
                          <h2 className="heading-2">{questionName}</h2>

                          <div className="rating-wrapper mt-30">
                            <div className="rating">
                              <input
                                checked={questionAnswer?.answer === "5"}
                                onChange={() => { }}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}5`}
                              />
                              <label htmlFor="rating-5"></label>
                              <input
                                checked={questionAnswer?.answer === "4"}
                                onChange={() => { }}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}4`}
                              />
                              <label htmlFor="rating-4"></label>
                              <input
                                checked={questionAnswer?.answer === "3"}
                                onChange={() => { }}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}3`}
                              />
                              <label htmlFor="rating-3"></label>
                              <input
                                checked={questionAnswer?.answer === "2"}
                                onChange={() => { }}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}2`}
                              />
                              <label htmlFor="rating-2"></label>
                              <input
                                checked={questionAnswer?.answer === "1"}
                                onChange={() => { }}
                                type="radio"
                                name={`rating ${i}`}
                                id={`rating-${i}1`}
                              />
                              <label htmlFor="rating-1"></label>
                            </div>
                            <div className="rating-count">
                              {questionAnswer?.answer} out of 5
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </>
  )
};
export default FeedbackAnswer;