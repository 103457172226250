import React from "react";
import "./TextAreaStyle.css";

const TextArea = ({
  labelName,
  placeholder,
  isImportant,
  isDisabled,
  onChange,
  value,
  name,
  error
}) => {

  return (
    <div className="formGroup dFlex gap5 flexColumn">
      <label htmlFor="Email">
        {labelName}
        {isImportant ? <span className="impMark">*</span> : null}
      </label>
      <textarea
        className="formInput"
        value={value}
        rows={5}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        disabled={isDisabled}
      />
      {error ? <label style={{color:'#d80000',fontSize:'12px'}}>Please fill this field</label> : null}
    </div>
  );
};

export default TextArea;
