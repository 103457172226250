import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from '../environment/environment'
export const loginUser = createAsyncThunk(
    "loginUser",
    async ({ email, password }, thunkAPI) => {
      try {
        const payload = {
          email: email,
          password: password,
        };
        const response = await fetch(`${environment.baseURL}/api/org/admin/auth`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        let data = await response.json();
        console.log("response", data);
        if (response.status === 200) {
          const name = data.Data.firstName + " " + data.Data.lastName;
          localStorage.setItem("token", data.Token);
          localStorage.setItem("orgId", data.Data.Organization.id);
          localStorage.setItem("adminId", data.Data.id);
          localStorage.setItem('name',name)
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e) {
        console.log("Error", e.response.data);
        thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );

  export const userSlice = createSlice({
    name: "loginAdmin",
    initialState: {
      LoginData:[],
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
      token:localStorage.getItem('token')?localStorage.getItem('token'):null,
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.isFetching = false;
  
        return state;
      },
    },
    extraReducers: {
      [loginUser.fulfilled]: (state, { payload }) => {
        state.LoginData = payload?.Data;
        state.isFetching = false;
        state.isSuccess = true;
        state.token= payload.Token;
        return state;
      },
      [loginUser.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload?.Message || "Something Went Wrong";
      },
      [loginUser.pending]: (state) => {
        state.isFetching = true;
      },
    },
  });
  
  export const { clearState } = userSlice.actions;
  
  export const userSelector = (state) => state.user;
  