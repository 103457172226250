import React from 'react'
import './ClubDetailsStyle.css'
import PrimaryButtonImg from '../../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import ClubMemberListPopup from '../../../components/popups/clubMemberListPopup/ClubMemberListPopup'

const ClubDetails = () => {
    // const [showTable, setShowTable] = useState(true)
    return (
       <>
        <ClubMemberListPopup/>
        <div className="clubDetailsContainer">
            <div className="pageHeader">
                <p className="componentHeading"></p>
                <div></div>
                <div>
                    <PrimaryButtonImg classname={'primaryBtn'} children={'Create'} img={'/assets/icons/message-question-square-sml.svg'} />
                </div>
            </div>
            <div className="clubDetailsContainerOuter">
                <div className="clubDetailsCenterContainer">
                    <div className="clubHeaderOuter">
                        <div className="clubHeaderImg">
                            <img src="" alt="" />
                        </div>
                        <div className="clubBody">
                            <img src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" className="clubAvtar" />
                            <div className="clubDetailsOuter">
                                <div className="clubDetail">
                                    <div className='clubDetailHeader'>
                                        <p className="clubName">Harvard business Club</p>
                                        <div className="successTag">Music Club</div>
                                    </div>
                                    <p className="clubInfo">
                                        At Harvard Business Review, we believe in management. If the world’s organizations and
                                        institutions were run more effectively, if our leaders made better decisions,
                                        if people worked more productively,
                                    </p>
                                </div>
                                <div className="clubDetail">
                                    <PrimaryButton classname={'darkSecondaryOutlineBtnSmall'} children={'Guiding Document'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clubBodyOuter">
                        <div className="clubBodyBox">
                            <div className="boxHeader">
                                <p className="toggler active">Upcoming Events</p>
                                <p className="toggler">Past Events</p>
                            </div>
                            <div className="boxBody">
                                <table cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td>Learn Website Wireframing with Shravan Tickoo</td>
                                            <td>21st Jan 2022</td>
                                        </tr>
                                        <tr>
                                            <td>Learn Website Wireframing with Shravan Tickoo</td>
                                            <td>21st Jan 2022</td>
                                        </tr>
                                        <tr>
                                            <td>Learn Website Wireframing with Shravan Tickoo</td>
                                            <td>21st Jan 2022</td>
                                        </tr>
                                        <tr>
                                            <td>Learn Website Wireframing with Shravan Tickoo</td>
                                            <td>21st Jan 2022</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="boxFooter">
                                <p className="footerText">
                                    See all upcoming events
                                </p>
                                <img src="/assets/icons/chevron-right-double.svg" alt="" />
                            </div>
                        </div>
                        <div className="clubBodyBox">
                            <div className="presidentInfoBox">
                                <img src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                                <div className="presidentInfo">
                                    <div className="nameBox">
                                        <p className="name">Rahul Kapoor</p>
                                        <div className="successTag">President</div>
                                    </div>
                                    <p className="programName">Programme in Finance and Accounting
                                        | 2018-2020 |
                                    </p>
                                </div>
                            </div>
                            <div className="clubMembersList">
                                <div className="boxHeader">
                                    <p className="toggler active">Club Memeber</p>
                                    <p className="toggler">Club Report</p>
                                </div>
                                <div className="clubMemberList">
                                    <div className="presidentInfoBox">
                                        <img src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&amp;cs=tinysrgb&amp;w=600" alt="" />
                                        <div className="presidentInfo">
                                            <div className="nameBox">
                                                <p className="name">Rahul Kapoor</p>
                                            </div>
                                            <p className="programName">Ali@masterunion.com</p>
                                        </div>
                                    </div>
                                    <div className="presidentInfoBox">
                                        <img src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&amp;cs=tinysrgb&amp;w=600" alt="" />
                                        <div className="presidentInfo">
                                            <div className="nameBox">
                                                <p className="name">Rahul Kapoor</p>
                                            </div>
                                            <p className="programName">Ali@masterunion.com</p>
                                        </div>
                                    </div>
                                    <div className="presidentInfoBox">
                                        <img src="https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&amp;cs=tinysrgb&amp;w=600" alt="" />
                                        <div className="presidentInfo">
                                            <div className="nameBox">
                                                <p className="name">Rahul Kapoor</p>
                                            </div>
                                            <p className="programName">Ali@masterunion.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="clubMemberFooter">
                                    <p>See All Memebers <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.19064 3.77365C3.3615 3.60279 3.6385 3.60279 3.80936 3.77365L6.72603 6.69032C6.89688 6.86117 6.89688 7.13818 6.72603 7.30903L3.80936 10.2257C3.6385 10.3966 3.3615 10.3966 3.19064 10.2257C3.01979 10.0548 3.01979 9.77784 3.19064 9.60698L5.79795 6.99967L3.19064 4.39237C3.01979 4.22151 3.01979 3.9445 3.19064 3.77365ZM7.27397 3.77365C7.44483 3.60279 7.72184 3.60279 7.89269 3.77365L10.8094 6.69032C10.9802 6.86117 10.9802 7.13818 10.8094 7.30903L7.89269 10.2257C7.72184 10.3966 7.44483 10.3966 7.27397 10.2257C7.10312 10.0548 7.10312 9.77784 7.27397 9.60698L9.88128 6.99967L7.27397 4.39237C7.10312 4.22151 7.10312 3.9445 7.27397 3.77365Z" fill="#006CD4" />
                                    </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </>
    )
}

export default ClubDetails