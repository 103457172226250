import React from "react";

const FeedbackQuestionMenu = ({
  questionArray,
  setNewQuestion,
  onClickEditQuestion,
}) => {
  const onItemDeleteClick = (id, index) => {
    var updatedArray = questionArray.filter((item) => item.id !== id);

    var filteredArray = [];

    updatedArray.forEach((element, index) => {
      var item = {
        id: index + 1,
        questionType: element.questionType,
        questionName: element.questionName,
        isNotMandatory: element.isNotMandatory,
        option: element.option,
      };
      filteredArray.push(item);
    });

    setNewQuestion(filteredArray);
  };

  return (
    <>
      {questionArray?.map((item, index) => (
        <div key={item.id} className="questionListItem">
          <div className="detailArea">
            <img src="/assets/icons/star-01.svg" alt="" />
            <p className="question">
              Q{index + 1}. {item.questionName}
            </p>
          </div>
          <div className="mutiiconsWrapper">
            <svg
              className="multiicons editIcon"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                onClickEditQuestion(item.questionType, item.id);
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.4697 2.46973C17.8672 1.07226 20.1329 1.07227 21.5304 2.46973C22.9278 3.86719 22.9278 6.13293 21.5304 7.53039L8.32406 20.7367C8.30943 20.7513 8.29499 20.7658 8.28072 20.78C8.02876 21.0322 7.83033 21.2309 7.59577 21.3855C7.38892 21.522 7.16458 21.6298 6.92884 21.7061C6.66153 21.7926 6.38247 21.8235 6.02812 21.8627C6.00805 21.8649 5.98775 21.8671 5.9672 21.8694L2.58283 22.2455C2.35638 22.2706 2.13078 22.1915 1.96967 22.0304C1.80857 21.8693 1.72943 21.6437 1.75459 21.4172L2.13063 18.0329C2.13292 18.0123 2.13517 17.992 2.13739 17.9719C2.17658 17.6176 2.20744 17.3385 2.29398 17.0712C2.37029 16.8355 2.47812 16.6111 2.61452 16.4043C2.76919 16.1697 2.96782 15.9713 3.22002 15.7194C3.23431 15.7051 3.24877 15.6906 3.2634 15.676L16.4697 2.46973ZM20.4697 3.53039C19.658 2.71871 18.342 2.71871 17.5304 3.53039L4.32406 16.7367C4.00864 17.0521 3.92696 17.1388 3.86676 17.2301C3.80476 17.3241 3.75575 17.4261 3.72106 17.5332C3.68738 17.6372 3.67072 17.7552 3.62146 18.1985L3.34895 20.6511L5.80155 20.3786C6.2449 20.3293 6.36282 20.3127 6.46686 20.279C6.57401 20.2443 6.67599 20.1953 6.77001 20.1333C6.8613 20.0731 6.94798 19.9914 7.2634 19.676L20.4697 6.46973C21.2814 5.65805 21.2814 4.34207 20.4697 3.53039Z"
                fill="black"
              />
            </svg>

            <svg
              className="multiicons removeIcon"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => onItemDeleteClick(item.id, index)}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.25 3C8.25 2.58579 8.58579 2.25 9 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3C15.75 3.41421 15.4142 3.75 15 3.75H9C8.58579 3.75 8.25 3.41421 8.25 3ZM4.99417 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6C2.25 6.41421 2.58579 6.75 3 6.75H4.29834L4.955 16.6C5.00586 17.363 5.04696 17.9796 5.12022 18.4786C5.19579 18.9932 5.31292 19.4431 5.54831 19.8563C5.92345 20.5147 6.48928 21.0441 7.17126 21.3746C7.59919 21.582 8.0558 21.6689 8.57438 21.7101C9.07707 21.75 9.6951 21.75 10.4598 21.75H13.5402C14.3049 21.75 14.9229 21.75 15.4256 21.7101C15.9442 21.6689 16.4008 21.582 16.8287 21.3746C17.5107 21.0441 18.0766 20.5147 18.4517 19.8563C18.6871 19.4431 18.8042 18.9932 18.8798 18.4786C18.953 17.9796 18.9941 17.363 19.045 16.6L19.7017 6.75H21C21.4142 6.75 21.75 6.41421 21.75 6C21.75 5.58579 21.4142 5.25 21 5.25H19.0058C19.0014 5.24996 18.9971 5.24996 18.9927 5.25H5.00732C5.00294 5.24996 4.99856 5.24996 4.99417 5.25ZM18.1983 6.75H5.80166L6.44963 16.4694C6.50303 17.2704 6.54072 17.8276 6.60431 18.2607C6.66657 18.6847 6.74617 18.9286 6.85165 19.1138C7.07673 19.5088 7.41623 19.8265 7.82542 20.0248C8.01718 20.1177 8.26585 20.1809 8.69307 20.2148C9.12943 20.2495 9.68783 20.25 10.4907 20.25H13.5093C14.3122 20.25 14.8706 20.2495 15.3069 20.2148C15.7342 20.1809 15.9828 20.1177 16.1746 20.0248C16.5838 19.8265 16.9233 19.5088 17.1483 19.1138C17.2538 18.9286 17.3334 18.6847 17.3957 18.2607C17.4593 17.8276 17.497 17.2704 17.5504 16.4694L18.1983 6.75ZM10 9.75C10.4142 9.75 10.75 10.0858 10.75 10.5V15.5C10.75 15.9142 10.4142 16.25 10 16.25C9.58579 16.25 9.25 15.9142 9.25 15.5V10.5C9.25 10.0858 9.58579 9.75 10 9.75ZM14 9.75C14.4142 9.75 14.75 10.0858 14.75 10.5V15.5C14.75 15.9142 14.4142 16.25 14 16.25C13.5858 16.25 13.25 15.9142 13.25 15.5V10.5C13.25 10.0858 13.5858 9.75 14 9.75Z"
                fill="black"
              />
            </svg>

            {/* <img src="/assets/icons/admin-v2-editBtn.svg" className="multiicons editIcon"/>
            <img src="/assets/icons/admin-v2-delete-btn.svg" className="multiicons removeIcon" onClick={() => onItemDeleteClick(item.id, index)}/> */}
          </div>
          {/* <img
            src="/assets/icons/x-close-danger.svg"
            alt=""
            className="pointer"
            onClick={() => onItemDeleteClick(item.id, index)}
          /> */}
        </div>
      ))}
    </>
  );
};

export default FeedbackQuestionMenu;
