import React from 'react'
import './FeedbackPopupStyle.css'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import TextArea from '../../globalComponents/textArea/TextArea'

const FeedbackPopup = ({ title, boldText,text, label, btnType, btnText }) => {
    return (
        <div className="popupOuter ">
            <div className="feedbackPopupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">{title}</p>
                    <img src="/assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    {text && <p className="question"><b>{boldText}</b> {text}</p>}
                    <TextArea labelName={label} placeholder={'Type Here ...'} isImportant={true} />
                </div>
                <div className="popupFooter flexEnd">
                    <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Cancel'} />
                    <PrimaryButton classname={btnType} children={btnText} />
                </div>
            </div>
        </div>
    )
}

export default FeedbackPopup