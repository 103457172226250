import React from 'react'
import './AnnouncementPopupStyle.css'
import moment from 'moment'
const AnnouncementPopup = ({view,setView,data,removeTags}) => {

  return (
    <div className={`popupOuter ${view ? 'active': ''}`}>
      <div className="announcementPopupContainer">
        <div className="popupHeader">
          <div className="popupHeaderLeft">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16" cy="16" r="16" fill="#F5F5F5" />
              <path d="M12.4999 25C12.3333 25 12.2499 25 12.1666 25C11.2499 24.9167 10.4999 24.3334 10.1666 23.4167C10.1666 23.3334 10.0833 23.25 10.0833 23.0834L8.74992 17.8334C8.74992 17.8334 8.74992 17.8334 8.74992 17.75C7.74992 16.9167 7.08325 15.6667 7.08325 14.3334C7.08325 11.9167 9.08325 10 11.4166 10H14.5833C15.6666 10 17.1666 9.50002 18.9166 8.50002C19.8333 8.00002 20.3333 7.75002 20.7499 7.83336C21.1666 7.91669 21.4999 8.08336 21.7499 8.41669C21.9999 8.83336 21.9999 9.25002 21.9999 10.3334V10.9167C23.6666 11.25 24.9166 12.6667 24.9166 14.4167C24.9166 16.1667 23.6666 17.5834 21.9999 17.9167V18.5C21.9999 19.5 21.9999 20 21.7499 20.4167C21.4999 20.75 21.1666 21 20.7499 21C20.2499 21.0834 19.8333 20.8334 18.9166 20.3334C17.3333 19.5 15.9999 19 14.9999 18.9167V22.6667C14.9999 22.9167 14.9999 23 14.9999 23.0834C14.9166 24.1667 13.9999 25 12.9166 25.1667C12.8333 24.9167 12.6666 25 12.4999 25ZM12.4999 23.6667C12.6666 23.6667 12.7499 23.6667 12.8333 23.6667C13.3333 23.5834 13.7499 23.25 13.7499 22.75C13.7499 22.6667 13.7499 22.5834 13.7499 22.4167V18.6667H11.4166C10.9999 18.6667 10.5833 18.5834 10.1666 18.5L11.2499 22.75C11.2499 22.9167 11.3333 22.9167 11.3333 23C11.4999 23.4167 11.8333 23.6667 12.2499 23.6667C12.2499 23.75 12.3333 23.6667 12.4999 23.6667ZM14.9999 17.5C16.2499 17.5834 17.7499 18.1667 19.5833 19.0834C20.0833 19.3334 20.5833 19.5834 20.7499 19.6667C20.7499 19.6667 20.8333 19.6667 20.8333 19.5834C20.8333 19.5 20.8333 19 20.8333 18.4167V10.25C20.8333 9.66669 20.8333 9.16669 20.7499 9.08336C20.7499 9.08336 20.6666 9.00002 20.5833 9.00002C20.4999 9.00002 19.9999 9.25002 19.4999 9.58336C17.7499 10.5834 16.1666 11.0834 14.9166 11.1667V17.5H14.9999ZM11.4166 11.1667C9.66659 11.1667 8.33325 12.5834 8.33325 14.3334C8.33325 16.0834 9.74992 17.5 11.4999 17.5H13.8333V11.1667H11.4166ZM21.9999 12.1667V16.5834C22.9999 16.3334 23.6666 15.4167 23.6666 14.4167C23.6666 13.4167 22.9999 12.4167 21.9999 12.1667Z" fill="black" />
            </svg>
            <p className="title">Announcements</p>
            <div className="primaryTag">{moment(data.announcementDate).format("DD-MM-YYYY")}</div>
          </div>
          <img src="../assets/icons/x-close.svg" alt=""  onClick={()=>setView(false)}/>
        </div>
        <div dangerouslySetInnerHTML={{__html:data?.announcementDescription}} className="popupBody">
        {console.log(data)}
        </div>
      </div>
    </div>
  )
}

export default AnnouncementPopup