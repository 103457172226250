import moment, { utc } from "moment";
import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
// import CustomDatePicker from "../../../components/globalComponents/customDatePicker/CustomDatePicker";
import TextArea from "../../../components/globalComponents/textArea/TextArea";
import TextField from "../../../components/globalComponents/textFields/TextField";
import { useDispatch, useSelector } from "react-redux";
import { getAllProgramsDetails, feedbackData, getAllBatchByProgramId, getAllSessionByCourseId, getAllCourseAdmin } from "../../../reducers/feedbackSlice";
import { useSearchParams } from "react-router-dom";

const CreateFeedbackForm = ({
  onClickNext,
  setFeedbackDetails,
  feedbackDetails,
  setProgramName,
  programName,
  batchName,
  courseName,
  sessionName,
  setBatchName,
  setCourseName,
  setSessionName,
  feedbackType
}) => {

  const [programs, setPrograms] = useState([]);
  const [batch, setBatch] = useState([]);
  const [course, setCourse] = useState([]);
  const [session, setSession] = useState([]);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const {
    batchDetail,
    programDetails,
    sessionDetail,
    AllCourseData,
  } = useSelector(feedbackData);

  const handleOnChangeFeedbackForm = (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === "isAnonymous") {
      newValue = newValue === "true" ? true : false;
    }

    setFeedbackDetails((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

  };

  useEffect(() => {
    if (programName) {
      let obj = {
        programId: programName,
        courseId: courseName,
      };
      dispatch(getAllSessionByCourseId(obj));
    }
  }, [programName, courseName])


  // useEffect(() => {
  //   if (programName) {
  //     dispatch(getAllBatchByProgramId(programName))
  //   }
  //   // eslint-disable-next-line
  // }, [programName])

  // useEffect(() => {
  //   if (batchName) {
  //     let obj = {
  //       proId: batchName,
  //       proMasterId: programName,
  //     };
  //     dispatch(getAllCourseAdmin(obj))
  //   }
  //   // eslint-disable-next-line
  // }, [batchName])

  // useEffect(() => {
  //   if (courseName) {
  //     let obj = {
  //       programId: batchName,
  //       courseId: courseName,
  //     }
  //     dispatch(getAllSessionByCourseId(obj));
  //   }
  //   // eslint-disable-next-line
  // }, [courseName])

  // useEffect(() => {
  //   setPrograms(programDetails);
  //   // eslint-disable-next-line
  // }, [programDetails]);

  // useEffect(() => {
  //   setBatch(batchDetail);
  //   // eslint-disable-next-line
  // }, [batchDetail]);

  // useEffect(() => {
  //   setCourse(AllCourseData)
  //   // eslint-disable-next-line
  // }, [AllCourseData])

  useEffect(() => {
    setSession(sessionDetail);
    // eslint-disable-next-line
  }, [sessionDetail])
  // const onProgramChange = (event) => {
  //   let programId = event.target.value;
  //   setProgramName(programId);
  //   setBatchName("");
  //   setCourseName("");
  //   setSessionName("");
  //   setBatchName("");
  // };


  // const onBatchChange = (e) => {
  //   let batchId = e.target.value;
  //   setCourseName("");
  //   setSessionName("");
  //   setBatchName(batchId);
  // };

  // const onCourseChange = (e) => {
  //   let courseId = e.target.value;
  //   setCourseName(courseId);
  //   setSessionName("")
  // };

  const onSessionChange = (e) => {
    let sessionId = e.target.value;
    setSessionName(sessionId);
  };

  return (
    <div className="createFeedbackFormContainerOuter">
      <div className="createFeedbackFormContainer">
        <div className="createFeedbackForm">
          <div className="addStudentFix twoSettle">
            <TextField
              labelName={`${type} Title`}
              isImportant={true}
              placeholder={"Write a title"}
              onChange={handleOnChangeFeedbackForm}
              name="title"
              inputType={"text"}
              value={feedbackDetails?.title}
              />
            {(
                <div className="OptionBoxNew2">
                <label htmlFor="type">Select Session <span class="impMark">*</span></label>
                <select className="searchBox" id="type" onChange={onSessionChange}>
                  <option hidden value="">Select Session</option>
                  {session?.map((ses, i) => (
                    <option selected={ses.id === sessionName ? true : false} key={i} value={ses.id}>
                      {ses.name}
                    </option>
                  ))}
                </select>
                </div>
            )}
          </div>
          
          <TextArea
            labelName={"Description"}
            isImportant={false}
            placeholder={"Write a description"}
            onChange={handleOnChangeFeedbackForm}
            name="description"
            inputType={"text"}
            value={feedbackDetails?.description}
          />
          <div className="formGroup" style={type === "Quiz" ? { display: 'none' } : null}>
            <label htmlFor="">Semester / Annual</label>
            <div
              onChange={handleOnChangeFeedbackForm}
              className="feedbackRadioGroup"
            >
              <div className="radioBtnOuter">
                <input
                  value="Class"
                  type="radio"
                  id="test1"
                  name="feedbackType"
                  checked={feedbackDetails?.feedbackType === "Class"}
                />
                <label for="test1">Class</label>
              </div>
              <div className="radioBtnOuter">
                <input
                  value="General"
                  type="radio"
                  id="test2"
                  name="feedbackType"
                  checked={feedbackDetails?.feedbackType === "General"}
                />
                <label for="test2">General</label>
              </div>
              <div className="radioBtnOuter">
                <input
                  value="Event"
                  type="radio"
                  id="test3"
                  name="feedbackType"
                  checked={feedbackDetails?.feedbackType === "Event"}
                />
                <label for="test3">Event</label>
              </div>
              {/* <div className="radioBtnOuter">
                <input
                  value="Session"
                  type="radio"
                  id="test4"
                  name="feedbackType"
                  checked={feedbackDetails?.feedbackType === "Session"}
                />
                <label for="test4">Session</label>
              </div> */}
              <div className="radioBtnOuter">
                <input
                  value="Others"
                  type="radio"
                  id="test5"
                  name="feedbackType"
                  checked={feedbackDetails?.feedbackType === "Others"}
                />
                <label for="test5">Others</label>
              </div>
            </div>
          </div>
          <div style={type === "Quiz" ? { display: 'none' } : null} className="formGroup">
            <label htmlFor="">
              Do you want to allow anonymous feedback?{" "}
              <span className="impMark">*</span>
            </label>
            <div
              onChange={handleOnChangeFeedbackForm}
              className="feedbackRadioGroup"
            >
              <div className="radioBtnOuter">
                <input
                  value={true}
                  checked={feedbackDetails?.isAnonymous === true}
                  type="radio"
                  id="test7"
                  name="isAnonymous"
                />
                <label for="test7">Yes</label>
              </div>
              <div className="radioBtnOuter">
                <input
                  value={false}
                  checked={feedbackDetails?.isAnonymous === false}
                  type="radio"
                  id="test8"
                  name="isAnonymous"
                />
                <label for="test8">No</label>
              </div>
            </div>
          </div>
          <div className="form2Col gap10">
            <TextField
              inputType={"datetime-local"}
              labelName={`Set expiry date and time of this ${type}`}
              isImportant={true}
              name="expiryDate"
              value={moment.utc(feedbackDetails?.expiryDate).format("YYYY-MM-DD HH:mm:ss")}
              onChange={handleOnChangeFeedbackForm}
              min={moment(new Date()).format("YYYY-MM-DDThh:mm")}
            />
            {/* <TextField
              inputType={"time"}
              labelName={`Set expiry time of this ${type}`}
              isImportant={true}
              name="expiryTime"
              value={feedbackDetails?.expiryTime}
              onChange={handleOnChangeFeedbackForm}
              min={moment(new Date()).format("YYYY-MM-DD")}
            /> */}
          </div>
        </div>

        <div className="createFeedbackFooter">
          <PrimaryButton
            onClick={onClickNext}
            children={"Next"}
            classname={"primaryBtn"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateFeedbackForm;
