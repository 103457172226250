import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import PrimaryButtonImg from '../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
// import CustomDatePicker from '../../components/globalComponents/customDatePicker/CustomDatePicker'
import Dropdown from '../../components/globalComponents/dropdown/Dropdown'
// import TextArea from '../../components/globalComponents/textArea/TextArea'
import TextField from '../../components/globalComponents/textFields/TextField'
import AddStudentsPopup from '../../components/popups/addStudentPopup/AddStudentsPopup'
import SunEditor from "suneditor-react";
import moment from 'moment/moment'
import ErrorToast from '../../components/Toast/ErrorToast'
import { useDispatch, useSelector } from 'react-redux'
import { addCourseGroupAssignment, assignmentSelector } from '../../reducers/assignmentSlice'
import PageLoader from '../../components/PageLoader/PageLoader'



const CreateGroupAssignment = () => {

    const [submissionType, setSubmissionType] = useState('');
    const [brief, setBrief] = useState('');
    const [groupCount, setGroupCount] = useState([0]);
    const [popupToggler, setPopupToggler] = useState(false);
    const [field, setField] = useState({ name: "", totalPoint: "", })
    const options = ["Text Entry", "Website URL", "File Uploads"]
    const [values, setValues] = useState({})
    const [index, setIndex] = useState(0)
    const params = useParams()
    const [groupAssignmentArray, setGroupAssignmentArray] = useState({})
    const [isShowError, setIsShowError] = useState(false)
    const [errMessage, setErrMessage] = useState(false);
    const [disabled,setDisabled] =useState(false)
    const [dueDate, setDueDate] = useState("")
    const [instructorDate, setInstructorDate] = useState("")
    const [toggler, setToggler] = useState(false)
     
    // const { AllStudent } = useSelector(assignmentSelector);

    const { isSuccess,isFetching ,AllStudent} = useSelector(assignmentSelector);

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const getStudent = (label) => {

       for(let j=0;j< Object.keys(values).length;j++){
        setDisabled(false)
            if (j === index) { console.log("same"); }
            else {
                for(let i=0;i<values[j].length;i++){
                    // console.log("dre", item);
                    if (values[j][i]===label) {
                        console.log(values[j][i],"true",label);
                       return true
                    }
                    else{
                        console.log("dj")
                    }
                   
                }
            }

       }

    }


    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setField(val => ({ ...val, [name]: value }))
    }

    useEffect(() => {
        if (isSuccess) { navigate(-1) }
        // eslint-disable-next-line
    }, [isSuccess])



    const handleGroupAssignmentArray = (index) => (e) => {
        const { value, name } = e.target
        setGroupAssignmentArray((values) => ({
            ...values, [index]: {
                ...values[index],
                [name]: value
            }
        }))
    }
    console.log(values);
    // console.log(groupAssignmentArray);

    const onPublish = (val) => {
        console.log(values)
        console.log(groupAssignmentArray)

        Object.keys(groupAssignmentArray).map((x) =>
            groupAssignmentArray[x].selectedStudents = values[x]
        )
        const entries = Object.values(groupAssignmentArray);
        let newData=entries?.map(res=>res?.selectedStudents?.length).reduce((partialSum, a) => partialSum + a, 0);  
        const obj = {
            name: field.name,
            assignmentSubmissionType: submissionType,
            description: brief,
            totalPoint: field.totalPoint,
            dueDate: dueDate,
            instructorDate: instructorDate,
            isPublished: val ? val : false,
            programId: params?.programId,
            programMasterId: params?.proMasterId,
            courseId: params?.courseId,
            groupAssignmentArray: entries,
            sessionId:toggler?null:params?.sessionId,
            assignmentType:toggler?"course":"session"

        }
        if (field.name !== "" && field.totalPoint !== "" && brief !== "" && submissionType !== "" && groupAssignmentArray!=="" && instructorDate !== "" && dueDate !=="") {
          
          if(AllStudent?.length !== newData){
            setIsShowError(true)
            setErrMessage("Select all Students")
            
          }else{
            dispatch(addCourseGroupAssignment(obj))
          }
        }
        else {
            setIsShowError(true)
            setErrMessage("Please fill all the required feilds")
        }
    }
    const courseDetailsHandleChange = (e) => {
        const { name, value } = e.target
        if (name === "dueDate") setDueDate(value)
        else {
            setInstructorDate(value)
        }

    }


    return (
        <>
    {isFetching && <PageLoader />}
            <AddStudentsPopup view={"createGroupAssignment"}  isOpen={popupToggler} setIsOpen={setPopupToggler} values={values} setValues={setValues} ind={index} onlyShowData={false} getStudent={getStudent} disabled={disabled}/>

            <ErrorToast
                show={isShowError}
                setShow={() => setIsShowError(false)}
                message={errMessage}
            />
            <div className="pageHeader">
                <div className="backBtn" onClick={() => navigate(-1)}>
                    <img src="/assets/icons/back-btn.svg" alt="" />
                    Back to Page
                </div>
                <div className="pageTogglers">
                    <div className="pageToggler active">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5001 18.3334C15.1025 18.3334 18.8334 14.6025 18.8334 10.0001C18.8334 5.39771 15.1025 1.66675 10.5001 1.66675C5.89771 1.66675 2.16675 5.39771 2.16675 10.0001C2.16675 14.6025 5.89771 18.3334 10.5001 18.3334ZM14.9004 7.73369C15.1444 7.48961 15.1444 7.09388 14.9004 6.84981C14.6563 6.60573 14.2606 6.60573 14.0165 6.84981L9.45842 11.4079L7.40036 9.34981C7.15628 9.10573 6.76055 9.10573 6.51647 9.34981C6.2724 9.59388 6.2724 9.98961 6.51647 10.2337L9.01647 12.7337C9.26055 12.9778 9.65628 12.9778 9.90036 12.7337L14.9004 7.73369Z" fill="#1C7C54" />
                        </svg>
                        Add Group Assignment
                    </div>
                </div>
                <div className='pageHeaderBtnGrop'>
                    {/* <PrimaryButton children={'Save'} classname={'darkSecondaryOutlineBtn'} onClick={() => onPublish(false)} /> */}
                    <PrimaryButton children={'Publish'} classname={'successBtn'} onClick={() => onPublish(true)} />
                </div>
            </div>
            <div className="createAssignmentContainerOuter">
                <div className="createAssignmentContainer">
                    <div className="createAssignmentForm">
                    <TextField labelName={'Name'} value={field.name} isImportant={true} name={"name"} placeholder={'Enter your Assignment Name '} onChange={handleTextChange} />
                        <Dropdown onChange={setSubmissionType} label={'Select Submission Types'} isImportant={true} options={options} />
                        {/* <TextField value={assignmentName} onChange={(e) => setAssignmentName(e.target.value)} inputType={'text'} labelName={'Name'} isImportant={true} placeholder={'Enter your Assignment Name '} />
                         */}
                        <TextField inputType="number" labelName={'Total Marks'} name={"totalPoint"} value={field.totalPoint} isImportant={false} placeholder={'Enter Total Marks'} onChange={handleTextChange} />

                        {/* <TextArea value={brief} onChange={(e) => setBrief(e.target.value)} placeholder={'Enter your Brief'} labelName={'Brief'} />
                         */}

      <div className="togglerContainer">
                    <p>Mark as a course Assigment?</p>
                    <div className={`toggler ${toggler ? 'active' : ''}`} onClick={() => { setToggler(!toggler); }}>
                        <div className="togglerBtn"></div>
                    </div>
                </div>
                        <SunEditor
                            onChange={setBrief}
                            setOptions={{
                                buttonList: [
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "list",
                                        "align",
                                        "fontSize",
                                        "formatBlock",
                                        "fullScreen",
                                        "preview",
                                        "image"
                                    ]]
                            }} />
                             <div className="form2Col">
                            <TextField
                                inputType={"date"}
                                labelName={"Assignment Due Date"}
                                isImportant={true}
                                name="dueDate"
                                value={dueDate}
                                onChange={courseDetailsHandleChange}
                                min={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <TextField
                                inputType={"date"}
                                labelName={"Instructor Due Date"}
                                isImportant={true}
                                name="instructorDueDate"
                                value={instructorDate}
                                onChange={courseDetailsHandleChange}
                                min={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            {/* <CustomDatePicker onChange={setDueDate} value={dueDate}/> */}
                            {/* <CustomDatePicker label={'Instructor Due Date'} isImportant={true} onChange={setInstructorDate} value={instructorDate}/> */}
                        </div>
                        {groupCount.map((item, index) => (
                            <>
                                <div key={index} className="form2Col">
                                    {/* <TextField
                                inputType={"date"}
                                labelName={"Assignment Due Date"}
                                isImportant={true}
                                name="dueDate" 
                                value={dueDate}
                                onChange={courseDetailsHandleChange}
                                min={moment(new Date()).format("YYYY-MM-DD")}
                            /> */}
                                    {/* <TextField inputType={'date'} name={"assignmentDueDate"} labelName={'Group Assignment Due Date'} isImportant={false} min={moment(new Date()).format("YYYY-MM-DD")} onChange={(e) => handleGroupAssignmentArray(index)(e)} />
                                    <TextField inputType={'date'} name={"instructorDueDate"} labelName={'Instructor Due Date'} isImportant={true} min={moment(new Date()).format("YYYY-MM-DD")} onChange={(e) => handleGroupAssignmentArray(index)(e)} /> */}

                                </div>
                                {/* <div className="form2Col">
                            <TextField inputType={'number'} labelName={'Release Grades for students'} isImportant={false} placeholder={'Enter Total Number'} />
                            <TextField inputType={'number'} labelName={'Total Number'} isImportant={false} placeholder={'Enter Total Number'} />
                        </div> */}
                                <div className="form2Col flexEnd">
                                    <TextField labelName={'Group Name'} name={"assignmentGroupName"} isImportant={true} placeholder={'Enter Group Name'} onChange={(e) => handleGroupAssignmentArray(index)(e)} />
                                    <PrimaryButtonImg onClick={() => { setPopupToggler(true); setIndex(index) }} classname={'secondaryBtn'} img={'/assets/icons/plusBlue.svg'} children={'Add Student'} />
                                </div>
                            </>
                        ))}
                        {/* <div className="form2Col">
                            <TextField inputType={'number'} labelName={'Group Assignment Due Date'} isImportant={false} placeholder={'Enter Total Number '} />
                            <TextField inputType={'time'} labelName={'Due Date Time'} isImportant={true} placeholder={''} />

                        </div>
                        <div className="form2Col">
                            <TextField inputType={'number'} labelName={'Release Grades for students'} isImportant={false} placeholder={'Enter Total Number'} />
                            <TextField inputType={'number'} labelName={'Total Number'} isImportant={false} placeholder={'Enter  Total Number'} />
                        </div>
                        <div className="form2Col flexEnd">
                            <TextField labelName={'Group Name'} isImportant={true} placeholder={'Enter Group Name'} />
                            <PrimaryButtonImg classname={'secondaryBtn'} img={'/assets/icons/plusBlue.svg'} children={'Add Student'} />
                        </div> */}
                        <div onClick={() => setGroupCount(prev => [...prev, 0])} className="addCourseBtn">
                            <img src="/assets/icons/plus-circle.svg" alt="" />
                            Add Group
                        </div>
                        {/* <div className="formGrp">
                        <label htmlFor="">Submission Attempts<span className="impMark">*</span></label>
                        <div className="radioGrpFlex">
                            <div className="radioBtn">
                                <input type="radio" id="test3" name="radio-group2" />
                                <label for="test3">Limited</label>
                            </div>
                            <div className="radioBtn">
                                <input type="radio" id="test4" name="radio-group2" />
                                <label for="test4">UnLimited</label>
                            </div>
                        </div>
                    </div> */}
                    </div>

                </div>
            </div>
        </>
    )
}

export default CreateGroupAssignment