
import React from 'react'
import './ViewPopup.css'



const ViewPopup = ({ value, onChange,name,description,answer,remarks,setRemarks,handleEnterGpa,data,setLoading }) => {
// const ViewPopup = ({ value, onChange }) => {

 console.log(data,"data by aj");
    function removeTags(str) {
        if ((str === null) || (str === '') || (str === undefined))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in 
        // the input string. Replacing the identified 
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return (<>
        <div className={`popupOuter ${value ? 'active' : ''}`}>
            <div className="popupContainer generalQuestionContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Assignment</p>
                    <img onClick={() => { onChange(false); }} src="/assets/icons/x-close.svg" alt="" />
                </div>
                {/* <div className="resultSection">
                        <p>Your Result: <span>8/10 Points</span></p>
                </div> */}
                <div className="popupBody">
                    <p className="heading">{name}</p>
                    <p className="question">
                  <div dangerouslySetInnerHTML={{__html:removeTags(description)}}/>
                    </p>
                  <div dangerouslySetInnerHTML={{__html:removeTags(answer)}}/>

                  {remarks.show && <div> 
                    <textarea type={"text"} value={remarks.value} onChange={(e)=>setRemarks(prev=>({...prev,value:e.target.value}))}/>
                       <div style={{cursor:"pointer"}} onClick={()=>{data?.assignmentGroupName?handleEnterGpa(data?.id,data?.obtainedMarks):handleEnterGpa(data?.courseId,data?.studentId,data?.obtainedMarks)}}>Submit remarks</div>
                          

                  </div>}
                </div>
            </div>
        </div>
    </>
    )
}

export default ViewPopup