import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PrimaryButton from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
// import PrimaryButtonImg from '../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
// import CustomDatePicker from '../../components/globalComponents/customDatePicker/CustomDatePicker'
import Dropdown from '../../components/globalComponents/dropdown/Dropdown'
// import TextArea from '../../components/globalComponents/textArea/TextArea'
import TextField from '../../components/globalComponents/textFields/TextField'
import PageLoader from '../../components/PageLoader/PageLoader'
import { clearState, createAssignment, assignmentSelector, getSpecificAssignment } from '../../reducers/assignmentSlice'
import SunEditor from "suneditor-react";
import ErrorToast from '../../components/Toast/ErrorToast'


const CreateAssignment = () => {
    const { isSuccess, assignmentDetails, isFetching } = useSelector(assignmentSelector);
    const [submissionType, setSubmissionType] = useState("")
    const params = useParams()
    const [searchParams] = useSearchParams();

    const [brief, setBrief] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [toggler, setToggler] = useState(false)
    const [instructorDate, setInstructorDate] = useState("")


    const [field, setField] = useState({ name: assignmentDetails?.name ?? "", number: assignmentDetails?.totalPoint ?? "" })

    const options = ["Text Entry", "Website URL", "File Uploads"]
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const courseDetailsHandleChange = (e) => {
        const { name, value } = e.target
        if (name === "dueDate") setDueDate(value)
        else {
            setInstructorDate(value)
        }

    }

    useEffect(() => {

        setField(val => ({ ...val, name: assignmentDetails?.name ?? "", number: assignmentDetails?.totalPoint ?? "" }))
        setBrief(assignmentDetails?.description ?? "")
        setDueDate(moment(assignmentDetails?.assignmentDueDate).format("YYYY-MM-DD") ?? "")
        setInstructorDate(moment(assignmentDetails?.instructorDueDate).format("YYYY-MM-DD") ?? "")
        setSubmissionType(assignmentDetails?.assignmentSubmissionType ?? "")


    }, [assignmentDetails])

    // console.log(assignmentDetails);
    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");


    const onPublish = (val) => {
        const obj = {
            name: field.name,
            submissionType: submissionType,
            brief: brief,
            dueDate: dueDate,
            instructorDate: instructorDate,
            number: field.number,
            isPublished: val ? val : false,
            programId: params?.programId,
            programMasterId: params?.proMasterId,
            courseId: params?.courseId,
            isGroupedAssignment: false,
            sessionId:toggler?null:params?.sessionId,
            assignmentType:toggler?"course":"session"
        }
        console.log(obj);
        if (field.name !== "" && field.number !== "" && brief !== "" && dueDate !== "" && instructorDate !== "" && submissionType !== "")
            dispatch(createAssignment(obj))
            
        else {
            setIsShowError(true)
            setErrMessage("Please fill all the required feilds")
        }

    }


    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setField(val => ({ ...val, [name]: value }))
    }
    useEffect(() => {
        setField({ name: "", number: "" })
        setBrief("")
        setDueDate("")
        setInstructorDate("")
        if (searchParams?.get("type") !== "create") {
            dispatch(getSpecificAssignment(searchParams?.get("type")))
        }
        console.log("LL",searchParams)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isSuccess) {
            // const obj = {
            //     courseId:params?.courseId,
            //     sessionId:params?.sessionId
            // }              
            // dispatch(getAssignmentList(obj))
            dispatch(clearState())
            navigate(-1)
        }
        // eslint-disable-next-line
    }, [isSuccess])

    return (
        <>
            {isFetching && <PageLoader />}
            <ErrorToast
                show={isShowError}
                setShow={() => setIsShowError(false)}
                message={errMessage}
            />
            <div className="pageHeader">
                <div className="backBtn" onClick={() => navigate(-1)}>
                    <img src="/assets/icons/back-btn.svg" alt="" />
                    Back to Page
                </div>
                <div className="pageTogglers">
                    <div className="pageToggler active">
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5001 18.3334C15.1025 18.3334 18.8334 14.6025 18.8334 10.0001C18.8334 5.39771 15.1025 1.66675 10.5001 1.66675C5.89771 1.66675 2.16675 5.39771 2.16675 10.0001C2.16675 14.6025 5.89771 18.3334 10.5001 18.3334ZM14.9004 7.73369C15.1444 7.48961 15.1444 7.09388 14.9004 6.84981C14.6563 6.60573 14.2606 6.60573 14.0165 6.84981L9.45842 11.4079L7.40036 9.34981C7.15628 9.10573 6.76055 9.10573 6.51647 9.34981C6.2724 9.59388 6.2724 9.98961 6.51647 10.2337L9.01647 12.7337C9.26055 12.9778 9.65628 12.9778 9.90036 12.7337L14.9004 7.73369Z" fill="#1C7C54" />
                        </svg>
                        Add Assignment
                    </div>
                </div>
                <div className='pageHeaderBtnGrop'>
                    {/* <PrimaryButton children={'Save'} classname={'darkSecondaryOutlineBtn'} onClick={() => onPublish(false)} /> */}
                    <PrimaryButton children={'Publish'} classname={'successBtn'} onClick={() => onPublish(true)} />
                </div>
            </div>
            <div className="createAssignmentContainerOuter">
                <div className="createAssignmentContainer">
                    <div className="createAssignmentForm">
                    <TextField labelName={'Name'} value={field.name} isImportant={true} name={"name"} placeholder={'Enter your Assignment Name '} onChange={handleTextChange} />
                        <Dropdown label={'Select Submission Types'} isImportant={true} onChange={setSubmissionType} options={options} selected={assignmentDetails?.assignmentSubmissionType} />
                        {/* <TextArea placeholder={'Enter your Brief'} labelName={'Brief'} onChange={} value={brief}/> */}
                        <div className="togglerContainer">
                    <p>Mark as a course Assigment?</p>
                    <div className={`toggler ${toggler ? 'active' : ''}`} onClick={() => { setToggler(!toggler); }}>
                        <div className="togglerBtn"></div>
                    </div>
                </div>
                        {brief && <SunEditor
                            defaultValue={brief}
                            onChange={setBrief}
                            setOptions={{
                                buttonList: [
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "list",
                                        "align",
                                        "fontSize",
                                        "formatBlock",
                                        "fullScreen",
                                        "preview",
                                        "image"
                                    ]]
                            }} />}
                        {!brief &&
                            <SunEditor
                                onChange={setBrief}
                                setOptions={{
                                    buttonList: [
                                        [
                                            "bold",
                                            "underline",
                                            "italic",
                                            "strike",
                                            "list",
                                            "align",
                                            "fontSize",
                                            "formatBlock",
                                            "fullScreen",
                                            "preview",
                                            "image"
                                        ]]
                                }} />}
                        <div className="form2Col">
                            <TextField
                                inputType={"date"}
                                labelName={"Assignment Due Date"}
                                isImportant={true}
                                name="dueDate"
                                value={dueDate}
                                onChange={courseDetailsHandleChange}p
                                min={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <TextField
                                inputType={"date"}
                                labelName={"Instructor Due Date"}
                                isImportant={true}
                                name="instructorDueDate"
                                value={instructorDate}
                                onChange={courseDetailsHandleChange}
                                min={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            {/* <CustomDatePicker onChange={setDueDate} value={dueDate}/> */}
                            {/* <CustomDatePicker label={'Instructor Due Date'} isImportant={true} onChange={setInstructorDate} value={instructorDate}/> */}
                        </div>
                        <TextField inputType={"number"} labelName={'Total Marks'} name={"number"} value={field.number} isImportant={false} placeholder={'Enter Total Number '} onChange={handleTextChange} />
                    </div>

                </div>
            </div>
        </>
    )
}

export default CreateAssignment