import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import "./CustomDatePickerStyle.css"
import moment from 'moment'


const CustomDatePicker=({label,classname,isImportant,onChange,minDate,value})=> {
    const [date, setDate] = useState('');
    const [isFilled, setIsFilled] = useState(false);


    useEffect(()=>{
        if(date !== ''){
            setIsFilled(true);
           
            console.log(date)
        } else{
            setIsFilled(false);
        }
        // eslint-disable-next-line
    },[date])

   console.log(value,"ajkjjkjdfjkdfkjd")
   


    return (
      
        <div className={`formGroup gap5 flexColumn ${classname}`}>
            {label && <label className='calender-label'>{label} {isImportant && <span className="impMark">*</span>} </label>}
            <DatePicker className={`${isFilled ? 'isFilled': ''}`} minDate={minDate} onChange={(e)=>{onChange(e); setDate(e)}} value={date} calendarAriaLabel clearIcon calendarIcon/>
        </div>

    );
}
// value={moment(data.announcementDate).format("DD-MM-YYYY")}
 
export default CustomDatePicker