import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton';

const Banktransfer = ({isOpen,setOpen}) => {
    return (
        <>

        <div className={`popupOuter feePopup ${isOpen? 'open-popup' : '' }`}>
            <div className="popupContainer">

                <div className="popupHeader">
                    <p className="popupTitle">Send Due Fee</p>
                    <img src="/assets/icons/x-close.svg" onClick={()=> setOpen(false)} alt="" className='close-pointer'/>
                </div>

                <div className="popupBody">
                    <div className="payment-type-wrapper bank-transfer">

                    <div className="bank-detail">
                        <p className='detail-text detail-align'>
                            <span className="light-text">Account Name:</span>
                            Masters Union School of Business Unit of Shanti Informatics
                        </p>
                    </div>

                    <div className="bank-detail">
                        <p className='detail-text detail-align'>
                            <span className="light-text">Account No:</span>
                            98765432109
                        </p>
                    </div>

                    <div className="bank-detail">
                        <p className='detail-text detail-align'>
                            <span className="light-text">IFSC Code:</span>
                            SBI000000123
                        </p>
                    </div>

                    <div className="bank-detail">
                        <p className='detail-text detail-align'>
                            <span className="light-text">Bank Name:</span>
                            State Bank of India
                        </p>
                    </div>

                    </div>

                    <div className='utr-detail-wrapper'>

                    <div className="utr-detail">
                        <form action="#">
                            <label className="utr-code">Enter the UTR Number Post Transfer</label>
                            <input type="text" placeholder='Enter UTR Number' />
                        </form>
                    </div>

                    </div>
                </div>

                <div className="popupFooter footer-button">
                    <PrimaryButton children={'Submit'} classname={'successBtn'}/>
                </div>
                
            </div>
        </div>

        </>
    )
}

export default Banktransfer