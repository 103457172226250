import React from "react";
import { useState } from "react";
import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
import AddFeedbackStudents from "./AddFeedbackStudents";
import CreateFeedbackForm from "./CreateFeedbackForm";
import FeedbackBuilder from "./FeedbackBuilder";
import "./CreateFeedbackStyle.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import ErrorToast from '../../../components/Toast/ErrorToast';
import { useParams } from "react-router-dom";
import {
  clearState,
  createFeedback,
  feedbackData,
  getFeedbackDetailById,
  editFeedback,
  clearStates
} from "../../../reducers/feedbackSlice";
import { useEffect } from "react";
import moment from "moment/moment";
let feedbackObject = {
  title: "",
  description: "",
  feedbackType: "Class",
  isAnonymous: false,
  expiryDate: "",
  // expiryTime: "",
  questions: [],
  isTotalSelected: false,
  status: "draft",
  isExpired: false,
  previewType: "singlePage",
  layoutType: "card-style",
};
const CreateFeedbackModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, isSuccess, feedbackDetail, feedbackAnswerIsFetching } = useSelector(feedbackData);

  const [renderComponent, setRenderComponent] = useState(1);
  const [feedbackDetails, setFeedbackDetails] = useState({ ...feedbackObject });
  const [questionArray, setNewQuestion] = useState([]);
  const [isTotalSelected, setTotalSelected] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [programName, setProgramName] = useState("");
  const [batchName, setBatchName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [feedId, setFeedId] = useState('');
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const proId = searchParams.get("proId") ?? "";
  const proMasterId = searchParams.get("proMasterId") ?? "";
  const courseId = searchParams.get("courseId") ?? "";
  const type = searchParams.get("type")
  const program = searchParams.get("program") ?? "";
  const batch = searchParams.get("batch") ?? "";
  const course = searchParams.get("course") ?? "";

  //toast states
  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  useEffect(() => {
    if (id === 'create') setFeedbackDetails({ ...feedbackObject })
    else { dispatch(getFeedbackDetailById(id)) };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (feedbackDetail && id !== "create") {
      setFeedId(feedbackDetail?.id);

      feedbackDetail?.FeedbackQuestions.map((question, index) => {
        let feedbackOptions = [];
        question?.FeedbackQuestionOptions.map(item => {
          let obj = {
            isCorrect: item.isCorrect,
            value: item.value
          }
          feedbackOptions.push(obj);
          return null
        })

        setNewQuestion(prev => [...prev, {
          id: index + 1,
          isNotMandatory: question?.isNotMandatory,
          questionName: question?.questionName,
          questionType: question?.questionType,
          time: question?.time,
          weightage: question?.weightage,
          option: [...feedbackOptions]
        }])
        return null
      })
      setFeedbackDetails({
        ...feedbackDetails,
        title: feedbackDetail?.title,
        description: feedbackDetail?.description,
        feedbackType: feedbackDetail?.feedbackType,
        isAnonymous: feedbackDetail?.isAnonymous,
        // expiryTime: feedbackDetail?.expiryTime,
        expiryDate: feedbackDetail?.expiryDate,
        questions: questionArray
      })

      feedbackDetail?.FeedbackStudents?.map((student) => {
        setIsChecked(prev => [...prev, student.Student.id]);
        return null
      })
      setProgramName(feedbackDetail?.FeedbackStudents[0]?.Student?.programId);
      setBatchName(feedbackDetail?.FeedbackStudents[0]?.Student?.programMasterId);
      setCourseName(feedbackDetail?.courseId);
      setSessionName(feedbackDetail?.sessionId);

    }
    if (id === 'create') {
      dispatch(clearStates());
    }
    // eslint-disable-next-line
  }, [feedbackDetail])

  const onClickNext = () => {
    if (validateFeedbackForm()) setRenderComponent(2);
    // if (
    //   feedbackDetails?.title === "" ||
    //   feedbackDetails?.expiryTime === "" ||
    //   feedbackDetails?.expiryDate === ""
    // ) {
    // } else {

    // }

  };

  const validateFeedbackForm = () => {
    if (
      feedbackDetails?.title === "" ||
      // feedbackDetails?.expiryTime === "" ||
      feedbackDetails?.expiryDate === "" ||
      sessionName === ""
    ) {
      setIsShowError(true);
      setErrMessage("Please fill all the required fields");
      return false;
    } else {
      return true;
    }
  };

  const onClickBackButton = () => {
    navigate(-1);
  };


  const onClickSaveAsDraft = () => {
    // if (isChecked.length === 0) {
    //   setIsShowError(true);
    //   setErrMessage("Please add students");
    //   return
    // }
    if (questionArray.length === 0) {
      setIsShowError(true);
      setErrMessage("Please add questions");
      return
    }
    if (validateFeedbackForm()) {
      var params = {
        searchTitle: "",
        programName: programName,
        batchName: batchName,
        courseName: courseName
      };

      let feedbackObj = {
        // isTotalSelected: isTotalSelected,
        query: params,
        sessionId: sessionName,
        courseId: courseName,
        title: feedbackDetails?.title,
        description: feedbackDetails?.description,
        feedbackType: type !== "Quiz" ? feedbackDetails?.feedbackType : type,
        isAnonymous: feedbackDetails?.isAnonymous,
        expiryDate: feedbackDetails?.expiryDate,
        // expiryTime: feedbackDetails?.expiryTime,
        organizationId: null,
        adminId: null,
        userId: null,
        questions: questionArray,
        previewType: "singlePage",
        layoutType: "card-style",
        // studentsId: isChecked,
        feedId: "",
        status: "draft",
        isExpired: false,
        programMasterId:programName,
        programId:batchName,
        // sessionId:sessionName
      };
      console.log(feedbackObj, 'obj')
      if (feedbackDetail) {
        feedbackObj.feedId = feedId;
        dispatch(editFeedback(feedbackObj))
      }
      else dispatch(createFeedback(feedbackObj));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      if(id === 'create') navigate(`/feedbackList?proId=${proId}&proMasterId=${proMasterId}&courseId=${courseId}&type=${type.toLowerCase()}`)
      else navigate(`/feedbackList?proId=${program}&proMasterId=${batch}&courseId=${course}&type=${type.toLowerCase()}`)
    }
    // eslint-disable-next-line

  }, [isSuccess]);

  const onClickAddQuestion = () => {
    if (validateFeedbackForm()) setRenderComponent(2);
  };
  // const onClickAddStudent = () => {
  //   if (validateFeedbackForm()) setRenderComponent(3);
  // };

  useEffect(() => {
      setProgramName(proId);
      setBatchName(proMasterId);
      setCourseName(courseId);
  },[proId,proMasterId,courseId])

  return (
    <>
      {(isFetching || feedbackAnswerIsFetching) && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <div className="pageHeader">
        <div onClick={onClickBackButton} className="backBtn">
          <img src="/assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div className="pageTogglers">
          <div
            className={`pageToggler ${renderComponent === 1 && 'active'}`}
            onClick={() => setRenderComponent(1)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327ZM14.4006 7.73296C14.6447 7.48888 14.6447 7.09315 14.4006 6.84907C14.1565 6.605 13.7608 6.605 13.5167 6.84907L8.95866 11.4071L6.9006 9.34907C6.65652 9.105 6.26079 9.105 6.01672 9.34907C5.77264 9.59315 5.77264 9.98888 6.01672 10.233L8.51672 12.733C8.76079 12.977 9.15652 12.977 9.4006 12.733L14.4006 7.73296Z"
                fill="#404040"
              />
            </svg>
            Create a {type}
          </div>
          <div className={`pageToggler ${renderComponent === 2 && 'active'}`} onClick={() => onClickAddQuestion()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327ZM14.4006 7.73296C14.6447 7.48888 14.6447 7.09315 14.4006 6.84907C14.1565 6.605 13.7608 6.605 13.5167 6.84907L8.95866 11.4071L6.9006 9.34907C6.65652 9.105 6.26079 9.105 6.01672 9.34907C5.77264 9.59315 5.77264 9.98888 6.01672 10.233L8.51672 12.733C8.76079 12.977 9.15652 12.977 9.4006 12.733L14.4006 7.73296Z"
                fill="#404040"
              />
            </svg>
            Add Questions
          </div>
          {/* <div className={`pageToggler ${renderComponent === 3 && 'active'}`} onClick={() => onClickAddStudent()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327ZM14.4006 7.73296C14.6447 7.48888 14.6447 7.09315 14.4006 6.84907C14.1565 6.605 13.7608 6.605 13.5167 6.84907L8.95866 11.4071L6.9006 9.34907C6.65652 9.105 6.26079 9.105 6.01672 9.34907C5.77264 9.59315 5.77264 9.98888 6.01672 10.233L8.51672 12.733C8.76079 12.977 9.15652 12.977 9.4006 12.733L14.4006 7.73296Z"
                fill="#404040"
              />
            </svg>
            Add Students
          </div> */}
        </div>
        <div className="pageHeaderBtnGrop">
          <PrimaryButton
            children={"Save as Draft"}
            classname={"successOutlineBtn"}
            onClick={onClickSaveAsDraft}
          />
        </div>
      </div>
      <div className="createFeedbackMainContainer">
        {renderComponent === 1 ? (
          <CreateFeedbackForm
            onClickNext={onClickNext}
            setFeedbackDetails={setFeedbackDetails}
            feedbackDetails={feedbackDetails}
            setProgramName={setProgramName}
            setBatchName={setBatchName}
            setCourseName={setCourseName}
            setSessionName={setSessionName}
            programName={programName}
            batchName={batchName}
            courseName={courseName}
            sessionName={sessionName}
            feedbackType={feedbackDetails.feedbackType}
          />
        ) : null}
        {renderComponent === 2 ? (
          <FeedbackBuilder
            setFeedbackDetails={setFeedbackDetails}
            feedbackDetails={feedbackDetails}
            questionArray={questionArray}
            setNewQuestion={setNewQuestion}
          />
        ) : null}
        {renderComponent === 3 ? (
          <AddFeedbackStudents
            setTotalSelected={setTotalSelected}
            setIsChecked={setIsChecked}
            isTotalSelected={isTotalSelected}
            isChecked={isChecked}
            setProgramName={setProgramName}
            setBatchName={setBatchName}
            setCourseName={setCourseName}
            programName={programName}
            batchName={batchName}
            courseName={courseName}
            feedbackType={feedbackDetails.feedbackType}
          />
        ) : null}
      </div>
    </>
  );
};

export default CreateFeedbackModule;
