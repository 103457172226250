import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import Dropdown from "../../globalComponents/dropdown/Dropdown";
import TextField from "../../globalComponents/textFields/TextField";
import "./termPlanDetailsPopup.css";
import moment from "moment";
const TermPlanDetailsPopup = (props) => {
  return (
    <div className={`popupOuter ${props.show ? "active":""}`}>
      <div className="termPlanPopup">
        <div className="popupHeader">
          <p className="popupHeading">Add Term Plan</p>
          <img src="/assets/icons/x-close.svg" alt="img" 
          onClick={props.handleClose}
          /> 
        </div>
        <div className="popupBody">
          <div className="formPopup">
            <div className="input">
              <div className="formGroup dFlex gap5 flexColumn">
                <label htmlFor="select">Select Term</label>
                <select id="select" className="formInput" name="term" value={props.value?.term} onChange={props.onChange}>
                  <option>
                    Please Select Term
                  </option>
                  {["1","2","3","4","5","6","7","8"].map(res=><option value={res} key={res}>{res}</option>)}
                </select>
              </div>
            </div>
            {
              props?.proMasterId === "53e08f7a-a03b-42f3-b181-064d1afc05e3" &&
              <div className="input">
                <div className="formGroup dFlex gap5 flexColumn">
                  <label htmlFor="select">Select Minimun Elective Course </label>
                  <select id="select" className="formInput" name="minElectiveCourse" value={props.value?.minElectiveCourse} onChange={props.onChange}>
                    <option>
                    Select Elective Course 
                    </option>
                    {["1","2","3","4","5","6"].map(res=><option value={res} key={res}>{res}</option>)}
                  </select>
                </div>
              </div>
            }
            <div className="input">
              <TextField
                inputType={"date"}
                labelName={"Start Date"}
                isImportant={true}
                name="startDate"
                value={props.value?.startDate}
                onChange={props.onChange}
              />
            </div>
            {
              props.value?.startDate &&
              <div className="input">
                <TextField
                  inputType={"date"}
                  labelName={"End Date"}
                  isImportant={true}
                  name="endDate"
                  value={props.value?.endDate}
                  onChange={props.onChange}
                  min={moment(props.value?.startDate).add(1, 'day').format("YYYY-MM-DD")}
                />
              </div>
            }
          </div>
          <div className="popupBtnGrp">
            <PrimaryButton
              classname={"darkBtn"}
              children={"Cancel"}
              onClick={props.handleClose}
            />
            <PrimaryButton
              classname={'successBtn'}
              children={"Submit"}
              onClick={props.submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermPlanDetailsPopup;
