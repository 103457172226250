import React, { useState,useEffect } from 'react'
import BreadCrumbs from '../../components/globalComponents/breadCrumbs/BreadCrumbs'
import PrimaryButton from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getAllSessionByCourseId,courseSliceData } from '../../reducers/courseSlice'
import PageLoader from '../../components/PageLoader/PageLoader'
import moment from 'moment'
const AllSessions = () => {
   
    const [openFilters, setOpenFilters] = useState(false)
    const { getSessionByCourse,isFetching } = useSelector(courseSliceData);
    const params=useParams()
  const [searchParams] = useSearchParams();
  const assignment = searchParams.get("assignment") ?? "";

const dispatch = useDispatch()

console.log(getSessionByCourse);
const navigate =useNavigate()

useEffect(() => {
    let obj = {
      proId: params?.programId,
      courseId: params?.courseId,
    };
    
    dispatch(getAllSessionByCourseId(obj));
    // eslint-disable-next-line
  }, []);
// const handleView =(id,programId,masterId)=>{
//   if(params?.name!=="grade") navigate(`/assignment/${programId}/${masterId}/${id}?assignment=${assignment==="assignment"?"assignment":"group"}`,{exact:true}) 
//    else{
//     navigate(`/gradesList/${id}/${programId}/${masterId}`,{exact:true})
//    }

// }
 const handleView =(id)=>{
    if(params?.name !== "grade") navigate(`/assignment/${params?.programId}/${params?.proMasterId}/${params?.courseId}/${id}?assignment=${assignment==="assignment"?"assignment":"group"}`)
    else navigate(`/gradesList/${params?.courseId}/${params?.programId}/${params?.proMasterId}/${id}`)
 }


// const layer = [`${params?.name!=="grade"?assignment==="assignment"?"Assignment":"Group Assignment":"Grade"}`,`${getAllCourseData[0]?.Program?.programName ?? ""}`]
const layer =[]
    return (
        <>
        {isFetching && <PageLoader />}
            <div className="pageHeader">
                <div>
                    <BreadCrumbs layers={layer} />
                </div>
                <div></div>
                <div></div>
            </div>
            {/* <div className="filterBar">
                <div className="filterLeft">
                    <div className="searchBox">
                        <img src="/assets/icons/search-sm.svg" alt="" />
                        <input type="text" className="searchInput" placeholder='Search ...' />
                    </div>

                </div>
                <div className='filterBarRight'>

                    <button className="fiterBtn" onClick={() => setOpenFilters(!openFilters)}> <img src="/assets/icons/filter-lines.svg" alt="" /> Filters</button>
                </div>
            </div> */}
            <div className="assigmentListOuter">

                <div className="assignmentListTableOuter">
                    <table className="assignmentListTable" cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>

                                <th>Session Name</th>
                                <th>Date</th>
                                <th>From Time</th>
                                <th>To Time</th>
                                <th>View Assignments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                       getSessionByCourse && getSessionByCourse.length>0 &&  getSessionByCourse.map((item,index)=>( <tr key={index}>
                                <td onClick={()=>handleView(item.id)}>{item.name}</td>
                                <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                                <td>{item.fromTime}</td>
                                <td>{item.toTime}</td>
                                <td><PrimaryButton classname={'secondaryBtnSmall'} children={`${params?.name!=="grade"?"View":"View Students"}`} onClick={()=>handleView(item.id)}/></td>
                            </tr> )) }
                        </tbody>
                    </table>
                </div>

            </div>
            <div className={`filterMenuOuter ${openFilters ? 'active' : ''}`}>
                <div className="filterMenuContainer">
                    <div className="filterMenuHeader">
                        <p className="heading">Filter by</p>
                        <img src="/assets/icons/x-close.svg" alt="" className='pointer' onClick={() => setOpenFilters(false)} />
                    </div>
                    <div className="filterBox">
                        <p className="heading">Program</p>
                        <div className="radioGrpFlex">
                            <div className="radioBtn">
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">MasterCamps</label>
                            </div>
                            <div className="radioBtn">
                                <input type="radio" id="test4" name="radio-group" />
                                <label for="test4">PGP TBM</label>
                            </div>
                        </div>
                    </div>
                    <div className="filterMenuFooter">
                        <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Reset'} />
                        <PrimaryButton classname={'primaryBtn'} children={'Show Result'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllSessions