import React from "react";
import "./SideMenuStyle.css";
// import $ from "jquery";
import { NavLink } from "react-router-dom";

const SideMenu = ({ isOpened, setIsOpened, setLogout }) => {
  return (
    <div className={`sideMenuOuter active ${isOpened ? "active" : ""}`}>
      <div className="sideMenu">
        <ul className="sideMenuList">
          {/* <NavLink onClick={() => setIsOpened(false)} to="/dashboard">
                    <li className="sideMenuListItem">
                    <img src="/assets/icons/home-05.svg" alt="" />
                    Dashboard
                    </li>
                    </NavLink> */}
          {/* <li className="sideMenuListItem">
                        <img src="/assets/icons/bell-02.svg" alt="" />
                        Notification
                    </li> */}
          <NavLink  to="/announcement">
            <li className="sideMenuListItem">
              <img src="/assets/icons/calendar.svg" alt="" />
              Announcement
            </li>
          </NavLink>
          {/* <NavLink onClick={() => setIsOpened(false)} to="/allCourseAttendance">
            <li className="sideMenuListItem">
              <img src="/assets/icons/user-check-01.svg" alt="" />
              Attendance
            </li>
          </NavLink> */}
          {/* <li className="sideMenuListItem">
                        <img src="/assets/icons/user-minus-02.svg" alt="" />
                        Leave Request
                    </li> */}
          <NavLink  to="/">
            <li className="sideMenuListItem border-top">
              <img src="/assets/icons/book-open-02.svg" alt="" />
              Programs
            </li>
          </NavLink>
          {/* <li className="sideMenuListItem">
                        <img src="/assets/icons/briefcase-01.svg" alt="" />
                        Career Services
                    </li> */}
          {/* <li className="sideMenuListItem border-top">
                        <img src="/assets/icons/menu-01.svg" alt="" />
                        Club
                    </li> */}
          {/* <NavLink
            onClick={() => setIsOpened(false)}
            to="programAssignment/assignment"
          >
            <li className="sideMenuListItem border-top">
              <img src="/assets/icons/menu-01.svg" alt="" />
              Assignment
            </li>
          </NavLink>
          <NavLink
            onClick={() => setIsOpened(false)}
            to="programAssignment/grade"
          >
            <li className="sideMenuListItem border-top">
              <img src="/assets/icons/menu-01.svg" alt="" />
              Grade
            </li>
          </NavLink> */}
          {/* <li className="sideMenuListItem">
                        <img src="/assets/icons/calendar-heart-01.svg" alt="" />
                        Events
                    </li> */}
          {/* <li className="sideMenuListItem">
                        <img src="/assets/icons/users-01.svg" alt="" />
                        Meeting
                    </li> */}
          {/* <NavLink onClick={() => setIsOpened(false)} to="/feedbackList">
            <li className="sideMenuListItem">
              <img src="/assets/icons/message-question-square.svg" alt="" />
              Feedback
            </li>
          </NavLink> */}
          <NavLink
            to={
              localStorage.getItem("adminId") ===
                "3b19fa50-b1ff-4216-93bf-c786f8d8cd95"
                ? "/help"
                : "/adminHelpDesk"
            }
          >
            <li className="sideMenuListItem">
              <img src="/assets/icons/help-octagon.svg" alt="" />
              Help Desk
            </li>
          </NavLink>
          <li
            onClick={() => {
              setLogout(true);
            }}
            className="sideMenuListItem border-top"
          >
            <img src="/assets/icons/log-out.svg" alt="logout" />
            Log Out
          </li>
        </ul>
      </div>
    </div>
  );
};
// {setIsOpened(false);localStorage.clear();window.location.reload()}
export default SideMenu;
