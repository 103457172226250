import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environment/environment";

// let orgId;
let token;
// let adminId;

let setTokenValues = () => {
  // orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  // adminId = localStorage.getItem("adminId");
};

/** GET EMAIL TEMPLATE */
export const getEmailTemplate = createAsyncThunk(
  "common/getEmailTemplate",
  async (obj, thunkAPI) => {
    console.log("printing template data", obj);
    try {
      setTokenValues();

      var templateName = obj.templateName;
      const response = await fetch(
        environment.baseURL +
          "/api/org/" +
          templateName +
          "/getSpecificTemplateDetails",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    emailTemplateData: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [getEmailTemplate.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      //   state.isFetching = false;
      //   state.isSuccess = true;
      state.emailTemplateData = payload;
      // state.studentList = payload;
      return state;
    },
    [getEmailTemplate.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      //   state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getEmailTemplate.pending]: (state) => {
      console.log("inside pending state emailTEmplate", state);
      //   state.isFetching = true;
    },
  },
});

export const { clearState } = commonSlice.actions;

export const commonData = (state) => state.commonData;
