import React, { useState, useEffect } from "react";
import "./CreateFeedbackStyle.css";
import SingleSelectCheckbox from "../../../components/globalComponents/singleSelectCheckbox/SingleSelectCheckbox";
import Dropdown from "../../../components/globalComponents/dropdown/Dropdown";
import TextArea from "../../../components/globalComponents/textArea/TextArea";
import TextField from "../../../components/globalComponents/textFields/TextField";
import { useSearchParams } from "react-router-dom";
// import { feedbackData } from "../../../reducers/feedbackSlice";
// import { useSelector } from "react-redux";
const EditQuestion = ({
  isEditQuestion,
  questionType,
  optionsList,
  setQuestionType,
  closeEditQuestion,
  setNewQuestion,
  questionArray,
  setQuestionDetail,
  questionDetail,
  feedbackType,
  editCheck
}) => {
  const inputArr = [
    {
      value: "",
      isCorrect: false,
    },
  ];
  var select = false;

  const [questionId, setQuestionId] = useState("");
  const [isQuestionMandatory, setChecked] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionTime, setQuestionTime] = useState("");
  const [questionWeightage, setWeightage] = useState("");

  const [optionArray, setOptionArray] = useState(inputArr);
  const [validation, setValidation] = useState('');
  const [ searchParams ] = useSearchParams();
  const type = searchParams.get("type");

  // const { feedbackDetail } = useSelector(feedbackData);

  const onChangeQuestionTitle = (event) => {
    if (event.target.value === "") setValidation('')
    setQuestionTitle(event.target.value);
  };
  const addQuestionDetail = () => {
    if (questionTitle === '') { setValidation('title'); return null }
    if (type === 'Quiz' && (questionWeightage === '' || questionTime === '')) { setValidation('weightage'); return null }
    if ((questionType === "Multi Select" || questionType === "Single Select") && validateEmptyOptionValue()) { setValidation(validateEmptyOptionValue()); return null }
    if (type === "Quiz" && optionArray.length <= 1) {setValidation("addOptions"); return null}
    let item;
    if (questionId) {
      const i = questionArray.findIndex((_item) => _item.id === questionId);
      if (i !== -1) {
        item = {
          id: questionId,
          questionType: questionType,
          questionName: questionTitle,
          isNotMandatory: isQuestionMandatory,
          option: optionArray,
          weightage: questionWeightage,
          time: questionTime,
        };
        questionArray[i] = item;
      } else {
        item = {
          id: questionArray.length + 1,
          questionType: questionType,
          questionName: questionTitle,
          isNotMandatory: isQuestionMandatory,
          option: optionArray,
          weightage: questionWeightage,
          time: questionTime,
        };
        setNewQuestion([...questionArray, item]);
      }
    } else {
       item = {
        id: questionArray.length + 1,
        questionType: questionType,
        questionName: questionTitle,
        isNotMandatory: isQuestionMandatory,
        option: optionArray,
        weightage: questionWeightage,
        time: questionTime,
      };
      setNewQuestion([...questionArray, item]);
    }
    clearQuestionForm();

  };

  const clearQuestionForm = () => {
    setQuestionType("");
    closeEditQuestion();
    setQuestionTitle("");
    setOptionArray(inputArr);
    setQuestionDetail([]);
    setQuestionId("");
    setChecked(false);
    setValidation('');
    setWeightage('');
    setQuestionTime('');
  };

  useEffect(() => {
    if (questionDetail.length > 0) {
      setQuestionTitle(questionDetail[0].questionName);
      setOptionArray(questionDetail[0].option);
      setQuestionId(questionDetail[0].id);
      setChecked(questionDetail[0].isNotMandatory);
      setQuestionTime(questionDetail[0].time);
      setWeightage(questionDetail[0].weightage);
    }
  }, [questionDetail]);

  // const handleChange = (e) => {
  //   const { checked } = e.target;
  //   setChecked(checked);
  // };

  if (questionType === "Single Select" || questionType === "Multi Select") {
    select = true;
  }

  const removeOptionItem = (id) => {
    var filteredOption = optionArray.filter(function (item, index) {
      return index !== id;
    });

    setOptionArray(filteredOption);
  };

  const validateEmptyOptionValue = () => {
    let check = false;
    let correct = true;
    optionArray.forEach((option) => {
      if (option.value === '') check = true;
      if (option.isCorrect === true) {
        correct = false
        return;
      }
    });
    if(check) return "empty";
    else if(type === "Quiz" && correct) return "noCorrect"
    else return false;
    // if (check || (type === "Quiz" && correct)) return true
  }

  const addOption = () => {
    // if (validateEmptyOptionValue()) setOptionArrayValidation(false);
    setOptionArray([
      ...optionArray,
      {
        value: "",
        isCorrect: false,
      },
    ]);
  };

  const handleInputChange = (e) => {
    if (e.target.value !== '') setValidation(false);
    const index = e.target.id;


    setOptionArray((s) => {
      const newArr = s.slice();

      // newArr[index].value = e.target.value;
      newArr[index] = { ...newArr[index], value: e.target.value };

      return newArr;
    });
  };

  const handleInputCheckedChange = (e) => {
    const index = e.target.id;
    
    setOptionArray((s) => {
      let newArr = s.slice();
      const checked = newArr[index].isCorrect;

      newArr.map(item => item.isCorrect = false);

      // newArr[index].value = e.target.value;
      newArr[index] = {
        ...newArr[index],
        isCorrect: !checked,
      };
      
      return newArr;
    });

    // const value = e.target.value;


    // const modifiedData = [...optionArray];
    // modifiedData.map((item) => {
    //   item.isCorrect = index === value;
    //   return item;
    // });


    // setOptionArray(modifiedData);
  };


  // const handleWeightageChange = (e) => {
  //   const index = e.target.id;


  //   setOptionArray((s) => {
  //     const newArr = s.slice();

  //     // newArr[index].value = e.target.value;
  //     newArr[index] = {
  //       ...newArr[index],
  //       isCorrect: index === e.target.value,
  //     };

  //     return newArr;
  //   });

  //   // const value = e.target.value;


  //   // const modifiedData = [...optionArray];
  //   // modifiedData.map((item) => {
  //   //   item.isCorrect = index === value;
  //   //   return item;
  //   // });


  //   // setOptionArray(modifiedData);
  // };

  return (
    <div className={`questionDetailsOuter ${isEditQuestion ? "active" : ""}`}>
      <div onClick={clearQuestionForm} className="questionHeader">
        <img
          
          src="/assets/icons/back-btn.svg"
          alt=""
        />
        Question Details
      </div>
      <div style={type === 'Quiz' ? { display: 'none' } : null} className="questionSelector">
        <Dropdown
          selected={questionType}
          options={optionsList}
          disabled={editCheck}
          label={"Question Type"}
          onChange={(option) => {
            setQuestionType(option);
          }}
        />
      </div>
      <div className="questionSelector">
        <TextArea
          labelName={"Question"}
          placeholder={"Enter your Question"}
          onChange={onChangeQuestionTitle}
          value={questionTitle}
          isImportant={true}
          error={validation === 'title' ? true : false}
        />

        {type === "Feedback" && <SingleSelectCheckbox
          checked={isQuestionMandatory}
          onChange={() => setChecked(prev => !prev)}
          label={"Is this question not mandatory?"}
        />}
      </div>

      {type === "Quiz" && (
        <div className="questionSelector">
          <TextField
            labelName={"Weightage"}
            isImportant={true}
            inputType="number"
            value={questionWeightage}
            placeholder={"Enter weightage"}
            min={0}
            onChange={(e) => { setWeightage(e.target.value); setValidation('') }}
          />
         
          <TextField
            labelName={"Duration In Seconds"} 
            isImportant={true}
            inputType="number"
            placeholder={"Enter duration"}
            value={questionTime}
            min={0}
            onChange={(e) => { setQuestionTime(e.target.value); setValidation('') }}
          />
          <label style={validation === 'weightage' ? { display: 'block', color: '#de0000', fontSize: '12px' } : { display: 'none' }}>Please fill these fields</label>
        </div>
      )}

      {select && (
        <div className="questionSelector optionSelectorSetting">
          {optionArray.map((item, index) => {
            return (
              <div className="checkMarkOption">
                <div className="optionBox">
                  <p className="optionNumber">Option {index + 1}</p>
                  <input
                    placeholder="Type here...."
                    type="text"
                    id={index}
                    value={item.value}
                    onChange={handleInputChange}
                  />
                  {optionArray.length > 1 && (
                    <img
                      src="/assets/icons/x-close-danger.svg"
                      alt=""
                      className="pointer"
                      onClick={() => removeOptionItem(index)}
                    />
                  )}
                </div>
                {type === "Quiz" && <label className="mt-5">
                  <input
                    value={index}
                    id={index}
                    onChange={handleInputCheckedChange}
                    checked={item.isCorrect}
                    type="checkbox"
                  />
                  <span class="checkmarkNew"></span>
                  Is Correct
                </label>}
              </div>
            );
          })}
          {validation === "empty" && <label className="FeedbackOptionError">Please fill options</label>}
          {validation === "noCorrect" &&<label className="FeedbackOptionError">Please select one correct value</label>}
          {validation === "addOptions" && <label className="FeedbackOptionError">Please add atleast 2 options</label>}

          <div onClick={addOption} className="addCourseBtn">
            <img src="../assets/icons/plus-circle.svg" alt="" />
            Add Option
          </div>
        </div>
      )}

      <div className="questionSelector fixed-bottom-button">
        <div onClick={addQuestionDetail} className="addCourseBtn">
          Save
        </div>
      </div>
    </div>
  );
};

export default EditQuestion;
