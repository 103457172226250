import React, { useState } from "react";
import { useEffect } from "react";
import "./DropdownStyle.css";

const Dropdown = ({
  label,
  options,
  isImportant,
  onChange,
  id,
  selected,
  disabled,
}) => {
  const dropdownType = "dropdownListItem";
  const [active, setActive] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const isError = false;
  const [selectedOption, setSelectedOption] = useState("Select your response");

  useEffect(() => {
    if (selected) {
      setSelectedOption(selected);
    }
    else{
      setSelectedOption("Select your response");
    }
  }, [selected]);

  useEffect(() => {
    setIsDisabled(disabled);
  },[disabled])

  let option = ["Option 1", "Option 2", "Option 3"];

  // const optionsList = options
  const optionsList = options || option;

  return (
    <div
      className={`dropdownBox ${active ? "active" : ""} ${
        isError ? "error" : ""
      } ${isDisabled ? "disabled" : ""}`}
      onBlur={() => setActive(false)}
      onClick={isDisabled ? null : () => setActive(!active)}
    >
      {label && (
        <label htmlFor="dropdown" className="formLabel">
          {label} {isImportant ? <span className="impMark">*</span> : null}
        </label>
      )}
      <div className="selectedOption" id="dropdown">
        {selectedOption}{" "}
        <img src="/assets/icons/chevron-down-black.svg" alt="" />{" "}
        <ul className="dropdownList">
          {optionsList?.map((option, index) => {
            return dropdownType === "checkbox" ? (
              <li className="dropdownListItem" key={index}>
                <div className="checkboxGrp">
                  <input type="checkbox" id={option} />
                  <label htmlFor={option}>{option}</label>
                </div>
              </li>
            ) : (
              <>
              {/* For Help Desk */}
                {id ? (
                  <li
                    className="dropdownListItem"
                    key={index}
                    onClick={() => {
                      onChange({ id: id[0], status: option, stuId: id[1] });
                    }}
                  >
                    {option}
                  </li>
                ) : (
                  <li
                    className="dropdownListItem"
                    key={index}
                    onClick={() => {
                      setSelectedOption(option);
                      onChange(option);
                    }}
                    isDisabled={disabled}
                  >
                    {option}
                  </li>
                )}
              </>
            );
          })}
        </ul>
      </div>
      {isError ? <p className="errorMsg">Error message</p> : ""}
    </div>
  );
};

export default Dropdown;
