import React from 'react'
import './LeaveDetailsStyle.css'
// import BreadCrumbs from '../../../components/globalComponents/breadCrumbs/BreadCrumbs'
import FileViewer from '../../../components/globalComponents/fileViewer/FileViewer';
import PrimaryButtonImg from '../../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg';

const LeaveDetails = () => {
    // const layers = [
    //     "Leave Record",
    //     "5301",
    // ];
    return (
        <>
            <div className="pageHeader">
                <div className="backBtn">
                    <img src="/assets/icons/back-btn.svg" alt="" />
                    Back to Page
                </div>
                <div></div>
                <div className='pageHeaderBtnGrop'>
                    <PrimaryButtonImg children={'Reject'} classname={'dangerOutlineBtn'} img={'/assets/icons/x-circle-danger.svg'} />
                    <PrimaryButtonImg children={'Approve'} classname={'successBtn'} img={'/assets/icons/check-circleWhite.svg'} />
                </div>
            </div>
            <div className="leaveDetailsContainerOuter">
                <div className="leaveDetailsContainer">
                    <div className="leaveDetailsHeader">
                        <p className="heading">Request Id: MUSB/2021/4789</p>
                        <div className="dangerTag">Rejected</div>
                    </div>
                    <div className="leaveDetailsTable">
                        <div className="leaveDetailsTableElement">
                            <p className="heading">Leave Type</p>
                            <p className="content">Multiple Leave </p>
                        </div>
                        <div className="leaveDetailsTableElement">
                            <p className="heading">Leave From</p>
                            <p className="content">23/6/22 </p>
                        </div>
                        <div className="leaveDetailsTableElement">
                            <p className="heading">Leave To</p>
                            <p className="content">23/6/22 </p>
                        </div>
                    </div>
                    <p className="descriptionHeading">Description</p>
                    <p className="description">
                        Need to discuss about the Start date of the Course and procedures to apply for the Loan.
                        My gmail account has been changed and I want to update it in my account.
                    </p>
                    <p className="supportingDocHeading">Supporting Documents</p>
                    <div className="supportingDocumentContainer">
                        <FileViewer />
                    </div>
                    <div className="missingTableContainer">
                        <table className="missingLectureTable" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Subject List</th>
                                    <th>Missing Lectures</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Introduction to Programming & Logic Building Section A</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="leaveDetailsContainer">
                    <p className="rejectionHeading">Reason for rejecting the leave request</p>
                    <p className="rejectionReason">Dear Student, Since this is not a medical leave. We will not approve the same but will keep it in the record.</p>
                </div>
            </div>
        </>
    )
}

export default LeaveDetails