import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import "./LogoutPopupStyle.css";
import { useNavigate } from "react-router-dom";
const LogoutPopup = ({
  title,
  boldText,
  btnType,
  isOpen,
  onClose,
}) => {

  const navigate = useNavigate();
  const confirmLogout=()=>{
    onClose()
    localStorage.clear();
    navigate(`/login`, {
      exact: true,
    });
  }
  return (
    <div className={`popupOuter ${isOpen ? "active" : ""}`}>
      <div className="confirmationPopupContainer">
        <div className="popupHeader">
          <p className="popupTitle">{title}</p>
        </div>
        <div className="popupBody">
          <p className="popupText">
            <b>{boldText}</b>
          </p>
          <div className="popupBtnGrp-logout">
            <PrimaryButton
              onClick={onClose}
              classname={"dangerBtn"}
              children={"No"}
            />
            <PrimaryButton
              onClick={confirmLogout}
              classname={btnType}
              children={"Yes"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
