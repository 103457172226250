import React, { useEffect, useState } from 'react'
import './CourseDetailStyle.css'
// import Dropdown from '../../components/globalComponents/dropdown/Dropdown'
// import ProgressBar from '../../components/globalComponents/progressBar/ProgressBar'
import FileViewer from '../../components/globalComponents/fileViewer/FileViewer'
// import Button from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import QuizPopup from '../../components/popups/quizPopup/QuizPopup'
import BreadCrumbs from '../../components/globalComponents/breadCrumbs/BreadCrumbs'
// import PrimaryButtonImg from '../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAllSessionByCourseId,
  courseSliceData,
  getCourseByIdAdmin,
} from "../../reducers/courseSlice";
import moment from "moment";
import PageLoader from "../../components/PageLoader/PageLoader";
import SubMenu from '../../components/layout/subMenu/SubMenu'
const CourseDetail = () => {
  const dispatch = useDispatch();
  const { isFetching, getSessionByCourse, getSingleCourseDetail } =
    useSelector(courseSliceData);
    
    const [toggleCourse, setToggleCourse] = useState(0);
    const [changeContainer, setChangeContainer] = useState(true);
    const [searchParams] = useSearchParams();
    const proId = searchParams.get("proId") ?? "";
    const courseId = searchParams.get("courseId") ?? "";
    const proMasterId = searchParams.get("proMasterId") ?? "";
    const navigate = useNavigate();
    
    const layer = [getSingleCourseDetail?.ProgramDetail?.Program?.programName, getSingleCourseDetail?.courseName, `${changeContainer ? "Course Details" : "Session Details"}`];

  useEffect(() => {
    let obj = {
      proId: proId,
      courseId: courseId,
    };
    dispatch(getAllSessionByCourseId(obj));
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let obj = {
      proId: proId,
      courseId: courseId,
    };
    dispatch(getCourseByIdAdmin(obj));
    // eslint-disable-next-line
  }, []);

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const handleBreadCrumbsClick = (index) => {
    if(index === 0) navigate("/");
    if(index === 1) navigate(`/allCourse?proId=${proId}&proMasterId=${proMasterId}`)
  }

  return (
    <>
      {isFetching && <PageLoader />}
      <div className="pageHeader">
        <BreadCrumbs layers={layer} onClick={handleBreadCrumbsClick} type={"link"}/>
        <div className="pageTogglers">
          <div
            className={`pageToggler ${changeContainer ? "active" : ""}`}
            onClick={() => setChangeContainer(true)}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 19C6.83334 19 6.75 19 6.66667 19C5.75 18.9167 5 18.3333 4.66667 17.4167C4.66667 17.3333 4.58334 17.25 4.58334 17.0833L3.25 11.8333C3.25 11.8333 3.25 11.8333 3.25 11.75C2.25 10.9167 1.58334 9.66666 1.58334 8.33333C1.58334 5.91666 3.58334 3.99999 5.91667 3.99999H9.08334C10.1667 3.99999 11.6667 3.49999 13.4167 2.49999C14.3333 1.99999 14.8333 1.74999 15.25 1.83333C15.6667 1.91666 16 2.08333 16.25 2.41666C16.5 2.83333 16.5 3.24999 16.5 4.33333V4.91666C18.1667 5.24999 19.4167 6.66666 19.4167 8.41666C19.4167 10.1667 18.1667 11.5833 16.5 11.9167V12.5C16.5 13.5 16.5 14 16.25 14.4167C16 14.75 15.6667 15 15.25 15C14.75 15.0833 14.3333 14.8333 13.4167 14.3333C11.8333 13.5 10.5 13 9.5 12.9167V16.6667C9.5 16.9167 9.5 17 9.5 17.0833C9.41667 18.1667 8.5 19 7.41667 19.1667C7.33334 18.9167 7.16667 19 7 19ZM7 17.6667C7.16667 17.6667 7.25 17.6667 7.33334 17.6667C7.83334 17.5833 8.25 17.25 8.25 16.75C8.25 16.6667 8.25 16.5833 8.25 16.4167V12.6667H5.91667C5.5 12.6667 5.08334 12.5833 4.66667 12.5L5.75 16.75C5.75 16.9167 5.83334 16.9167 5.83334 17C6 17.4167 6.33334 17.6667 6.75 17.6667C6.75 17.75 6.83334 17.6667 7 17.6667ZM9.5 11.5C10.75 11.5833 12.25 12.1667 14.0833 13.0833C14.5833 13.3333 15.0833 13.5833 15.25 13.6667C15.25 13.6667 15.3333 13.6667 15.3333 13.5833C15.3333 13.5 15.3333 13 15.3333 12.4167V4.24999C15.3333 3.66666 15.3333 3.16666 15.25 3.08333C15.25 3.08333 15.1667 2.99999 15.0833 2.99999C15 2.99999 14.5 3.24999 14 3.58333C12.25 4.58333 10.6667 5.08333 9.41667 5.16666V11.5H9.5ZM5.91667 5.16666C4.16667 5.16666 2.83334 6.58333 2.83334 8.33333C2.83334 10.0833 4.25 11.5 6 11.5H8.33334V5.16666H5.91667ZM16.5 6.16666V10.5833C17.5 10.3333 18.1667 9.41666 18.1667 8.41666C18.1667 7.41666 17.5 6.41666 16.5 6.16666Z"
                fill="#262626"
              />
            </svg>
            Course Details
          </div>
          <div
            className={`pageToggler ${!changeContainer ? "active" : ""}`}
            onClick={() => setChangeContainer(false)}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 19C6.83334 19 6.75 19 6.66667 19C5.75 18.9167 5 18.3333 4.66667 17.4167C4.66667 17.3333 4.58334 17.25 4.58334 17.0833L3.25 11.8333C3.25 11.8333 3.25 11.8333 3.25 11.75C2.25 10.9167 1.58334 9.66666 1.58334 8.33333C1.58334 5.91666 3.58334 3.99999 5.91667 3.99999H9.08334C10.1667 3.99999 11.6667 3.49999 13.4167 2.49999C14.3333 1.99999 14.8333 1.74999 15.25 1.83333C15.6667 1.91666 16 2.08333 16.25 2.41666C16.5 2.83333 16.5 3.24999 16.5 4.33333V4.91666C18.1667 5.24999 19.4167 6.66666 19.4167 8.41666C19.4167 10.1667 18.1667 11.5833 16.5 11.9167V12.5C16.5 13.5 16.5 14 16.25 14.4167C16 14.75 15.6667 15 15.25 15C14.75 15.0833 14.3333 14.8333 13.4167 14.3333C11.8333 13.5 10.5 13 9.5 12.9167V16.6667C9.5 16.9167 9.5 17 9.5 17.0833C9.41667 18.1667 8.5 19 7.41667 19.1667C7.33334 18.9167 7.16667 19 7 19ZM7 17.6667C7.16667 17.6667 7.25 17.6667 7.33334 17.6667C7.83334 17.5833 8.25 17.25 8.25 16.75C8.25 16.6667 8.25 16.5833 8.25 16.4167V12.6667H5.91667C5.5 12.6667 5.08334 12.5833 4.66667 12.5L5.75 16.75C5.75 16.9167 5.83334 16.9167 5.83334 17C6 17.4167 6.33334 17.6667 6.75 17.6667C6.75 17.75 6.83334 17.6667 7 17.6667ZM9.5 11.5C10.75 11.5833 12.25 12.1667 14.0833 13.0833C14.5833 13.3333 15.0833 13.5833 15.25 13.6667C15.25 13.6667 15.3333 13.6667 15.3333 13.5833C15.3333 13.5 15.3333 13 15.3333 12.4167V4.24999C15.3333 3.66666 15.3333 3.16666 15.25 3.08333C15.25 3.08333 15.1667 2.99999 15.0833 2.99999C15 2.99999 14.5 3.24999 14 3.58333C12.25 4.58333 10.6667 5.08333 9.41667 5.16666V11.5H9.5ZM5.91667 5.16666C4.16667 5.16666 2.83334 6.58333 2.83334 8.33333C2.83334 10.0833 4.25 11.5 6 11.5H8.33334V5.16666H5.91667ZM16.5 6.16666V10.5833C17.5 10.3333 18.1667 9.41666 18.1667 8.41666C18.1667 7.41666 17.5 6.41666 16.5 6.16666Z"
                fill="#262626"
              />
            </svg>
            Session Details
          </div>
        </div>
        <div />
        {/* <div className='btnGrp'>
                    <PrimaryButtonImg classname={'darkSecondaryOutlineBtn'} children={'Add Session'} img={'/assets/icons/edit-01.svg'} />
                    <PrimaryButtonImg classname={'successBtn'} children={'Publish'} img={'/assets/icons/check-square.svg'} />
                </div> */}
      </div>
      <div className="routeWithSubMenu">
        <SubMenu proId={proId} courseId={courseId} proMasterId={proMasterId} />
        {changeContainer ? (
          <div className="courseDetailsContainerOuter">
            <div className="courseDetailsContainer">
              <div className="courseDetailsBoxOuter">
                <div className="courseDetailsBox">
                  <p className="heading">Term</p>
                  <p className="content"> {getSingleCourseDetail && getSingleCourseDetail?.term}</p>
                </div> <div className="courseDetailsBox">
                  <p className="heading">Course Name</p>
                  <p className="content"> {getSingleCourseDetail && getSingleCourseDetail?.courseName}</p>
                </div>
                <div className="courseDetailsBox">
                  <p className="heading">Course Code</p>
                  <p className="content">{getSingleCourseDetail && getSingleCourseDetail?.courseCode}</p>
                </div>
                <div className="courseDetailsBox">
                  <p className="heading">Hours</p>
                  <p className="content"> {getSingleCourseDetail && getSingleCourseDetail?.hours}</p>
                </div>
                <div className="courseDetailsBox">
                  <p className="heading">Credits</p>
                  <p className="content"> {getSingleCourseDetail && getSingleCourseDetail?.credits}</p>
                </div>
                <div className="courseDetailsBox">
                  <p className="heading">Course Start Date</p>
                  <p className="content"> {getSingleCourseDetail && moment(getSingleCourseDetail?.startDate).format("DD-MM-YYYY")}</p>
                </div>
                <div className="courseDetailsBox">
                  <p className="heading">Course End Date</p>
                  <p className="content"> {getSingleCourseDetail && moment(getSingleCourseDetail?.endDate).format("DD-MM-YYYY")}</p>
                </div>
              </div>
              <div className="courseDescriptionBox">
                <p className="heading">Course Description</p>
                {/* <p className="courseDetailsDesc">
              You need to enable JavaScript to run this app.You need to enable
              JavaScript to run this app.You need to enable JavaScript to run
              this app.You need to enable JavaScript to run this app.
            </p> */}
                {getSingleCourseDetail?.description && (
                  <div
                    className="courseDetailsDesc"
                    dangerouslySetInnerHTML={{
                      __html: removeTags(getSingleCourseDetail?.description),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="courseDetailsOuter">
            <QuizPopup />
            <div className="courseDetailsMenuOuter">
              <div className="sessionDetailsOuter">
                {/* <Dropdown /> */}
                <p className="sessionName">
                  {getSessionByCourse?.length > 0 &&
                    getSessionByCourse[0]?.CourseRoster?.courseName}
                </p>
              </div>
              <h4 className="courseDetailsMenuHeading">Session Overview</h4>
              {getSessionByCourse?.length > 0 &&
                getSessionByCourse?.map((res, i) => (
                  <div
                    className={`sessionOverviewCard ${toggleCourse === i ? "active" : ""
                      }`}
                    onClick={() => setToggleCourse(i)}
                  >
                    <div className="sessionCardHeader">
                      <p className="sessionHeading">{res.name}</p>
                      <img src="/assets/icons/arrow-narrow-right.svg" alt="" />
                    </div>

                    <div className="currentStatus">
                      {res.isCompleted ? (
                        <img src="../assets/icons/check-circle.svg" alt="" />
                      ) : (
                        <img src="../assets/icons/pendingCircle.svg" alt="" />
                      )}

                      {res.isCompleted ? (
                        <p className="successText">Completed</p>
                      ) : (
                        <p className="secondaryText">Pending</p>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="courseContentOuter">
              {getSessionByCourse?.length > 0 &&
                getSessionByCourse?.map((res, i) => (
                  <div
                    className={`courseContentBox ${toggleCourse === i ? "active" : ""
                      }`}
                  >
                    <div className="courseContentBoxHeader">
                      <div className="tags">
                        <div className="greyTag">Session 1</div>
                      </div>
                      <div
                        className="toggleBtn"
                        onClick={() => setToggleCourse(i)}
                      >
                        {toggleCourse === i ? <p>Collapse</p> : <p>Expand</p>}
                        <img
                          src="/assets/icons/chevron-down-black.svg"
                          alt=""
                          className="toggleBtnIcon"
                        />
                      </div>
                    </div>
                    <div className="courseContentBodyHeader">
                      <p className="sessionName">{res.name}</p>
                      <div className="currentStatus">
                        {res.isCompleted ? (
                          <img src="../assets/icons/check-circle.svg" alt="" />
                        ) : (
                          <img src="../assets/icons/pendingCircle.svg" alt="" />
                        )}

                        {res.isCompleted ? (
                          <p className="successText">Completed</p>
                        ) : (
                          <p className="secondaryText">Pending</p>
                        )}
                      </div>
                    </div>
                    <div className="submitDate">
                      <p>
                        Submit Date:{" "}
                        <span>{moment(res.date).format("DD-MM-YYYY")}</span>
                      </p>
                    </div>
                    <div className="courseContentBoxBody">
                      <div className="videoBox">
                        {res?.videoUrl === null || res?.videoUrl === "" ? (
                          ""
                        ) : (
                          // <img
                          //     src="https://images.pexels.com/photos/2531608/pexels-photo-2531608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                          //     alt=""
                          // />
                          <video
                            className="videoBox"
                            src={res.videoUrl}
                            controlsList="nodownload"
                            disablePictureInPicture
                            controls
                            muted
                          ></video>
                        )}
                      </div>
                      <p className="sessionDescription">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: removeTags(res.description),
                          }}
                        />
                      </p>
                      {/* <div className="fileContainer">
                                            <FileViewer />
                                            <FileViewer />
                                        </div> */}
                      <div className="fileContainer">
                        {
                          res?.SessionFiles?.length > 0 && res.SessionFiles?.map((data) => (
                            <FileViewer
                              fileName={data.fileName}
                              fileUrl={data.fileUrl}
                            />
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>

    </>
  );
};

export default CourseDetail;
