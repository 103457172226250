import React from 'react'
import BreadCrumbs from '../../../components/globalComponents/breadCrumbs/BreadCrumbs'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import ProgressBar from '../../../components/globalComponents/progressBar/ProgressBar'
// import CustomTimePicker from '../../../components/globalComponents/customtimePicker/CustomTimePicker'

const StudentPerCourse = () => {
    const layer = ['Student list', 'Product Management']
    return (
        <div className='studentContainer'>

            <div className="pageHeader">
            <BreadCrumbs layers={layer} />
                    <div></div>
                    <div></div>
            </div>

            {/* <div className="filterBar">
                <div className="searchBox">
                    <img src="/assets/icons/search-sm.svg" alt="" />
                    <input type="text" className="searchInput" placeholder="Search ..." />
                </div>
                <button className="fiterBtn">
                {" "}
                <img src="/assets/icons/filter-lines.svg" alt="" /> Filters
                </button>
            </div> */}

            <div className="clubListTableContainer">
                <table className="clubListTable studentListTable courseListTable" cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th>Student name</th>
                            <th>Gender</th>
                            <th>Mobile Number</th>
                            <th>Gmail</th>
                            <th>Program Progress</th>
                            <th>View Details</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>
                                <div className='studentImage'>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt='profile picture1'/>
                                    Mona Kane
                                </div>
                            </td>
                            <td>Female</td>
                            <td>9597569853</td>
                            <td>ladygaga@masterunion.org</td>
                            <td><div className='progressAlign'><ProgressBar color={'#1C7C54'} width={'70%'}/>72%</div></td>
                            <td><PrimaryButton children={'View Details'} classname={'secondaryBtnSmall'} /></td>
                        </tr>

                        <tr>
                            <td>
                                <div className='studentImage'>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt='profile picture2'/>
                                    Mona Kane
                                </div>
                            </td>
                            <td>Female</td>
                            <td>9597569853</td>
                            <td>ladygaga@masterunion.org</td>
                            <td><div className='progressAlign'><ProgressBar color={'#1C7C54'} width={'70%'}/>72%</div></td>
                            <td><PrimaryButton children={'View Details'} classname={'secondaryBtnSmall'} /></td>
                        </tr>

                        <tr>
                            <td>
                                <div className='studentImage'>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt='profile picture3'/>
                                    Mona Kane
                                </div>
                            </td>
                            <td>Female</td>
                            <td>9597569853</td>
                            <td>ladygaga@masterunion.org</td>
                            <td><div className='progressAlign'><ProgressBar color={'#1C7C54'} width={'70%'}/>72%</div></td>
                            <td><PrimaryButton children={'View Details'} classname={'secondaryBtnSmall'} /></td>
                        </tr>

                        <tr>
                            <td>
                                <div className='studentImage'>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt='profile picture4'/>
                                    Mona Kane
                                </div>
                            </td>
                            <td>Female</td>
                            <td>9597569853</td>
                            <td>ladygaga@masterunion.org</td>
                            <td><div className='progressAlign'><ProgressBar color={'#1C7C54'} width={'70%'}/>72%</div></td>
                            <td><PrimaryButton children={'View Details'} classname={'secondaryBtnSmall'} /></td>
                        </tr>

                        <tr>
                            <td>
                                <div className='studentImage'>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt='profile picture5'/>
                                    Mona Kane
                                </div>
                            </td>
                            <td>Female</td>
                            <td>9597569853</td>
                            <td>ladygaga@masterunion.org</td>
                            <td><div className='progressAlign'><ProgressBar color={'#1C7C54'} width={'70%'}/>72%</div></td>
                            <td><PrimaryButton children={'View Details'} classname={'secondaryBtnSmall'} /></td>
                        </tr>

                        <tr>
                            <td>
                                <div className='studentImage'>
                                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png" alt='profile picture6'/>
                                    Mona Kane
                                </div>
                            </td>
                            <td>Female</td>
                            <td>9597569853</td>
                            <td>ladygaga@masterunion.org</td>
                            <td><div className='progressAlign'><ProgressBar color={'#1C7C54'} width={'70%'}/>72%</div></td>
                            <td><PrimaryButton children={'View Details'} classname={'secondaryBtnSmall'} /></td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default StudentPerCourse