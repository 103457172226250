import React, { useEffect, useState } from "react";
import "./feedBackStyle.css";
import Button from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
// import Buttons from "../../../components/globalComponents/buttons/Buttons";
import BreadCrumbs from "../../../components/globalComponents/breadCrumbs/BreadCrumbs";
// import CreateInput from "../../../components/createInput/CreateInput";
import Rating from "../../../components/rating/Rating";
import SingleSelect from "../../../components/singleSelect/SingleSelect";
import MultiSelect from "../../../components/multiSelect/MultiSelect";
import UploadBox from "../../../components/UploadBox/UploadBox";
import EmailPopup from "../../../components/popups/emailPopup/EmailPopup";
// import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
import PrimaryButtonImg from "../../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  feedbackData,
  getFeedbackDetailById,
  publishFeedback,
} from "../../../reducers/feedbackSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageLoader from "../../../components/globalComponents/PageLoader/PageLoader";
import TextArea from "../../../components/globalComponents/textArea/TextArea";
import { commonData, getEmailTemplate } from "../../../reducers/commonSlice";

const FeedBackPreview = () => {
  const dispatch = useDispatch();
  const {
    isFetching,
    isSuccess,
    // isError,
    // errorMessage,
    // feedbackList,
    // feedbackResponse,
    // studentList,
    feedbackDetail,feedbackAnswerIsFetching
  } = useSelector(feedbackData);
  const navigate = useNavigate();
  const { emailTemplateData } = useSelector(commonData);
  const [searchParams] = useSearchParams();
  const feedbackId = searchParams.get("id");
  const [questionArray, setQuestionArray] = useState([]);
  const [isMailPopupOpen, setIsMailPopupOpen] = useState(false);

  const proId = searchParams.get("proId") ?? "";
  const proMasterId = searchParams.get("proMasterId") ?? "";
  const courseId = searchParams.get("courseId") ?? "";
  const type = searchParams.get("type");

  const log = () => {
    console.log("jj");
  };

  // const layers = ["List of feedback", "Product Management | Session"];

  useEffect(() => {
    dispatch(
      getEmailTemplate({
        templateName: "Masters'Union_Create_Session_Template",
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (feedbackId) dispatch(getFeedbackDetailById(feedbackId));
    // eslint-disable-next-line
  }, [feedbackId]);

  useEffect(() => {
    if (
      feedbackDetail !== null ||
      feedbackDetail !== undefined ||
      feedbackDetail !== "null"
    ) {
      //   setPreviewType(feedbackDetail && feedbackDetail.previewType);
      //   setLayoutType(feedbackDetail && feedbackDetail.layoutType);

      var array = [];

      feedbackDetail &&
        feedbackDetail.FeedbackQuestions.forEach((element, index) => {
          var item = {
            id: index,
            questionType: element.questionType,
            questionName: element.questionName,
            isNotMandatory: element.isNotMandatory,
            option: element.FeedbackQuestionOptions,
          };

          array.push(item);
        });

      setQuestionArray(array);
    }
  }, [feedbackDetail]);

  console.log("fdfidhifdihfiehdfihdifhidfhidhfidhfihdifh", feedbackDetail);

  const toggleMailPopup = () => {
    setIsMailPopupOpen(!isMailPopupOpen);
  };

  const onClickLiveButton = () => {
      // if(type === "Quiz") {
        const obj = {
          specificTemplateBody: emailTemplateData.templateBody,
          specificTemplateSubject: emailTemplateData.templateSubject,
          feedId: feedbackId,
          organizationId: null,
          adminId: null,
          userId: null,
        };
    
        dispatch(publishFeedback(obj));
      // }
      // else toggleMailPopup();
  };
  const onClickSendMail = () => {
    const obj = {
      specificTemplateBody: emailTemplateData.templateBody,
      specificTemplateSubject: emailTemplateData.templateSubject,
      feedId: feedbackId,
      organizationId: null,
      adminId: null,
      userId: null,
    };

    dispatch(publishFeedback(obj));
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      toggleMailPopup();
      navigate(
        `/feedbackList?proId=${proId}&proMasterId=${proMasterId}&courseId=${courseId}&type=${type.toLowerCase()}`
      );
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <>
      {(feedbackAnswerIsFetching || isFetching) && <PageLoader />}
      <EmailPopup
        handleClose={toggleMailPopup}
        show={isMailPopupOpen}
        emailTemplateData={emailTemplateData}
        handleSendMail={onClickSendMail}
      />
      <div className="pageHeader">
        <div
          onClick={() => navigate(`/feedbackList?proId=${proId}&proMasterId=${proMasterId}&courseId=${courseId}&type=${type.toLowerCase()}`)}
          style={{ marginLeft: "8px" }}
          className="backBtn"
        >
          <img src="/assets/icons/back-btn.svg" alt="" />
          Back to Page
        </div>
        <div style={{marginLeft:"80px"}}>
          <label>
            <b>{type} Preview</b>
          </label>
        </div>
        <div className="pageHeaderRight">
          {/* <PrimaryButtonImg
            img={"/assets/icons/edit-02.svg"}
            children={"Edit"}
            classname={"darkSecondaryOutlineBtn"}
          /> */}
          {(feedbackDetail?.isPublished || feedbackDetail?.status === "expire") ? null : (
            <PrimaryButtonImg
              img={"/assets/icons/rss-01.svg"}
              children={"Live"}
              classname={"dangerBtn"}
              onClick={onClickLiveButton}
            />
          )}
          
        </div>
      </div>

      <div className="main-wrapper with-card feedback-wrapper">
        <div className="fullScreeeWrapper" id="topElement">
          <div className="question-box">
            <h1 className="heading-2">{feedbackDetail?.title}</h1>
            <p className="text-3 mt-20">{feedbackDetail?.description}</p>
            <a href={`#feedback0`}>
              <Button
                onclick={log}
                children={"Get Started"}
                classname={"feedbackDangerBtn mt-40"}
                isdisabled={false}
              />
            </a>
          </div>
        </div>

        {/* <div className="question-box">
          <CreateInput />
          <Button
            onclick={log}
            children={"Get Started"}
            classname={"feedbackDangerBtn mt-40"}
            isdisabled={false}
          />
        </div> */}

        {questionArray.map((item, index) => {
          console.log("fdinfidnfindf", item);
          const {
            // id,
            questionType,
            questionName,
            // answers,
            // title,
            // desc,
            // required,
          } = item;

          return (
            <div className="fullScreeeWrapper" id={`feedback${index}`}>
              <div className="question-box">
                <h1 className="text-3">{questionName}</h1>
                {questionType === "Multi Select" && (
                  <div className="feedbackCheckboxOuter">
                    <MultiSelect item={item} />
                  </div>
                )}

                {questionType === "Single Select" && (
                  <div className="radioFeedbackWrapper">
                    <SingleSelect item={item} isDisabled={true}/>
                  </div>
                )}
                {questionType === "Upload Media" && (
                  <div className="feedbackCheckboxOuter">
                    <UploadBox item={{ id: "" }} isDisabled={true}/>
                  </div>
                )}
                {questionType === "Paragraph" && (
                  <TextArea placeholder={"Enter your Answer"} isDisabled={true}/>
                )}
                {questionType === "Rating" && (
                  <div className="ratingWrapper">
                    <Rating item={{ id: "aj" }} />
                  </div>
                )}

                <a href={`#feedback${index + 1}`}>
                  <Button
                    onclick={log}
                    children={"Continue"}
                    classname={"feedbackDangerBtn mt-40"}
                    isdisabled={false}
                  />{" "}
                </a>
              </div>

              {/* {index === questionArray.length - 1 && (
                <a href="#topElement" className="scrollTop">
                  <svg
                    width="45"
                    height="42"
                    viewBox="0 0 500 500"
                    fill="#000"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M386.049 323.549C379.947 329.65 370.053 329.65 363.951 323.549L250 209.597L136.049 323.549C129.947 329.651 120.053 329.651 113.951 323.549C107.85 317.447 107.85 307.553 113.951 301.451L238.951 176.451C245.053 170.35 254.947 170.35 261.049 176.451L386.049 301.451C392.15 307.553 392.15 317.447 386.049 323.549Z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              )} */}
            </div>
          );
        })}

        {/* <div className="question-box">
          <h1 className="text-3">
            Please select many option from below options
          </h1>
          <SingleSelect
            item={{ option: ["test"] }}
            value={{ feedbackOptionId: [{ id: "1" }] }}
            onSelect={onSelect}
          />
          <Button
            onclick={log}
            children={"Get Started"}
            classname={"feedbackDangerBtn mt-40"}
            isdisabled={false}
          />
        </div>

        <div className="question-box">
          <h1 className="text-3">Please give us rating accordingly</h1>
          <MultiSelect
            item={{ option: [{ id: "test" }] }}
            value={{ feedbackOptionId: [{ id: "1" }] }}
            select={selected}
          />
          <Button
            onclick={log}
            children={"Get Started"}
            classname={"feedbackDangerBtn mt-40"}
            isdisabled={false}
          />
        </div>

        <div className="question-box">
          <h1 className="text-3">Please give us rating accordingly</h1>
          <UploadBox value={{ answer: "aj" }} item={{ id: "" }} />
          <Button
            onclick={log}
            children={"Get Started"}
            classname={"feedbackDangerBtn mt-40"}
            isdisabled={false}
          />
        </div> */}
      </div>
      {/* me */}
    </>
  );
};

export default FeedBackPreview;
