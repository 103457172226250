import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/layout/header/Header";
import SideMenu from "./components/layout/sideMenu/SideMenu";
import CourseDetail from "./pages/courseDetails/CourseDetail";
import Login from "./pages/login/Login";
import DashBoard from "./pages/dashBoard/DashBoard";
import MyCourses from "./pages/myCourses/MyCourses";
import ClubList from "./pages/clubModule/clubList/ClubList";
import ClubDetails from "./pages/clubModule/clubDetails/ClubDetails";
import Notification from "./components/notificationComponent/Notification";
import Attendance from "./pages/Attendance/Attendance";
import Notifications from "./pages/notifiactions/Notifications";
import Announcement from "./pages/announcements/Announcement";
import CreateAnnouncement from "./pages/announcements/CreateAnnouncement";
import AttendanceList from "./pages/Attendance/AttendanceList";
import CreateClub from "./pages/clubModule/createClub/CreateClub";
import FeedbackList from "./pages/feedback/feedbackList/FeedbackList";
import FeedBackPreview from "./pages/feedback/feedBackPreview/FeedBackPreview";
import EditClub from "./pages/clubModule/editClub/EditClub";
import Grades from "./pages/grades/Grades";
import HelpDesk from "./pages/help/helpDesk/HelpDesk";
import CreateHelpTicket from "./pages/help/createHelpTicket/CreateHelpTicket";
import TicketRecord from "./pages/help/ticketRecord/TicketRecord";
import LeaveList from "./pages/leave/leaveList/LeaveList";
import LeaveDetails from "./pages/leave/leaveDetails/LeaveDetails";
import LeaveApplication from "./pages/leave/leaveApplication/LeaveApplication";
import EventList from "./pages/events/eventList/EventList";
import CreateEvent from "./pages/events/createEvent/CreateEvent";
import EventDetails from "./pages/events/eventDetails/EventDetails";
import Profile from "./pages/profile/Profile";
import Fee from "./pages/fee/Fee";
import MeetingList from "./pages/meeting/meetingList/MeetingList";
import CreateMeeting from "./pages/meeting/createMeeting/CreateMeeting";
import SessionRecord from "./pages/meeting/sessionRecord/SessionRecord";
import ManageLeaves from "./pages/leave/manageLeaves/ManageLeaves";
import AdminHelpDesk from "./pages/help/adminHelpDesk/AdminHelpDesk";
import AllCourseAttendance from "./pages/Attendance/AllCourseAttendance";
import ProgramAssignmentList from "./pages/assignment/ProgramAssignmentList";
import CourseAssignmentList from "./pages/assignment/CourseAssignmentList";
import AssignmentList from "./pages/assignment/AssignmentList";
import AssignmentGrades from "./pages/assignment/AssignmentGrades";
import CreateAssignment from "./pages/assignment/CreateAssignment";
import { PrivateRoute } from "./helpers/PrivateRoute";
import AddCourse from "./pages/course/addCourse/AddCourse";
import AllProgramList from "./pages/course/allProgramList/AllProgramList";
import AllCourseList from "./pages/course/allCourseList/AllCourseList";
import { useSelector } from "react-redux";
import { userSelector } from "./reducers/userSlice";
import CreateFeedbackModule from "./pages/feedback/createFeedback/CreateFeedbackModule";
import "suneditor/dist/css/suneditor.min.css";
import GradeList from "./pages/grades/GradeList";
import CreateGroupAssignment from "./pages/assignment/CreateGroupAssignment";
import StudentList from "./pages/students/studentList/StudentList";
import StudentPerCourse from "./pages/students/studentList/StudentPerCourse";
import FeedBackAnswer from "./pages/feedback/feedBackAnswer/FeedBackAnswer";
import AllSessions from "./pages/session/AllSessions";
import InterviewScheduler from "./pages/interviewScheduler/InterviewScheduler";
import CreateInterviewSlots from "./pages/interviewScheduler/CreateInterviewSlots";
import LogoutPopup from "./components/popups/LogoutPopup/LogoutPopup";
import CourseBidding from "./pages/course/courseBidding/CourseBidding";
import TermPlanDetails from "./pages/course/termPlanDetails/TermPlanDetails";
function App() {
  const pathname = window.location.pathname;
  const { token } = useSelector(userSelector);
  const [logoutToggler, setLogoutToggler] = useState(false);
  console.log(window.location.pathname,"window.location.pathname");

  // console.log = function () {}
  return (
    <div className="appOuter">
      <Router>
        {pathname === "/login" || token === null ? (
          ""
        ) : (
          <Header/>
        )}
        <LogoutPopup
          isOpen={logoutToggler}
          onClose={() => setLogoutToggler(false)}
          title={"Logout"}
          boldText={"Are you sure you want to logout?"}
          btnType={"successBtn"}
          btnText={"Continue"}
        />
        {/* <Notification /> */}
        <div className="appOuterContainer">
            { pathname !== "/login" && token !== null && <SideMenu setLogout={setLogoutToggler}/>}
          <div className={`appOuterContainerRight ${(pathname === "/login" || token === null) ? "loginPageSet":""}`}>
            <Routes>
              <Route exact path="/login" element={<Login />} />
            </Routes>
            {pathname !== "/login" && (
              <div className="appContainer">
                <Routes>
                  <Route path="/" element={<PrivateRoute />}>
                    <Route exact path="/feedbackList" element={<FeedbackList />} />
                    <Route
                      exact
                      path="/createFeedback/:id"
                      element={<CreateFeedbackModule />}
                    />
                    <Route exact path="/dashboard" element={<DashBoard />} />
                    <Route exact path="/" element={<AllProgramList />} />
                    <Route exact path="/myCourses" element={<MyCourses />} />
                    <Route exact path="/courseDetails" element={<CourseDetail />} />
                    <Route exact path="/clubList" element={<ClubList />} />
                    <Route exact path="/clubDetails" element={<ClubDetails />} />
                    <Route exact path="/createClub" element={<CreateClub />} />
                    <Route exact path="/editClub" element={<EditClub />} />
                    <Route exact path="/attendance" element={<Attendance />} />
                    <Route
                      exact
                      path="/allCourseAttendance"
                      element={<AllCourseAttendance />}
                    />
                    <Route
                      exact
                      path="/attendanceList"
                      element={<AttendanceList />}
                    />
                    <Route
                      exact
                      path="/notifications"
                      element={<Notifications />}
                    />
                    <Route exact path="/announcement" element={<Announcement />} />
                    <Route
                      exact
                      path="/createAnnouncement"
                      element={<CreateAnnouncement />}
                    />
                    <Route
                      exact
                      path="/feedbackPreview"
                      element={<FeedBackPreview />}
                    />
                    <Route
                      exact
                      path="/feedbackAnswer/:fbId/:stuId"
                      element={<FeedBackAnswer />}
                    />
                    <Route
                      exact
                      path="/grades/:programId/:proMasterId/:studentId"
                      element={<Grades />}
                    />
                    <Route
                      exact
                      path="/gradesList/:programId/:proMasterId/:courseId"
                      element={<GradeList />}
                    />
                    <Route exact path="/help" element={<HelpDesk />} />
                    <Route
                      exact
                      path="/adminHelpDesk"
                      element={<AdminHelpDesk />}
                    />
                    <Route
                      exact
                      path="/createHelpTicket"
                      element={<CreateHelpTicket />}
                    />
                    <Route
                      exact
                      path="/ticketRecord/:studentId/:ticketId"
                      element={<TicketRecord />}
                    />
                    <Route exact path="/leaveList" element={<LeaveList />} />
                    <Route exact path="/manageLeaves" element={<ManageLeaves />} />
                    <Route exact path="/leaveDetails" element={<LeaveDetails />} />
                    <Route
                      exact
                      path="/leaveApplication"
                      element={<LeaveApplication />}
                    />
                    <Route exact path="/eventList" element={<EventList />} />
                    <Route exact path="/createEvent" element={<CreateEvent />} />
                    <Route exact path="/eventDetails" element={<EventDetails />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/fee" element={<Fee />} />
                    <Route exact path="/meetingList" element={<MeetingList />} />
                    <Route exact path="/createMeting" element={<CreateMeeting />} />
                    <Route
                      exact
                      path="/sessionRecord"
                      element={<SessionRecord />}
                    />
                    <Route
                      exact
                      path="/programAssignment/:grade"
                      element={<ProgramAssignmentList />}
                    />
                    <Route
                      exact
                      path="/courseAssignment/:programId/:proMasterId/:name"
                      element={<CourseAssignmentList />}
                    />
                    <Route
                      exact
                      path="/allSessions/:programId/:proMasterId/:courseId/:name"
                      element={<AllSessions />}
                    />
                    <Route
                      exact
                      path="/assignment/:programId/:proMasterId/:courseId"
                      element={<AssignmentList />}
                    />
                    <Route
                      exact
                      path="/assignmentGrade/:assignmentId"
                      element={<AssignmentGrades />}
                    />
                    <Route
                      exact
                      path="/createAssignment/:programId/:proMasterId/:courseId/:sessionId"
                      element={<CreateAssignment />}
                    />
                    <Route
                      exact
                      path="/createGroupAssignment/:programId/:proMasterId/:courseId/:sessionId"
                      element={<CreateGroupAssignment />}
                    />
                    <Route exact path="/addCourse" element={<AddCourse />} />
                    <Route exact path="/allProgram" element={<AllProgramList />} />
                    <Route exact path="/allCourse" element={<AllCourseList />} />
                    <Route exact path="/studentList" element={<StudentList />} />
                    <Route
                      exact
                      path="/StudentPerCourse"
                      element={<StudentPerCourse />}
                    />
                    <Route
                      exact
                      path="/interviewScheduler"
                      element={<InterviewScheduler />}
                    />
                    <Route
                      exact
                      path="/createInterviewSlot"
                      element={<CreateInterviewSlots />}
                    />
                    <Route
                      exact
                      path="/courseBidding"
                      element={<CourseBidding />}
                    />
                    <Route
                      exact
                      path="/termPlanDetails"
                      element={<TermPlanDetails />}
                    />
                  </Route>
                </Routes>
              </div>
            )}
          </div>
        </div>

      </Router>
    </div>
  );
}

export default App;
