import React from 'react'
import './CreateEventStyle.css'
import PrimaryButton from '../../../components/globalComponents/buttons/primaryButton/PrimaryButton'
// import PrimaryButtonImg from '../../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
import CustomDatePicker from '../../../components/globalComponents/customDatePicker/CustomDatePicker'
// import Dropdown from '../../../components/globalComponents/dropdown/Dropdown'
import TextArea from '../../../components/globalComponents/textArea/TextArea'
import TextField from '../../../components/globalComponents/textFields/TextField'

const CreateEvent = () => {
    return (
        <>
            <div className="pageHeader">
                <div></div>
                <div className="pageTogglers">
                    <div className='pageToggler active'>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83333 4.79163C1.71827 4.79163 1.625 4.8849 1.625 4.99996C1.625 5.11502 1.71827 5.20829 1.83333 5.20829C1.94839 5.20829 2.04167 5.11502 2.04167 4.99996C2.04167 4.8849 1.94839 4.79163 1.83333 4.79163ZM0.375 4.99996C0.375 4.19454 1.02792 3.54163 1.83333 3.54163C2.63875 3.54163 3.29167 4.19454 3.29167 4.99996C3.29167 5.80537 2.63875 6.45829 1.83333 6.45829C1.02792 6.45829 0.375 5.80537 0.375 4.99996ZM5.375 4.99996C5.375 4.65478 5.65482 4.37496 6 4.37496L16 4.37496C16.3452 4.37496 16.625 4.65478 16.625 4.99996C16.625 5.34514 16.3452 5.62496 16 5.62496L6 5.62496C5.65482 5.62496 5.375 5.34514 5.375 4.99996ZM1.83333 9.79163C1.71827 9.79163 1.625 9.8849 1.625 9.99996C1.625 10.115 1.71827 10.2083 1.83333 10.2083C1.94839 10.2083 2.04167 10.115 2.04167 9.99996C2.04167 9.8849 1.94839 9.79163 1.83333 9.79163ZM0.375 9.99996C0.375 9.19454 1.02792 8.54163 1.83333 8.54163C2.63875 8.54163 3.29167 9.19454 3.29167 9.99996C3.29167 10.8054 2.63875 11.4583 1.83333 11.4583C1.02792 11.4583 0.375 10.8054 0.375 9.99996ZM5.375 9.99996C5.375 9.65478 5.65482 9.37496 6 9.37496L16 9.37496C16.3452 9.37496 16.625 9.65478 16.625 9.99996C16.625 10.3451 16.3452 10.625 16 10.625L6 10.625C5.65482 10.625 5.375 10.3451 5.375 9.99996ZM1.83333 14.7916C1.71827 14.7916 1.625 14.8849 1.625 15C1.625 15.115 1.71827 15.2083 1.83333 15.2083C1.94839 15.2083 2.04167 15.115 2.04167 15C2.04167 14.8849 1.94839 14.7916 1.83333 14.7916ZM0.375 15C0.375 14.1945 1.02792 13.5416 1.83333 13.5416C2.63875 13.5416 3.29167 14.1945 3.29167 15C3.29167 15.8054 2.63875 16.4583 1.83333 16.4583C1.02792 16.4583 0.375 15.8054 0.375 15ZM5.375 15C5.375 14.6548 5.65482 14.375 6 14.375L16 14.375C16.3452 14.375 16.625 14.6548 16.625 15C16.625 15.3451 16.3452 15.625 16 15.625L6 15.625C5.65482 15.625 5.375 15.3451 5.375 15Z" fill="#262626" />
                        </svg>
                        Create Event
                    </div>
                </div>
                <div></div>
            </div>
            <div className="createClubContainer">
                <div className="createClubContainerOne createEventContainer">
                    <div className="createClubHeader">
                        <div className="addClubBackgroundImg">
                            <img src="https://images.pexels.com/photos/46160/field-clouds-sky-earth-46160.jpeg?auto=compress&cs=tinysrgb&w=1600" alt=""
                                className='clubBackgroundImg' />

                        </div>
                        {/* <div className="clubProfile">
              <img src="https://images.pexels.com/photos/13187759/pexels-photo-13187759.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load" alt="" className='clubProfilePhoto' />
              <img src="/assets/icons/camera-01.svg" alt="" className='addProfilePhoto' />
            </div> */}
                    </div>
                    <form className='formOuter'>
                        <TextField labelName={'Name of the Event'} inputType={'text'} placeholder={'Enter event name'} />
                        <TextField labelName={'Club/Committee Name'} inputType={'text'} placeholder={'Club/Committee Name'} />
                        <TextField labelName={'Describe the usage of this funding during the event'} inputType={'text'} placeholder={'Vision of your Club'} />
                        <TextField labelName={'Amount Needed'} inputType={'number'} placeholder={'enter the amount'} />
                        <CustomDatePicker label={'Proposed time of the event'} />
                        <CustomDatePicker label={'Proposed date of the event'} />
                        <TextField labelName={'Support required for the event'} inputType={'text'} placeholder={'Write your support required'} />
                        <TextField labelName={'Resources required for the event'} inputType={'text'} placeholder={'Write your resources required'} />
                        <div className="form2Col">
                            <div className="formGrp">
                                <label htmlFor="">Event type</label>
                                <div className="radioGrpFlex">
                                    <div className="radioBtn">
                                        <input type="radio" id="test1" name="radio-group1" />
                                        <label for="test1">Internal Event</label>
                                    </div>
                                    <div className="radioBtn">
                                        <input type="radio" id="test2" name="radio-group1" />
                                        <label for="test2">External Event</label>
                                    </div>
                                </div>
                            </div>
                            <div className="formGrp">
                                <label htmlFor="">Registration required</label>
                                <div className="radioGrpFlex">
                                    <div className="radioBtn">
                                        <input type="radio" id="test3" name="radio-group2" />
                                        <label for="test3">Required</label>
                                    </div>
                                    <div className="radioBtn">
                                        <input type="radio" id="test4" name="radio-group2" />
                                        <label for="test4">Not Required</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form2Col">
                            <div className="formGrp">
                                <label htmlFor="">Enable rating & reviews</label>
                                <div className="radioGrpFlex">
                                    <div className="radioBtn">
                                        <input type="radio" id="test5" name="radio-group3" />
                                        <label for="test5">Yes</label>
                                    </div>
                                    <div className="radioBtn">
                                        <input type="radio" id="test6" name="radio-group3" />
                                        <label for="test6">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="formGrp">
                                <label htmlFor="">Visibility of all students </label>
                                <div className="radioGrpFlex">
                                    <div className="radioBtn">
                                        <input type="radio" id="test7" name="radio-group4" />
                                        <label for="test7">Yes</label>
                                    </div>
                                    <div className="radioBtn">
                                        <input type="radio" id="test8" name="radio-group4" />
                                        <label for="test8">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TextArea labelName={'Detailed description of the event'} placeholder={'Detailed description of the event'} />
                    </form>
                    <div className="createClubContainerFooter flexEnd">
                        <PrimaryButton classname={'primaryBtn'} children={'Submit'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateEvent