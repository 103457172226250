import React from 'react'

const Rating = () => {
    return (
        <div class="starRating">
            <input type="radio" name="rating" id="st1" value="1" />
            <label for="st1">
                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2191 0.957685L13.8682 5.78303C14.1195 6.24087 14.5622 6.56251 15.0753 6.66007L20.4831 7.68833C20.6769 7.72518 20.7537 7.96146 20.6185 8.10518L16.8479 12.1157C16.4902 12.4962 16.3211 13.0166 16.3869 13.5347L17.08 18.9956C17.1049 19.1913 16.9039 19.3373 16.7254 19.2532L11.7461 16.9065C11.2736 16.6838 10.7264 16.6838 10.2539 16.9065L5.27457 19.2532C5.09613 19.3373 4.89514 19.1913 4.91998 18.9956L5.61314 13.5347C5.67891 13.0166 5.50981 12.4962 5.15205 12.1157L1.38147 8.10518C1.24635 7.96146 1.32312 7.72518 1.51691 7.68833L6.92468 6.66007C7.43778 6.56251 7.88048 6.24087 8.13182 5.78303L10.7809 0.957686C10.8758 0.784763 11.1242 0.784764 11.2191 0.957685Z" stroke="#FDCA40" stroke-width="1.5" stroke-linejoin="round" />
                </svg>


            </label>
            <input type="radio" name="rating" id="st2" value="2" />
            <label for="st2">
                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2191 0.957685L13.8682 5.78303C14.1195 6.24087 14.5622 6.56251 15.0753 6.66007L20.4831 7.68833C20.6769 7.72518 20.7537 7.96146 20.6185 8.10518L16.8479 12.1157C16.4902 12.4962 16.3211 13.0166 16.3869 13.5347L17.08 18.9956C17.1049 19.1913 16.9039 19.3373 16.7254 19.2532L11.7461 16.9065C11.2736 16.6838 10.7264 16.6838 10.2539 16.9065L5.27457 19.2532C5.09613 19.3373 4.89514 19.1913 4.91998 18.9956L5.61314 13.5347C5.67891 13.0166 5.50981 12.4962 5.15205 12.1157L1.38147 8.10518C1.24635 7.96146 1.32312 7.72518 1.51691 7.68833L6.92468 6.66007C7.43778 6.56251 7.88048 6.24087 8.13182 5.78303L10.7809 0.957686C10.8758 0.784763 11.1242 0.784764 11.2191 0.957685Z" stroke="#FDCA40" stroke-width="1.5" stroke-linejoin="round" />
                </svg>


            </label>
            <input type="radio" name="rating" id="st3" value="3" />
            <label for="st3">
                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2191 0.957685L13.8682 5.78303C14.1195 6.24087 14.5622 6.56251 15.0753 6.66007L20.4831 7.68833C20.6769 7.72518 20.7537 7.96146 20.6185 8.10518L16.8479 12.1157C16.4902 12.4962 16.3211 13.0166 16.3869 13.5347L17.08 18.9956C17.1049 19.1913 16.9039 19.3373 16.7254 19.2532L11.7461 16.9065C11.2736 16.6838 10.7264 16.6838 10.2539 16.9065L5.27457 19.2532C5.09613 19.3373 4.89514 19.1913 4.91998 18.9956L5.61314 13.5347C5.67891 13.0166 5.50981 12.4962 5.15205 12.1157L1.38147 8.10518C1.24635 7.96146 1.32312 7.72518 1.51691 7.68833L6.92468 6.66007C7.43778 6.56251 7.88048 6.24087 8.13182 5.78303L10.7809 0.957686C10.8758 0.784763 11.1242 0.784764 11.2191 0.957685Z" stroke="#FDCA40" stroke-width="1.5" stroke-linejoin="round" />
                </svg>


            </label>
            <input type="radio" name="rating" id="st4" value="4" />
            <label for="st4">
                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2191 0.957685L13.8682 5.78303C14.1195 6.24087 14.5622 6.56251 15.0753 6.66007L20.4831 7.68833C20.6769 7.72518 20.7537 7.96146 20.6185 8.10518L16.8479 12.1157C16.4902 12.4962 16.3211 13.0166 16.3869 13.5347L17.08 18.9956C17.1049 19.1913 16.9039 19.3373 16.7254 19.2532L11.7461 16.9065C11.2736 16.6838 10.7264 16.6838 10.2539 16.9065L5.27457 19.2532C5.09613 19.3373 4.89514 19.1913 4.91998 18.9956L5.61314 13.5347C5.67891 13.0166 5.50981 12.4962 5.15205 12.1157L1.38147 8.10518C1.24635 7.96146 1.32312 7.72518 1.51691 7.68833L6.92468 6.66007C7.43778 6.56251 7.88048 6.24087 8.13182 5.78303L10.7809 0.957686C10.8758 0.784763 11.1242 0.784764 11.2191 0.957685Z" stroke="#FDCA40" stroke-width="1.5" stroke-linejoin="round" />
                </svg>


            </label>
            <input type="radio" name="rating" id="st5" value="5" />
            <label for="st5">
                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2191 0.957685L13.8682 5.78303C14.1195 6.24087 14.5622 6.56251 15.0753 6.66007L20.4831 7.68833C20.6769 7.72518 20.7537 7.96146 20.6185 8.10518L16.8479 12.1157C16.4902 12.4962 16.3211 13.0166 16.3869 13.5347L17.08 18.9956C17.1049 19.1913 16.9039 19.3373 16.7254 19.2532L11.7461 16.9065C11.2736 16.6838 10.7264 16.6838 10.2539 16.9065L5.27457 19.2532C5.09613 19.3373 4.89514 19.1913 4.91998 18.9956L5.61314 13.5347C5.67891 13.0166 5.50981 12.4962 5.15205 12.1157L1.38147 8.10518C1.24635 7.96146 1.32312 7.72518 1.51691 7.68833L6.92468 6.66007C7.43778 6.56251 7.88048 6.24087 8.13182 5.78303L10.7809 0.957686C10.8758 0.784763 11.1242 0.784764 11.2191 0.957685Z" stroke="#FDCA40" stroke-width="1.5" stroke-linejoin="round" />
                </svg>


            </label>
        </div>
    )
}

export default Rating