import React from "react";
import "./BreadCrumbsStyle.css";

const BreadCrumbs = ({layers, type, onClick}) => {
  // const layers = [
  //   "Layer 1",
  //   "Layer 2",
  //   "Layer 3",
  //   "Layer 4",
  //   "Layer 5",
  //   "Layer 6",
  // ];
  return (
   
        <div className="breadCrumbOuter">
          {layers?.map((layer, index) => {
            return (
              <div key={index} className="breadCrumb">
                <p className={`${ type === "link" && (index === 0 || index === 1 ? "primaryText pointer" : null)}`} onClick={() => onClick(index)} >{layer}</p>
                <span>/</span>
              </div>
            );
          })}
        </div>
     
  );
};

export default BreadCrumbs;
