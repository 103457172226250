import React from 'react'
import '../checkboxes/Checkbox'
const SingleSelectCheckbox = ({ label, checked, onChange, hide, disable }) => {

    return (
        <>
            <div className="checkmarkContainerOuter">
                <label class="checkboxContainer">
                    <input type="checkbox" value={label} checked={checked ? true : false} onChange={onChange} />
                    <span class="checkmark"></span>
                    {hide === true ? "" : label}
                </label>
            </div>
        </>
    );
};

export default SingleSelectCheckbox;
