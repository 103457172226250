import React from "react";
import PrimaryButton from "../../globalComponents/buttons/primaryButton/PrimaryButton";
import Checkbox from "../../globalComponents/checkboxes/Checkbox";
import "./MarkAttendancePopupStyle.css";
const MarkAttendancePopup = (props) => {

  return (
    <>
      <div className={`popupOuter ${props.show ? "active" : ""}`}>
        <div className="markAttendancePopupContainer">
          <div className="popupHeader">
            <p className="popupTitle">Mark Attendance</p>
            <img
              src="/assets/icons/x-close.svg"
              alt=""
              onClick={props.handleClose}
            />
          </div>
          <div className="popupBody">
            <p className="programName">
              {props?.getAllAttendanceStudent?.CourseRoster?.courseName}
            </p>
            <p className="subtitle">
              <p>{props.getAllAttendanceStudent?.name}</p> &bull;{" "}
              <p className="dangerText">
                {
                  props.getAllAttendanceStudent?.CourseRoster?.CourseStudents
                    ?.length
                }{" "}
                Students
              </p>
            </p>
            <div className="popupTableContainer">
              <table className="popupTable" cellPadding={0} cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Students</th>
                    <th>
                      <div className="markAttendaceheader">
                        <label className="checkboxContainer">
                          <input type="checkbox" checked={props?.allPresent} onChange={(e) => props?.onClickAllPresent(e)} />
                          <span class="checkmark"></span>
                          Present
                        </label>
                      </div>
                    </th>
                    <th>
                      <div className="markAttendaceheader">
                        <label className="checkboxContainer">
                          <input type="checkbox" checked={props?.allAbsent} onChange={(e) => props?.onClickAllAbsent(e)} />
                          <span class="checkmark"></span>
                          Absent
                        </label>
                     
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.getAllAttendanceStudent?.CourseRoster?.CourseStudents
                    ?.length > 0 &&
                    props.getAllAttendanceStudent?.CourseRoster?.CourseStudents?.map(
                      (res, i) => (
                        <tr key={i}>
                          <td>
                            {res.Student?.firstName} {res.Student?.lastName}
                          </td>
                          <td>
                            <div
                              className={`markBtn success ${props?.prasent[i]?.status === "attended"
                                ? "active"
                                : ""
                                }`}
                              onClick={() => props?.presentClick(res, i)}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M15.3977 4.10225C15.6174 4.32192 15.6174 4.67808 15.3977 4.89775L7.14775 13.1477C6.92808 13.3674 6.57192 13.3674 6.35225 13.1477L2.60225 9.39775C2.38258 9.17808 2.38258 8.82192 2.60225 8.60225C2.82192 8.38258 3.17808 8.38258 3.39775 8.60225L6.75 11.9545L14.6023 4.10225C14.8219 3.88258 15.1781 3.88258 15.3977 4.10225Z"
                                  fill="#A3A3A3"
                                />
                              </svg>
                            </div>
                          </td>
                          <td>
                            <div
                              className={`markBtn danger ${props?.absent[i]?.status === "attended"
                                ? "active"
                                : ""
                                }`}
                              onClick={() => props?.absentClick(res, i)}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4.10225 4.10225C4.32192 3.88258 4.67808 3.88258 4.89775 4.10225L9 8.2045L13.1023 4.10225C13.3219 3.88258 13.6781 3.88258 13.8977 4.10225C14.1174 4.32192 14.1174 4.67808 13.8977 4.89775L9.7955 9L13.8977 13.1023C14.1174 13.3219 14.1174 13.6781 13.8977 13.8977C13.6781 14.1174 13.3219 14.1174 13.1023 13.8977L9 9.7955L4.89775 13.8977C4.67808 14.1174 4.32192 14.1174 4.10225 13.8977C3.88258 13.6781 3.88258 13.3219 4.10225 13.1023L8.2045 9L4.10225 4.89775C3.88258 4.67808 3.88258 4.32192 4.10225 4.10225Z"
                                  fill="#A3A3A3"
                                />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="popupFooter flexEnd">
            <PrimaryButton classname={"primaryBtn"} children={"Submit"} onClick={props.attendanceSubmit} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarkAttendancePopup;
