import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import PrimaryButtonImg from '../../components/globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
import AnnouncementPopup from '../../components/popups/announcementPopup/AnnouncementPopup'
import { getAnnouncement, announcementSliceFunction } from '../../reducers/announcementSlice'
import PageLoader from '../../components/globalComponents/PageLoader/PageLoader'
import './AnnouncementStyle.css'
import moment from 'moment'

const Announcement = () => {
    const [openFilters, setOpenFilters] = useState(false)
    const [view, setView] = useState(false)
    const [popupData, setPopupData] = useState({})
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAnnouncement());
        // eslint-disable-next-line
    }, [])

    const { announcementData, isFetching } = useSelector(announcementSliceFunction)
    console.log(announcementData)

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in 
        // the input string. Replacing the identified 
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return (
        <>
            {isFetching && <PageLoader />}
            {popupData && <AnnouncementPopup
                view={view}
                setView={setView}
                data={popupData}
                removeTags={removeTags}

            />}
            <div className="pageHeader">
                <p className="componentHeading"></p>
                <div className="pageTogglers">
                    <div className="pageToggler active">

                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 19C6.83334 19 6.75 19 6.66667 19C5.75 18.9167 5 18.3333 4.66667 17.4167C4.66667 17.3333 4.58334 17.25 4.58334 17.0833L3.25 11.8333C3.25 11.8333 3.25 11.8333 3.25 11.75C2.25 10.9167 1.58334 9.66666 1.58334 8.33333C1.58334 5.91666 3.58334 3.99999 5.91667 3.99999H9.08334C10.1667 3.99999 11.6667 3.49999 13.4167 2.49999C14.3333 1.99999 14.8333 1.74999 15.25 1.83333C15.6667 1.91666 16 2.08333 16.25 2.41666C16.5 2.83333 16.5 3.24999 16.5 4.33333V4.91666C18.1667 5.24999 19.4167 6.66666 19.4167 8.41666C19.4167 10.1667 18.1667 11.5833 16.5 11.9167V12.5C16.5 13.5 16.5 14 16.25 14.4167C16 14.75 15.6667 15 15.25 15C14.75 15.0833 14.3333 14.8333 13.4167 14.3333C11.8333 13.5 10.5 13 9.5 12.9167V16.6667C9.5 16.9167 9.5 17 9.5 17.0833C9.41667 18.1667 8.5 19 7.41667 19.1667C7.33334 18.9167 7.16667 19 7 19ZM7 17.6667C7.16667 17.6667 7.25 17.6667 7.33334 17.6667C7.83334 17.5833 8.25 17.25 8.25 16.75C8.25 16.6667 8.25 16.5833 8.25 16.4167V12.6667H5.91667C5.5 12.6667 5.08334 12.5833 4.66667 12.5L5.75 16.75C5.75 16.9167 5.83334 16.9167 5.83334 17C6 17.4167 6.33334 17.6667 6.75 17.6667C6.75 17.75 6.83334 17.6667 7 17.6667ZM9.5 11.5C10.75 11.5833 12.25 12.1667 14.0833 13.0833C14.5833 13.3333 15.0833 13.5833 15.25 13.6667C15.25 13.6667 15.3333 13.6667 15.3333 13.5833C15.3333 13.5 15.3333 13 15.3333 12.4167V4.24999C15.3333 3.66666 15.3333 3.16666 15.25 3.08333C15.25 3.08333 15.1667 2.99999 15.0833 2.99999C15 2.99999 14.5 3.24999 14 3.58333C12.25 4.58333 10.6667 5.08333 9.41667 5.16666V11.5H9.5ZM5.91667 5.16666C4.16667 5.16666 2.83334 6.58333 2.83334 8.33333C2.83334 10.0833 4.25 11.5 6 11.5H8.33334V5.16666H5.91667ZM16.5 6.16666V10.5833C17.5 10.3333 18.1667 9.41666 18.1667 8.41666C18.1667 7.41666 17.5 6.41666 16.5 6.16666Z"
                                fill="#262626" />
                        </svg>
                        Announcements
                    </div>
                </div>
                <div>
                    <PrimaryButtonImg classname={'primaryBtn'} children={'Create'} img={'../assets/icons/announcement-02.svg'} onClick={() => { Navigate('/createAnnouncement') }} />
                </div>
            </div>
            <div className="leaveListContainerOuter">
                {/* <div className="filterBar">
                    <div className="filterLeft">
                        <div className="searchBox">
                            <img src="/assets/icons/search-sm.svg" alt="" />
                            <input type="text" className="searchInput" placeholder='Search ...' />
                        </div>

                    </div>
                    <div className='filterBarRight'>

                        <button className="fiterBtn" onClick={() => setOpenFilters(!openFilters)}> <img src="/assets/icons/filter-lines.svg" alt="" /> Filters</button>
                    </div>
                </div> */}
                <div className="leaveListContainer">
                    <table className="annoucementTable" cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th>Announcements type</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {announcementData.map((num, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td>{num.announcementType}</td>
                                    <td className='anouncementDesc'><span dangerouslySetInnerHTML={{ __html: removeTags(num.announcementDescription) }}></span></td>
                                    <td>{moment(num.announcementDate).format("DD-MM-YYYY")}</td>
                                    <td  ><PrimaryButton classname={'secondaryBtnSmall'} children={'View'} onClick={() => { setView(true); setPopupData(num) }} /> </td>
                                </tr>
                            </tbody>

                        ))}
                    </table>
                </div>
            </div>
            <div className={`filterMenuOuter ${openFilters ? 'active' : ''}`}>
                <div className="filterMenuContainer">
                    <div className="filterMenuHeader">
                        <p className="heading">Filter by</p>
                        <img src="/assets/icons/x-close.svg" alt="" className='pointer' onClick={() => setOpenFilters(false)} />
                    </div>
                    <div className="filterBox">
                        <p className="heading">Program</p>
                        <div className="radioGrpFlex">
                            <div className="radioBtn">
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">MasterCamps</label>
                            </div>
                            <div className="radioBtn">
                                <input type="radio" id="test4" name="radio-group" />
                                <label for="test4">PGP TBM</label>
                            </div>
                        </div>
                    </div>
                    <div className="filterMenuFooter">
                        <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Reset'} />
                        <PrimaryButton classname={'primaryBtn'} children={'Show Result'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Announcement


