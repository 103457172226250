import React, { useState,useEffect } from 'react'
import BreadCrumbs from '../../components/globalComponents/breadCrumbs/BreadCrumbs'
import PrimaryButton from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getAllCourseAdmin,courseSliceData } from '../../reducers/courseSlice'
import PageLoader from '../../components/PageLoader/PageLoader'

const CourseAssignmentList = () => {
   
    const [openFilters, setOpenFilters] = useState(false)
    const { getAllCourseData,isFetching } = useSelector(courseSliceData);
    const params=useParams()
  const [searchParams] = useSearchParams();
  const assignment = searchParams.get("assignment") ?? "";

const dispatch = useDispatch()

console.log(getAllCourseData);  
const navigate =useNavigate()

useEffect(() => {
    let obj = {
      proId: params?.programId,
      proMasterId: params?.proMasterId,
    };
    dispatch(getAllCourseAdmin(obj));
    // eslint-disable-next-line
  }, []);
// const handleView =(id,programId,masterId)=>{
//   if(params?.name!=="grade") navigate(`/assignment/${programId}/${masterId}/${id}?assignment=${assignment==="assignment"?"assignment":"group"}`,{exact:true}) 
//    else{
//     navigate(`/gradesList/${id}/${programId}/${masterId}`,{exact:true})
//    }

// }
 const handleView =(id)=>{
    if(params?.name!=="grade") navigate(`/allSessions/${params?.programId}/${params?.proMasterId}/${id}/copy?assignment=${assignment==="assignment"?"assignment":"group"}`)
    else navigate(`/allSessions/${params?.programId}/${params?.proMasterId}/${id}/grade`)
 }


const layer = [`${params?.name!=="grade"?assignment==="assignment"?"Assignment":"Group Assignment":"Grade"}`,`${getAllCourseData[0]?.Program?.programName ?? ""}`]
    return (
        <>{isFetching && <PageLoader />}
        
            <div className="pageHeader">
                <div>
                    <BreadCrumbs layers={layer} />
                </div>
                <div></div>
                <div></div>
            </div>
            {/* <div className="filterBar">
                <div className="filterLeft">
                    <div className="searchBox">
                        <img src="/assets/icons/search-sm.svg" alt="" />
                        <input type="text" className="searchInput" placeholder='Search ...' />
                    </div>

                </div>
                <div className='filterBarRight'>

                    <button className="fiterBtn" onClick={() => setOpenFilters(!openFilters)}> <img src="/assets/icons/filter-lines.svg" alt="" /> Filters</button>
                </div>
            </div> */}
            <div className="assigmentListOuter">

                <div className="assignmentListTableOuter">
                    <table className="assignmentListTable" cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>

                                <th>Course Name</th>
                                <th>Terms</th>
                                <th>No. of Hours</th>
                                <th>No. of Sessions</th>
                                <th>View Sessions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                       getAllCourseData && getAllCourseData.length>0 &&  getAllCourseData.map((item,index)=>( <tr key={index}>
                                <td onClick={()=>handleView(item.id)}>{item.courseName}</td>
                                <td>{item.term}</td>
                                <td>{item.hours}</td>
                                <td>{item.CourseSessions?.length}</td>
                                <td><PrimaryButton classname={'secondaryBtnSmall'} children={`${params?.name!=="grade"?"View":"View Students"}`} onClick={()=>handleView(item.id)}/></td>
                            </tr> )) }
                        </tbody>
                    </table>
                </div>

            </div>
            <div className={`filterMenuOuter ${openFilters ? 'active' : ''}`}>
                <div className="filterMenuContainer">
                    <div className="filterMenuHeader">
                        <p className="heading">Filter by</p>
                        <img src="/assets/icons/x-close.svg" alt="" className='pointer' onClick={() => setOpenFilters(false)} />
                    </div>
                    <div className="filterBox">
                        <p className="heading">Program</p>
                        <div className="radioGrpFlex">
                            <div className="radioBtn">
                                <input type="radio" id="test3" name="radio-group" />
                                <label for="test3">MasterCamps</label>
                            </div>
                            <div className="radioBtn">
                                <input type="radio" id="test4" name="radio-group" />
                                <label for="test4">PGP TBM</label>
                            </div>
                        </div>
                    </div>
                    <div className="filterMenuFooter">
                        <PrimaryButton classname={'darkSecondaryOutlineBtn'} children={'Reset'} />
                        <PrimaryButton classname={'primaryBtn'} children={'Show Result'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseAssignmentList