import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
// import Checkbox from "../../../components/globalComponents/checkboxes/Checkbox";
import { getAllCourseAdmin, courseSliceData } from '../../../reducers/courseSlice'
import {
  feedbackData,
  getAllBatchByProgramId,
  getAllProgramsDetails,
  getAllStudent,
} from "../../../reducers/feedbackSlice";


const AddFeedbackStudents = ({
  // isTotalSelected,
  setTotalSelected,
  setIsChecked,
  isChecked,
  setProgramName,
  programName,
  batchName,
  setBatchName,
  setCourseName,
  feedbackType
}) => {
  // const [openFilters, setOpenFilters] = useState(false);
  const dispatch = useDispatch();
  const { getAllCourseData } = useSelector(courseSliceData);
  const {
    // isFetching,
    // isSuccess,
    // isError,
    // errorMessage,
    // feedbackList,
    // feedbackResponse,
    studentList,
    batchDetail,
    programDetails,
    // feedbackDetail,
    totalStudentsCount,
    // createdSuccess,
  } = useSelector(feedbackData);

  const [pageSize, setPageSize] = useState("10");
  const [searchTitle, setSearchTitle] = useState("");
  const [pageNo, setPageNo] = useState("1");

  const [programs, setPrograms] = useState([]);
  const [batch, setBatch] = useState([]);
  const [course, setCourse] = useState([]);
  const [defaultProgramName, setDefaultProgramName] = useState('');
  const [defaultBatchName, setDefaultBatchName] = useState('');

  const [studentCount, setStudentCount] = useState();
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    dispatch(getAllProgramsDetails());
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    var params = {
      searchTitle: searchTitle,
      programName: programName,
      batchName: batchName,
      pageSize: pageSize,
      pageNo: pageNo,

    };

    dispatch(getAllStudent(params));
    // eslint-disable-next-line
  }, [programName, batchName, pageSize, pageNo]);

  useEffect(() => {
    setPrograms(programDetails);
    programDetails.map(prog => {
      if (prog.id === programName) {
        dispatch(getAllBatchByProgramId(prog.id));
        setDefaultProgramName(prog.id)
        return null
      }
      return null
    })
    // eslint-disable-next-line
  }, [programDetails]);

  useEffect(() => {
    setBatch(batchDetail);
    batchDetail.map(batch => {
      if (batch.id === batchName) {
        setDefaultBatchName(batch.id)
        return null
      }
      return null
    })
    // eslint-disable-next-line
  }, [batchDetail]);

  useEffect(() => {
    setStudentCount(totalStudentsCount);
  }, [totalStudentsCount]);

  useEffect(() => {
    checkAllChecked();
    // eslint-disable-next-line
  }, [studentList]);

  useEffect(() => {
    setCourse(getAllCourseData)
  }, [getAllCourseData])

  useEffect(() => {
    setPageNo("1");
  }, [pageSize]);

  const checkAllChecked = () => {
    let stdIds = studentList.map((item) => item.id);
    let isAll = stdIds.every((val) => isChecked.includes(val));
    if (!isAll) {
      setTotalSelected(false);
    }
    setAllChecked(isAll);
    console.log(studentCount);
  };

  // const selectTotalSelected = () => {
  //   setTotalSelected((prev) => !prev);
  // };

  // const clearSelected = () => {
  //   setTotalSelected((prev) => !prev);
  //   setIsChecked([]);
  //   setAllChecked(false);
  // };

  const onProgramChange = (event) => {

    let programId = event.target.value;
    setProgramName(programId);
    if (programId === "") {
      setBatchName("");
    }
    dispatch(getAllBatchByProgramId(programId));

    setIsChecked([]);
    setBatchName("");
    setPageNo("1");
  };
  const onBatchChange = (e) => {


    let batchId = e?.target?.value ?? "";
    setBatchName(batchId);
    setIsChecked([]);
    setPageNo("1");
    if (feedbackType === "Quiz") {
      let obj = {
        proId: batchId,
        proMasterId: programName,
      };
      dispatch(getAllCourseAdmin(obj))
    }
  };

  const onCourseChange = (e) => {
    let courseId = e.target.value;
    setCourseName(courseId);
    setIsChecked([]);
    setPageNo("1");
  };

  const onPageSizeChange = (e) => {
    let size = e.target.value;
    setPageSize(size);
  };

  const handleSingleCheck = (e) => {
    const id = e.target.id;
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((checked_id) => checked_id !== id));
      setTotalSelected(false);
      return setAllChecked(false);
    }
    isChecked.push(id);
    setIsChecked([...isChecked]);
    checkAllChecked();
  };

  const handleAllCheck = (e) => {
    let currStdIds = studentList.map((data) => data.id);
    const currSet = new Set(currStdIds);
    if (allChecked) {
      setTotalSelected(false);
      setAllChecked(false);
      let newArr = isChecked.filter((val) => !currSet.has(val));
      return setIsChecked(newArr);
    }
    setAllChecked(true);
    let newIsChecked = [...isChecked];
    currStdIds.map((val) => {
      if (!newIsChecked.includes(val)) {
        newIsChecked.push(val);
        return null
      }
      return null
    });
    return setIsChecked(newIsChecked);
  };


  const setNextPage = () => {
    if (pageNo === Math.ceil(totalStudentsCount / pageSize)) {
      return;
    }
    setPageNo((prev) => ++prev);
  };

  const setPreviousPage = () => {
    if (pageNo === "1") {
      return;
    }
    setPageNo((prev) => --prev);
  };

  return (
    <div className="addFeedbackStudentContainerOuter">
      <div className="filterBar">
        <div style={{ display: 'flex', alignItems: 'center' }} className="filterLeft">
          <div className="searchBox">
            <img src="/assets/icons/search-sm.svg" alt="" />
            <input
              onChange={e => setSearchTitle(e.target.value)}
              value={searchTitle}
              type="text"
              className="searchInput"
              placeholder="Search ..."
            />
          </div>
          <label>Selected : {isChecked?.length}</label>
        </div>
        <div className="filterBarRight">
          <select className="searchBox" id="type" onChange={onProgramChange}>
            {/* {defaultProgramName &&
            <option>{defaultProgramName ? defaultProgramName : "Program Name"}</option> 
            } */}

            <option value="">Program Name</option>
            {programs?.length > 0 &&
              programs.map((program, index) => (
                <option selected={program.id === defaultProgramName ? true : false} key={index} value={program.id}>
                  {program.programName}{" "}
                </option>
              ))}
          </select>
          {batch && batch?.length > 0 && (
            <select className="searchBox" id="type" onChange={onBatchChange}>

              <option value="">Batch</option>
              {batch.map((bth, idx) => (
                <option selected={bth.id === defaultBatchName ? true : false} key={idx} value={bth.id}>
                  {bth.batch}
                </option>
              ))}
            </select>
          )}
          {course.length > 0 && feedbackType === "Quiz" && (
            <select className="searchBox" id="type" onChange={onCourseChange}>
              <option value="">course</option>
              {course.map((bth, idx) => (
                <option key={idx} value={bth.id}>
                  {bth.courseName}
                </option>
              ))}
            </select>
          )}
          <select className="searchBox" id="type" onChange={onPageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>

          {/* <button
            className="fiterBtn"
            onClick={() => setOpenFilters(!openFilters)}
          >
            {" "}
            <img src="/assets/icons/filter-lines.svg" alt="" /> Filters
          </button> */}
        </div>
      </div>

      {/* {allChecked && (
        <div className="filter-bar">
          {!isTotalSelected && (
            <div className="selection-bar">
              <span>
                All {isChecked?.length} Students in this page are selected
              </span>
              <button className="clear-btn" onClick={selectTotalSelected}>
                Select All {studentCount} Students
              </button>
            </div>
          )}
          {isTotalSelected && (
            <div className="selection-bar">
              <span>All {studentCount} Students are selected</span>
              <button className="clear-btn" onClick={clearSelected}>
                Clear Selected Students
              </button>
            </div>
          )}
        </div>
      )} */}
      <div className="addFeedbackTableOuter">
        <table className="addFeedbackTable" cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="allCheck"
                    id="allCheck"
                    className="checkmark-btn"
                    checked={allChecked}
                    onChange={handleAllCheck}
                  />
                  <span className="checkmarkspan"></span>
                </label>
              </th>
              <th>Students</th>
              <th>Course</th>
              <th>Cohort</th>
            </tr>
          </thead>
          <tbody>
            {studentList?.length > 0 &&
              studentList.map((item, index) => (
                <tr key={item.id} className="p-20">
                  <td>
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        name={item.name}
                        id={item.id}
                        className="checkmark-btn"
                        checked={isChecked.includes(item.id)}
                        value={item.id}
                        onChange={handleSingleCheck}
                      />
                      <span className="checkmarkspan" />
                    </label>
                  </td>
                  <td>
                    {item.firstName} {item.lastName}
                  </td>
                  <td>{item.ProgramDetail?.Program?.programName}</td>
                  <td>{item.ProgramDetail?.batch}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="footer pg-arrows-outer pd-add">
        <p className="pg-arraows-p">
          {pageNo} of{" "}
          {totalStudentsCount <= pageSize
            ? "1"
            : Math.ceil(totalStudentsCount / pageSize)}
        </p>
        <div className="pg-arrows">
          <a href onClick={setPreviousPage}>
            {" "}
            <img
              src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
              className="pg-left"
              alt=""
            />
          </a>
          <a href onClick={setNextPage}>
            {" "}
            <img
              src="https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/caretDown.svg"
              className="pg-right"
              alt=""
            />
          </a>
        </div>
      </div>

      {/* <div className={`filterMenuOuter ${openFilters ? "active" : ""}`}>
        <div className="filterMenuContainer">
          <div className="filterMenuHeader">
            <p className="heading">Filter by</p>
            <img
              src="/assets/icons/x-close.svg"
              alt=""
              className="pointer"
              onClick={() => setOpenFilters(false)}
            />
          </div>
          <div className="filterBox">
            <p className="heading">Program</p>
            <div className="radioGrpFlex">
              <div className="radioBtn">
                <input type="radio" id="test3" name="radio-group" />
                <label for="test3">MasterCamps</label>
              </div>
              <div className="radioBtn">
                <input type="radio" id="test4" name="radio-group" />
                <label for="test4">PGP TBM</label>
              </div>
            </div>
          </div>
          <div className="filterMenuFooter">
            <PrimaryButton
              classname={"darkSecondaryOutlineBtn"}
              children={"Reset"}
            />
            <PrimaryButton classname={"primaryBtn"} children={"Show Result"} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AddFeedbackStudents;
