import React from 'react';
// import ReactDom from 'react-dom';
import TimePicker from 'rc-time-picker-date-fns';
import 'rc-time-picker-date-fns/assets/index.css';


const CustomTimePicker = () => {

  const format = 'h:mm a';
  const now = new Date();

  // const [value, onChange] = useState('10:00');

  const onChange =()=>{

  }
  // console.log(value)
  return (
    <TimePicker
    showSecond={false}
    defaultValue={now}
    className="xxx"
    onChange={onChange}
    format={format}
    use12Hours
  />
  )
}

export default CustomTimePicker