import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
    orgId = localStorage.getItem("orgId");
    token = localStorage.getItem("token");
    adminId = localStorage.getItem("adminId");
};

export const getAllTickets = createAsyncThunk("admin/getAllTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/adminAllEnqueriesDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllSolvedTickets = createAsyncThunk("admin/getAllSolvedTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/adminAllSolvedEnqueriesDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllNewTickets = createAsyncThunk("admin/getAllNewTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/adminNewRequestEnqueriesDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAllOpenTickets = createAsyncThunk("admin/getAllOpenTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/adminAllOpenEnqueriesDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getAdminPendingTickets = createAsyncThunk("admin/getAdminPendingTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/getAllPendingEnqueries`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);
export const getAdminInProgressTickets = createAsyncThunk("admin/getAdminInProgressTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/inProgressEnqueries`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);
export const getAdminSolvedTickets = createAsyncThunk("admin/getAdminSolvedTickets", async (thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/solvedEnqueries`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);

export const getTicketDetails = createAsyncThunk("admin/getTicketDetails", async ({ studentId, ticketId }, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${studentId}/enquiry/${ticketId}/getSpecificEnquiryDetails`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

export const getUserResponse = createAsyncThunk("admin/getUserResponse", async (id, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/enquiry/${id}/getUserResponse`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

export const getAssignList = createAsyncThunk("admin/getAssignList", async (id,thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${id}/getCategoryWiseAdmins`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const addAdminChat = createAsyncThunk('admin/sendMessage', async (obj, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(
            environment.baseURL +
            "/api/org/" +
            orgId +
            "/admin/" +
            adminId +
            "/userResponse",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(obj),
            }
        );
        let data = await response.json();
        console.log("response", data);
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
});

export const changeTicketStatus = createAsyncThunk('admin/changeTicketStatus', async (raw, thunkAPI) => {
    try {
        setTokenValues();
        const response = await fetch(
            environment.baseURL +
            "/api/org/" +
            orgId +
            "/admin/" +
            adminId +
            "/" +
            raw.studentId +
            "/enquiry/" +
            raw.ticketId +
            "/updateEnquiryByStudent",
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(raw.obj),
            }
        );
        let data = await response.json();
        console.log("response", data);
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
})

export const adminSlice = createSlice({
    name: "adminData",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        AllTickets: [],
        AllSolvedTickets: [],
        AllNewTickets: [],
        AllOpenTickets: [],
        AdminPendingTickets: [],
        AdminSolvedTickets: [],
        AdminInProgressTickets: [],
        TicketDetails: [],
        TicketChat: [],
        UserList: []
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getAllTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.AllTickets = payload;
            return state;
        },
        [getAllTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllSolvedTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllSolvedTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.AllSolvedTickets = payload;
            return state;
        },
        [getAllSolvedTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllNewTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllNewTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            console.log(payload);
            state.AllNewTickets = payload;
            return state;
        },
        [getAllNewTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAllOpenTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAllOpenTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.AllOpenTickets = payload;
            return state;
        },
        [getAllOpenTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAdminPendingTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAdminPendingTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.AdminPendingTickets = payload;
            return state;
        },
        [getAdminPendingTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAdminInProgressTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAdminInProgressTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.AdminInProgressTickets = payload;
            return state;
        },
        [getAdminInProgressTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAdminSolvedTickets.pending]: (state) => {
            state.isFetching = true;
        },
        [getAdminSolvedTickets.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.AdminSolvedTickets = payload;
            return state;
        },
        [getAdminSolvedTickets.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getTicketDetails.pending]: (state) => {
            state.isFetching = true;
        },
        [getTicketDetails.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.TicketDetails = payload;
            return state;
        },
        [getTicketDetails.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getUserResponse.pending]: (state) => {
            state.isFetching = true;
        },
        [getUserResponse.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.TicketChat = payload;
            return state;
        },
        [getUserResponse.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getAssignList.pending]: (state) => {
            state.isFetching = true;
        },
        [getAssignList.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.UserList = payload;
            return state;
        },
        [getAssignList.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addAdminChat.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addAdminChat.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [addAdminChat.pending]: (state) => {
            state.isFetching = true;
        },
        [changeTicketStatus.fulfilled]: (state) => {
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [changeTicketStatus.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [changeTicketStatus.pending]: (state) => {
            state.isFetching = true;
        }
    }
})

export const { clearState } = adminSlice.actions;

export const adminSliceData = (state) => state.adminData;