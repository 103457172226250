import React from "react";
import { useState } from "react";
// import Dropdown from "../../../components/globalComponents/dropdown/Dropdown";
import TextArea from "../../../components/globalComponents/textArea/TextArea";
// import Checkbox from "../../../components/globalComponents/checkboxes/Checkbox";
import PrimaryButton from "../../../components/globalComponents/buttons/primaryButton/PrimaryButton";
// import CustomUploadBox from "../../../components/globalComponents/customUploadBox/CustomUploadBox";
import UploadBox from "../../../components/UploadBox/UploadBox";
import EditQuestion from "./EditQuestion";
import FeedbackQuestionMenu from "./FeedbackQuestionMenu";
import Rating from "../../../components/globalComponents/rating/Rating";
// import Radio from "../../../components/globalComponents/radios/Radio";
import MultiSelect from "../../../components/multiSelect/MultiSelect";
import SingleSelect from "../../../components/singleSelect/SingleSelect";
import { useSearchParams } from "react-router-dom";

const optionsList = [
  "Rating",
  "Paragraph",
  "Multi Select",
  "Single Select",
  "Upload Media",
];

const FeedbackBuilder = ({
  feedbackDetails,
  setNewQuestion,
  questionArray,
}) => {
  // const selected = (arr) => { };
  const [questionType, setQuestionType] = useState("");
  const [questionTypeDropdown, setQuestionTypeDropdown] = useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [questionDetail, setQuestionDetail] = useState([]);
  const [editCheck, setEditCheck] = useState(false);
  const [ searchParams ] = useSearchParams();
  const type = searchParams.get("type");

  const onClickQuestionType = (type) => {
    setQuestionType(type);
    setEditCheck(false);
    setIsEditQuestion(true);
  };

  const closeEditQuestion = () => {
    setIsEditQuestion(false);
  };


  const onClickEditQuestion = (type, id) => {
    setQuestionType(type);


    var newArray = questionArray.filter(function (el) {
      return el.id === id;
    });
    setQuestionDetail(newArray);
    setEditCheck(true);
    setIsEditQuestion(true);
  };

  return (
    <div className="feedbackBuilderContainerOuter">
      <div className="questionMenuOuter">
        <div className={`allQuestionMenu ${!isEditQuestion ? "active" : ""}`}>
          {/* <div className="layoutSelector">
            <p className="heading">Page Layout</p>
            <div className="selector">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.47325 1.875H13.5268C14.2041 1.87499 14.7505 1.87499 15.1929 1.91114C15.6484 1.94835 16.0485 2.02699 16.4187 2.21561C17.0067 2.51521 17.4848 2.99327 17.7844 3.58128C17.973 3.95146 18.0516 4.35158 18.0889 4.80711C18.125 5.24953 18.125 5.79587 18.125 6.47323V13.5268C18.125 14.2041 18.125 14.7505 18.0889 15.1929C18.0516 15.6484 17.973 16.0485 17.7844 16.4187C17.4848 17.0067 17.0067 17.4848 16.4187 17.7844C16.0485 17.973 15.6484 18.0516 15.1929 18.0889C14.7505 18.125 14.2041 18.125 13.5268 18.125H6.47323C5.79587 18.125 5.24953 18.125 4.80711 18.0889C4.35158 18.0516 3.95146 17.973 3.58128 17.7844C2.99327 17.4848 2.51521 17.0067 2.21561 16.4187C2.02699 16.0485 1.94835 15.6484 1.91114 15.1929C1.87499 14.7505 1.87499 14.2041 1.875 13.5268V6.47325C1.87499 5.79588 1.87499 5.24953 1.91114 4.80711C1.94835 4.35158 2.02699 3.95146 2.2156 3.58128C2.51521 2.99327 2.99327 2.51521 3.58128 2.2156C3.95146 2.02699 4.35158 1.94835 4.80711 1.91114C5.24953 1.87499 5.79588 1.87499 6.47325 1.875ZM3.125 8.125V13.5C3.125 14.2104 3.12549 14.7056 3.15699 15.0911C3.18789 15.4693 3.2455 15.6866 3.32936 15.8512C3.50913 16.204 3.79596 16.4909 4.14877 16.6706C4.31337 16.7545 4.53067 16.8121 4.9089 16.843C5.29443 16.8745 5.78962 16.875 6.5 16.875H13.5C14.2104 16.875 14.7056 16.8745 15.0911 16.843C15.4693 16.8121 15.6866 16.7545 15.8512 16.6706C16.204 16.4909 16.4909 16.204 16.6706 15.8512C16.7545 15.6866 16.8121 15.4693 16.843 15.0911C16.8745 14.7056 16.875 14.2104 16.875 13.5V8.125H3.125ZM16.875 6.875H3.125V6.5C3.125 5.78962 3.12549 5.29443 3.15699 4.9089C3.18789 4.53067 3.2455 4.31337 3.32936 4.14877C3.50913 3.79596 3.79596 3.50913 4.14877 3.32936C4.31337 3.2455 4.53067 3.18789 4.9089 3.15699C5.29443 3.12549 5.78962 3.125 6.5 3.125H13.5C14.2104 3.125 14.7056 3.12549 15.0911 3.15699C15.4693 3.18789 15.6866 3.2455 15.8512 3.32936C16.204 3.50913 16.4909 3.79596 16.6706 4.14877C16.7545 4.31337 16.8121 4.53067 16.843 4.9089C16.8745 5.29443 16.875 5.78962 16.875 6.5V6.875Z"
                  fill="black"
                />
              </svg>

              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4998 18.1667H6.49984C4.99984 18.1667 4.24984 18.1667 3.58317 17.8333C2.99984 17.5 2.49984 17 2.24984 16.4167C1.9165 15.75 1.9165 15 1.9165 13.5V6.5C1.9165 5 1.9165 4.25 2.24984 3.58333C2.49984 3 2.99984 2.5 3.58317 2.25C4.24984 1.83333 4.99984 1.83333 6.49984 1.83333H13.4998C14.9998 1.83333 15.7498 1.83333 16.4165 2.16667C16.9998 2.5 17.4998 3 17.7498 3.58333C18.0832 4.25 18.0832 5 18.0832 6.5V13.5C18.0832 15 18.0832 15.75 17.7498 16.4167C17.4165 17 16.9998 17.5 16.4165 17.75C15.7498 18.1667 14.9998 18.1667 13.4998 18.1667ZM3.1665 8.16667V13.5833C3.1665 14.9167 3.1665 15.5 3.33317 15.9167C3.49984 16.25 3.83317 16.5833 4.1665 16.75C4.58317 16.9167 5.24984 16.9167 6.49984 16.9167H13.4998C14.8332 16.9167 15.4165 16.9167 15.8332 16.75C16.1665 16.5833 16.4998 16.25 16.6665 15.9167C16.8332 15.5 16.8332 14.8333 16.8332 13.5833V8.16667H3.1665ZM3.1665 6.83333H16.9165V6.5C16.9165 5.16667 16.9165 4.58333 16.7498 4.16667C16.5832 3.83333 16.2498 3.5 15.9165 3.33333C15.4998 3.16667 14.8332 3.16667 13.5832 3.16667H6.49984C5.1665 3.16667 4.58317 3.16667 4.1665 3.33333C3.83317 3.5 3.49984 3.83333 3.33317 4.16667C3.1665 4.58333 3.1665 5.16667 3.1665 6.5V6.83333ZM14.5832 14.8333H5.4165C5.08317 14.8333 4.74984 14.5833 4.74984 14.1667C4.74984 13.75 4.99984 13.5 5.4165 13.5H14.5832C14.9165 13.5 15.2498 13.75 15.2498 14.1667C15.2498 14.5833 14.9165 14.8333 14.5832 14.8333ZM14.5832 11.5H5.4165C5.08317 11.5 4.74984 11.25 4.74984 10.8333C4.74984 10.4167 4.99984 10.1667 5.4165 10.1667H14.5832C14.9165 10.1667 15.2498 10.4167 15.2498 10.8333C15.2498 11.25 14.9165 11.5 14.5832 11.5Z"
                  fill="black"
                />
              </svg>
            </div>
          </div> */}
          <div className="homePage">
            <p className="heading">Questions Page</p>
          </div>
          <div className="questionList">
            <FeedbackQuestionMenu
              setQuestionDetail={setQuestionDetail}
              setNewQuestion={setNewQuestion}
              questionArray={questionArray}
              onClickEditQuestion={onClickEditQuestion}
            />
          </div>
          <div
            className="addQuestion"
            onClick={() => {type !== "Quiz" ? setQuestionTypeDropdown(!questionTypeDropdown) : onClickQuestionType("Single Select")}}
            onBlur={() => setQuestionTypeDropdown(false)}
          >
            <img src="/assets/icons/plus-circle.svg" alt="" />
            <p className="dangerText">Add Question</p>
            <div
              className={`addQuestionMenu ${questionTypeDropdown ? "active" : ""
                }`}
            >
              {type !== "Quiz" && (
                <>
                  <div
                    onClick={() => onClickQuestionType("Rating")}
                    className="addQuestionMenuItem"
                  >
                    <img src="/assets/icons/star-01.svg" alt="" />
                    Rating
                  </div>
                  <div
                    onClick={() => onClickQuestionType("Paragraph")}
                    className="addQuestionMenuItem"
                  >
                    <img src="/assets/icons/align-left.svg" alt="" />
                    Paragraph
                  </div>
                  <div
                    onClick={() => onClickQuestionType("Multi Select")}
                    className="addQuestionMenuItem"
                  >
                    <img src="/assets/icons/check-square-black.svg" alt="" />
                    Multi Select
                  </div>
                  <div
                    onClick={() => onClickQuestionType("Upload Media")}
                    className="addQuestionMenuItem"
                  >
                    <img src="/assets/icons/star-01.svg" alt="" />
                    Upload Media
                  </div>
                </>
              )}

              <div
                onClick={() => onClickQuestionType("Single Select")}
                className="addQuestionMenuItem"
              >
                <img src="/assets/icons/radio-filled.svg" alt="" />
                Single Select
              </div>
            </div>
          </div>
        </div>

        <EditQuestion
          isEditQuestion={isEditQuestion}
          questionType={questionType}
          optionsList={optionsList}
          setQuestionType={setQuestionType}
          closeEditQuestion={closeEditQuestion}
          setNewQuestion={setNewQuestion}
          questionArray={questionArray}
          setQuestionDetail={setQuestionDetail}
          questionDetail={questionDetail}
          feedbackType={feedbackDetails?.feedbackType}
          editCheck={editCheck}
        />
      </div>
      <div className="feedbackSlidePreview">
        <div className="feedbackSlide">
          <div className="feedbackSlideContent cardlayout">
            <h1 className="feedbackTitle">{feedbackDetails?.title}</h1>
            <p className="subContent">{feedbackDetails?.description}</p>
            <PrimaryButton classname={"dangerBtn"} children={"Get Started"} />
          </div>
        </div>
        {questionArray.map((item, index) => {
          const {
            // id,
            questionType,
            questionName,
            // answers,
            // title,
            // desc,
            // isNotMandatory,
          } = item;

          return (
            <div className="feedbackSlide">
              <div className="feedbackSlideContent cardlayout">
                <h1 className="feedbackTitle">{questionName}</h1>
                {questionType === "Multi Select" && (
                  <div className="feedbackCheckboxOuter">
                    <MultiSelect item={item} />
                  </div>
                )}

                {questionType === "Single Select" && (
                  <div className="radioFeedbackWrapper">
                    <SingleSelect item={item} />
                  </div>
                )}
                {questionType === "Upload Media" && (
                  <div className="feedbackCheckboxOuter">
                    <UploadBox value={{ answer: "aj" }} item={{ id: "" }} />
                  </div>
                )}
                {questionType === "Paragraph" && (
                  <TextArea placeholder={"Enter your Answer"} />
                )}
                {questionType === "Rating" && (
                  <div className="ratingWrapper">
                    <Rating />
                  </div>
                )}

                <PrimaryButton classname={"dangerBtn"} children={"Continue"} />
              </div>
            </div>
          );
        })}

        {/*         
        <div className="feedbackSlide">
          <div className="feedbackSlideContent cardlayout">
            <h1 className="feedbackTitle">
              Managerial Strategy - Session 02 - 12th Jan 220 - Mr. Thomas
              Kuruvilla
            </h1>
            <TextArea placeholder={"Enter your Answer"} />
            <PrimaryButton classname={"dangerBtn"} children={"Continue"} />
          </div>
        </div>
        <div className="feedbackSlide">
          <div className="feedbackSlideContent cardlayout">
            <h1 className="feedbackTitle">
              Managerial Strategy - Session 02 - 12th Jan 220 - Mr. Thomas
              Kuruvilla
            </h1>
            <div className="feedbackCheckboxOuter">
              <UploadBox value={{ answer: "aj" }} item={{ id: "" }} />
            </div>
            <PrimaryButton classname={"dangerBtn"} children={"Continue"} />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FeedbackBuilder;
