import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from '../environment/environment'

let orgId;
let token;
let adminId;


let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

export const createAnnouncement = createAsyncThunk(
  "announcement/createAnnouncement",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/addAnnouncements",

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Content-length":"1024211",
            Authorization: token,
          },
          body: JSON.stringify(obj)
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {

        return data.Data;
      } else {

        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {

      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "announcement/getAnnouncement",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      console.log(orgId)
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId + 
        "/admin/" +
        adminId +
        "/getAllAnnouncements",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          }
        });
      let data = await response.json();
      console.log(data)

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState: {
    announcementData: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    }
  },
  extraReducers: {
    [createAnnouncement.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      return state;
    },
    [createAnnouncement.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createAnnouncement.pending]: (state) => {
      state.isFetching = true;
    },
    [getAnnouncement.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.announcementData = payload

      return state;
    },
    [getAnnouncement.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAnnouncement.pending]: (state) => {
      state.isFetching = true;
    },

  }
})


export const { clearState } = announcementSlice.actions;

export const announcementSliceFunction = (state) => state.announcement;