import React from 'react'
import './MeetingTimePopupStyle.css'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'

const MeetingTimePopup = () => {
    return (
        <div className="popupOuter">
            <div className="meetingTimePopupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">Session Time: 1:30 seconds</p>
                    <PrimaryButton classname={'dangerOutlineBtnSmall'} children={'SOS'} />
                </div>
                <div className="popupBody">
                    <div className="meetingLinkOuter">
                        <img src="/assets/img/gmeetlogo.png" alt="" />
                        <div className="meetingLink">
                            <p>To join the video meeting, click this link:</p>
                            <a href='https://meet.google.com/yzt-frwq-xgq'>https://meet.google.com/yzt-frwq-xgq</a>
                        </div>
                    </div>
                </div>
                <div className="popupFooter flexEnd">
                    <PrimaryButton classname={'dangerBtn'} children={'End Session'} />
                </div>
            </div>
        </div>
    )
}

export default MeetingTimePopup