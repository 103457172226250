import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../environment/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
    orgId = localStorage.getItem("orgId");
    token = localStorage.getItem("token");
    adminId = localStorage.getItem("adminId");
};

export const getAllStudentsCourseMarks = createAsyncThunk("grade/getAllStudentsCourseMarks", async ({sessionId,programId,courseId},thunkAPI) => {
    try {
        setTokenValues();

        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/session/${sessionId}/course/${courseId}/${programId}/getAllStudentsCourseMarks`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data.Data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);        

export const getSpecificStudentAssignmentGrades = createAsyncThunk("grade/getSpecificStudentAssignmentGrades", async ({courseId,studentId},thunkAPI) => {
    try {
        setTokenValues();
        console.log(courseId)
        

        const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/course/${courseId}/student/${studentId}/getSpecificStudentAssignmentGradesAdmin`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json", 
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        );
        let data = await response.json();
        if (response.status === 200) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
    }
}
);


export const gradeSlice = createSlice({
    name: "grade",
    initialState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
        allCourseMarks:[],
        studentDataList:[],
        studentName:{}
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
    },
    extraReducers: {
        [getAllStudentsCourseMarks.pending]: (state) => {
            
            state.isFetching = true; 
        },
        [getAllStudentsCourseMarks.fulfilled]: (state, { payload }) => {
        
            state.isFetching = false;
            state.allCourseMarks = payload;
            return state;
        },
        [getAllStudentsCourseMarks.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
        [getSpecificStudentAssignmentGrades.pending]: (state) => {
            
            state.isFetching = true; 
        },
        [getSpecificStudentAssignmentGrades.fulfilled]: (state, { payload }) => {
        
            state.isFetching = false;
            state.studentDataList = payload.Data;
              state.studentName=payload.studentData
            return state;
        },
        [getSpecificStudentAssignmentGrades.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload?.Message || "Something Went Wrong";
        },
    }
})


export const { clearState } = gradeSlice.actions;

export const gradeSliceData = (state) => state.grade;

