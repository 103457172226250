import React from 'react'
import "./DashBoardStyle.css"
import Button from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import ProgressBar from '../../components/globalComponents/progressBar/ProgressBar'
// import Buttons from '../../components/globalComponents/buttons/Buttons'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

const localizer = momentLocalizer(moment)

const DashBoard = () => {

  return (
    <>
        <div className='main-wrapper'>

            <div className='dashboard-left'>

                <div className='session-wrapper'>

                    <div className='session-box'>
                        <span className='session-heading'>All Courses</span>
                        <span className='session-count'>07</span>
                        <p className='session-more'>View more 
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.10225 0.852252C1.32192 0.632583 1.67808 0.632583 1.89775 0.852252L5.64775 4.60225C5.86742 4.82192 5.86742 5.17808 5.64775 5.39775L1.89775 9.14775C1.67808 9.36742 1.32192 9.36742 1.10225 9.14775C0.882583 8.92808 0.882583 8.57192 1.10225 8.35225L4.4545 5L1.10225 1.64775C0.882583 1.42808 0.882583 1.07192 1.10225 0.852252ZM6.35225 0.852252C6.57192 0.632583 6.92808 0.632583 7.14775 0.852252L10.8977 4.60225C11.1174 4.82192 11.1174 5.17808 10.8977 5.39775L7.14775 9.14775C6.92808 9.36742 6.57192 9.36742 6.35225 9.14775C6.13258 8.92808 6.13258 8.57192 6.35225 8.35225L9.7045 5L6.35225 1.64775C6.13258 1.42808 6.13258 1.07192 6.35225 0.852252Z" fill="#006CD4"/>
                        </svg>
                        </p>
                    </div>

                    <div className='session-box green-session'>
                        <span className='session-heading'>All Courses</span>
                        <span className='session-count'>07</span>
                        <p className='session-more'>View more 
                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.10225 0.852252C1.32192 0.632583 1.67808 0.632583 1.89775 0.852252L5.64775 4.60225C5.86742 4.82192 5.86742 5.17808 5.64775 5.39775L1.89775 9.14775C1.67808 9.36742 1.32192 9.36742 1.10225 9.14775C0.882583 8.92808 0.882583 8.57192 1.10225 8.35225L4.4545 5L1.10225 1.64775C0.882583 1.42808 0.882583 1.07192 1.10225 0.852252ZM6.35225 0.852252C6.57192 0.632583 6.92808 0.632583 7.14775 0.852252L10.8977 4.60225C11.1174 4.82192 11.1174 5.17808 10.8977 5.39775L7.14775 9.14775C6.92808 9.36742 6.57192 9.36742 6.35225 9.14775C6.13258 8.92808 6.13258 8.57192 6.35225 8.35225L9.7045 5L6.35225 1.64775C6.13258 1.42808 6.13258 1.07192 6.35225 0.852252Z" fill="#1C7C54"/>
                        </svg>
                        </p>
                    </div>

                </div>

                <div className='process-wrapper mt-10'>

                    <div className='process-heading'>
                        <p className='dash-heading-small'>
                        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 1.875C2.84518 1.875 3.125 2.15482 3.125 2.5V16.1667C3.125 16.4103 3.12549 16.5555 3.13428 16.6631C3.13965 16.7288 3.14673 16.7564 3.14902 16.7638C3.16862 16.801 3.19902 16.8314 3.23622 16.851C3.24361 16.8533 3.27122 16.8604 3.3369 16.8657C3.4445 16.8745 3.58967 16.875 3.83334 16.875H17.5C17.8452 16.875 18.125 17.1548 18.125 17.5C18.125 17.8452 17.8452 18.125 17.5 18.125H3.81148C3.59691 18.125 3.39984 18.125 3.23511 18.1116C3.0575 18.0971 2.86319 18.0638 2.67127 17.9661C2.39686 17.8262 2.17377 17.6031 2.03395 17.3287C1.93616 17.1368 1.90294 16.9425 1.88843 16.7649C1.87497 16.6002 1.87499 16.4031 1.875 16.1885L1.875 2.5C1.875 2.15482 2.15482 1.875 2.5 1.875ZM3.23406 16.8502C3.23409 16.8502 3.23453 16.8504 3.23531 16.8507L3.23406 16.8502ZM3.14977 16.7659C3.14977 16.766 3.14961 16.7656 3.14932 16.7647L3.14977 16.7659ZM17.094 6.21058C17.3459 6.44658 17.3588 6.8421 17.1228 7.09399L13.857 10.5795C13.849 10.5881 13.8403 10.5975 13.831 10.6076C13.7416 10.7046 13.5943 10.8643 13.4006 10.9529C13.2358 11.0282 13.0543 11.0593 12.8739 11.043C12.6617 11.0239 12.4697 10.9223 12.3531 10.8606C12.341 10.8542 12.3297 10.8482 12.3192 10.8427L9.60476 9.43329C9.59485 9.42815 9.58554 9.42331 9.57674 9.41875C9.56996 9.42598 9.56278 9.43364 9.55515 9.44178L6.28942 12.9273C6.05342 13.1792 5.6579 13.1921 5.40601 12.9561C5.15412 12.7201 5.14124 12.3246 5.37725 12.0727L8.64297 8.58713C8.65101 8.57855 8.65969 8.56913 8.669 8.55904C8.75844 8.46206 8.9057 8.30237 9.09945 8.21378C9.26418 8.13845 9.44571 8.10738 9.62612 8.12363C9.8383 8.14275 10.0303 8.24437 10.1469 8.30609C10.159 8.31251 10.1703 8.3185 10.1808 8.32392L12.8952 9.73338C12.9052 9.73852 12.9145 9.74335 12.9233 9.74792C12.9301 9.74069 12.9372 9.73303 12.9449 9.72489L16.2106 6.23934C16.4466 5.98745 16.8421 5.97457 17.094 6.21058Z" fill="#262626"/>
                        </svg>
                            My Progress
                        </p>
                    </div>

                    <div className='process-body'>
                        <ul className='process-list'>

                            <li>
                                <div className='process-list-main'>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                    </svg>
                                    Fundamentals of Accounting
                                </div>
                                <span className='process-status active'>
                                    View
                                </span>
                            </li>

                            <li>

                                <div className='process-list-main'>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                    </svg>
                                    Fundamentals of Accounting
                                </div>
                                <span className='process-status'>
                                    In Progress
                                </span>

                                <div className='proress-menu'>
                                    <ul>

                                        <li>
                                            <div className='process-menu-list'>
                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                                </svg>
                                                Horizontal Analysis
                                            </div>
                                            <span>
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.05806 0.558058C1.30214 0.313981 1.69786 0.313981 1.94194 0.558058L6.94194 5.55806C7.18602 5.80214 7.18602 6.19786 6.94194 6.44194L1.94194 11.4419C1.69786 11.686 1.30214 11.686 1.05806 11.4419C0.813981 11.1979 0.813981 10.8021 1.05806 10.5581L5.61612 6L1.05806 1.44194C0.813981 1.19786 0.813981 0.802136 1.05806 0.558058Z" fill="#006CD4"/>
                                                </svg>
                                            </span>
                                        </li>

                                        <li>
                                            <div className='process-menu-list'>
                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                                </svg>
                                                Vertical Analysis
                                            </div>
                                            <span>
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.05806 0.558058C1.30214 0.313981 1.69786 0.313981 1.94194 0.558058L6.94194 5.55806C7.18602 5.80214 7.18602 6.19786 6.94194 6.44194L1.94194 11.4419C1.69786 11.686 1.30214 11.686 1.05806 11.4419C0.813981 11.1979 0.813981 10.8021 1.05806 10.5581L5.61612 6L1.05806 1.44194C0.813981 1.19786 0.813981 0.802136 1.05806 0.558058Z" fill="#006CD4"/>
                                                </svg>
                                            </span>
                                        </li>

                                        <li>
                                            <div className='process-menu-list'>
                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                                </svg>
                                                Trend Analysis
                                            </div>
                                            <span>
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.05806 0.558058C1.30214 0.313981 1.69786 0.313981 1.94194 0.558058L6.94194 5.55806C7.18602 5.80214 7.18602 6.19786 6.94194 6.44194L1.94194 11.4419C1.69786 11.686 1.30214 11.686 1.05806 11.4419C0.813981 11.1979 0.813981 10.8021 1.05806 10.5581L5.61612 6L1.05806 1.44194C0.813981 1.19786 0.813981 0.802136 1.05806 0.558058Z" fill="#006CD4"/>
                                                </svg>
                                            </span>
                                        </li>

                                        <li>
                                            <div className='process-menu-list'>
                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                                </svg>
                                                iquidity Analysis
                                            </div>
                                            <span>
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.05806 0.558058C1.30214 0.313981 1.69786 0.313981 1.94194 0.558058L6.94194 5.55806C7.18602 5.80214 7.18602 6.19786 6.94194 6.44194L1.94194 11.4419C1.69786 11.686 1.30214 11.686 1.05806 11.4419C0.813981 11.1979 0.813981 10.8021 1.05806 10.5581L5.61612 6L1.05806 1.44194C0.813981 1.19786 0.813981 0.802136 1.05806 0.558058Z" fill="#006CD4"/>
                                                </svg>
                                            </span>
                                        </li>

                                    </ul>
                                </div>

                            </li>

                            <li>
                                <div className='process-list-main'>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                    </svg>
                                    Fundamentals of Accounting
                                </div>
                                <span className='process-status'>
                                    Pending
                                </span>
                            </li>

                            <li>
                                <div className='process-list-main'>
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM17.0303 8.46967C17.3232 8.76256 17.3232 9.23744 17.0303 9.53033L11.0303 15.5303C10.7374 15.8232 10.2626 15.8232 9.96967 15.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L10.5 13.9393L15.9697 8.46967C16.2626 8.17678 16.7374 8.17678 17.0303 8.46967Z" fill="#006CD4"/>
                                    </svg>
                                    Fundamentals of Accounting
                                </div>
                                <span className='process-status'>
                                    Pending
                                </span>
                            </li>

                        </ul>
                    </div>

                </div>
                
                <div className='assignment-wrapper mt-10'>

                    <div className='process-heading'>
                        <p className='dash-heading-small'>
                        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 1.875C2.84518 1.875 3.125 2.15482 3.125 2.5V16.1667C3.125 16.4103 3.12549 16.5555 3.13428 16.6631C3.13965 16.7288 3.14673 16.7564 3.14902 16.7638C3.16862 16.801 3.19902 16.8314 3.23622 16.851C3.24361 16.8533 3.27122 16.8604 3.3369 16.8657C3.4445 16.8745 3.58967 16.875 3.83334 16.875H17.5C17.8452 16.875 18.125 17.1548 18.125 17.5C18.125 17.8452 17.8452 18.125 17.5 18.125H3.81148C3.59691 18.125 3.39984 18.125 3.23511 18.1116C3.0575 18.0971 2.86319 18.0638 2.67127 17.9661C2.39686 17.8262 2.17377 17.6031 2.03395 17.3287C1.93616 17.1368 1.90294 16.9425 1.88843 16.7649C1.87497 16.6002 1.87499 16.4031 1.875 16.1885L1.875 2.5C1.875 2.15482 2.15482 1.875 2.5 1.875ZM3.23406 16.8502C3.23409 16.8502 3.23453 16.8504 3.23531 16.8507L3.23406 16.8502ZM3.14977 16.7659C3.14977 16.766 3.14961 16.7656 3.14932 16.7647L3.14977 16.7659ZM17.094 6.21058C17.3459 6.44658 17.3588 6.8421 17.1228 7.09399L13.857 10.5795C13.849 10.5881 13.8403 10.5975 13.831 10.6076C13.7416 10.7046 13.5943 10.8643 13.4006 10.9529C13.2358 11.0282 13.0543 11.0593 12.8739 11.043C12.6617 11.0239 12.4697 10.9223 12.3531 10.8606C12.341 10.8542 12.3297 10.8482 12.3192 10.8427L9.60476 9.43329C9.59485 9.42815 9.58554 9.42331 9.57674 9.41875C9.56996 9.42598 9.56278 9.43364 9.55515 9.44178L6.28942 12.9273C6.05342 13.1792 5.6579 13.1921 5.40601 12.9561C5.15412 12.7201 5.14124 12.3246 5.37725 12.0727L8.64297 8.58713C8.65101 8.57855 8.65969 8.56913 8.669 8.55904C8.75844 8.46206 8.9057 8.30237 9.09945 8.21378C9.26418 8.13845 9.44571 8.10738 9.62612 8.12363C9.8383 8.14275 10.0303 8.24437 10.1469 8.30609C10.159 8.31251 10.1703 8.3185 10.1808 8.32392L12.8952 9.73338C12.9052 9.73852 12.9145 9.74335 12.9233 9.74792C12.9301 9.74069 12.9372 9.73303 12.9449 9.72489L16.2106 6.23934C16.4466 5.98745 16.8421 5.97457 17.094 6.21058Z" fill="#262626"/>
                        </svg>
                            My Progress
                        </p>
                    </div>

                    <div className='assignment-body'>

                        <div className='assignment-pill'>
                            <div className='assignment-type'>
                                <p>Iquidity Analysis</p>
                                <span>General</span>
                            </div>
                            <Button  children={'View'} classname={'darkBtn'} isdisabled={false}/>
                        </div>

                        <div className='assignment-pill'>
                            <div className='assignment-type'>
                                <p>Horizontal Analysis</p>
                                <span className='green-pill'>Quiz</span>
                            </div>
                            <Button  children={'View'} classname={'darkBtn'} isdisabled={false}/>
                        </div>

                        <div className='assignment-pill'>
                            <div className='assignment-type'>
                                <p>Financial Ratios</p>
                                <span>General</span>
                            </div>
                            <Button  children={'View'} classname={'darkBtn'} isdisabled={false}/>
                        </div>

                    </div>

                </div>

            </div>

            <div className='dashboard-right'>

                <div className='top-wrapper'>

                    <div className='assignment-wrapper attendance-wrapper'>

                        <div className='process-heading'>
                            <p className='dash-heading-small'>
                            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 1.875C2.84518 1.875 3.125 2.15482 3.125 2.5V16.1667C3.125 16.4103 3.12549 16.5555 3.13428 16.6631C3.13965 16.7288 3.14673 16.7564 3.14902 16.7638C3.16862 16.801 3.19902 16.8314 3.23622 16.851C3.24361 16.8533 3.27122 16.8604 3.3369 16.8657C3.4445 16.8745 3.58967 16.875 3.83334 16.875H17.5C17.8452 16.875 18.125 17.1548 18.125 17.5C18.125 17.8452 17.8452 18.125 17.5 18.125H3.81148C3.59691 18.125 3.39984 18.125 3.23511 18.1116C3.0575 18.0971 2.86319 18.0638 2.67127 17.9661C2.39686 17.8262 2.17377 17.6031 2.03395 17.3287C1.93616 17.1368 1.90294 16.9425 1.88843 16.7649C1.87497 16.6002 1.87499 16.4031 1.875 16.1885L1.875 2.5C1.875 2.15482 2.15482 1.875 2.5 1.875ZM3.23406 16.8502C3.23409 16.8502 3.23453 16.8504 3.23531 16.8507L3.23406 16.8502ZM3.14977 16.7659C3.14977 16.766 3.14961 16.7656 3.14932 16.7647L3.14977 16.7659ZM17.094 6.21058C17.3459 6.44658 17.3588 6.8421 17.1228 7.09399L13.857 10.5795C13.849 10.5881 13.8403 10.5975 13.831 10.6076C13.7416 10.7046 13.5943 10.8643 13.4006 10.9529C13.2358 11.0282 13.0543 11.0593 12.8739 11.043C12.6617 11.0239 12.4697 10.9223 12.3531 10.8606C12.341 10.8542 12.3297 10.8482 12.3192 10.8427L9.60476 9.43329C9.59485 9.42815 9.58554 9.42331 9.57674 9.41875C9.56996 9.42598 9.56278 9.43364 9.55515 9.44178L6.28942 12.9273C6.05342 13.1792 5.6579 13.1921 5.40601 12.9561C5.15412 12.7201 5.14124 12.3246 5.37725 12.0727L8.64297 8.58713C8.65101 8.57855 8.65969 8.56913 8.669 8.55904C8.75844 8.46206 8.9057 8.30237 9.09945 8.21378C9.26418 8.13845 9.44571 8.10738 9.62612 8.12363C9.8383 8.14275 10.0303 8.24437 10.1469 8.30609C10.159 8.31251 10.1703 8.3185 10.1808 8.32392L12.8952 9.73338C12.9052 9.73852 12.9145 9.74335 12.9233 9.74792C12.9301 9.74069 12.9372 9.73303 12.9449 9.72489L16.2106 6.23934C16.4466 5.98745 16.8421 5.97457 17.094 6.21058Z" fill="#262626"/>
                            </svg>
                                My Progress
                            </p>
                        </div>

                        <div className='assignment-body'>

                            <div className='assignment-pill'>
                                <div className='assignment-type'>
                                    <p>Building Marketing Strategies</p>
                                    <p className='text-4'>50%</p>
                                    <ProgressBar width={"70%"} color={"var(--primary-4)"}/>
                                </div>
                            </div>

                            <div className='assignment-pill'>
                                <div className='assignment-type'>
                                    <p>Building Marketing Strategies</p>
                                    <p className='text-4'>50%</p>
                                    <ProgressBar width={"50%"} color={"var(--primary-4)"}/>
                                </div>
                            </div>

                            <div className='assignment-pill'>
                                <div className='assignment-type'>
                                    <p>Building Marketing Strategies</p>
                                    <p className='text-4'>50%</p>
                                    <ProgressBar width={"20%"} color={"var(--primary-4)"}/>
                                </div>
                            </div>

                        </div>

                    </div> 

                    <div className='assignment-wrapper announcement-wrapper'>

                        <div className='process-heading'>
                            <p className='dash-heading-small'>
                            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 1.875C2.84518 1.875 3.125 2.15482 3.125 2.5V16.1667C3.125 16.4103 3.12549 16.5555 3.13428 16.6631C3.13965 16.7288 3.14673 16.7564 3.14902 16.7638C3.16862 16.801 3.19902 16.8314 3.23622 16.851C3.24361 16.8533 3.27122 16.8604 3.3369 16.8657C3.4445 16.8745 3.58967 16.875 3.83334 16.875H17.5C17.8452 16.875 18.125 17.1548 18.125 17.5C18.125 17.8452 17.8452 18.125 17.5 18.125H3.81148C3.59691 18.125 3.39984 18.125 3.23511 18.1116C3.0575 18.0971 2.86319 18.0638 2.67127 17.9661C2.39686 17.8262 2.17377 17.6031 2.03395 17.3287C1.93616 17.1368 1.90294 16.9425 1.88843 16.7649C1.87497 16.6002 1.87499 16.4031 1.875 16.1885L1.875 2.5C1.875 2.15482 2.15482 1.875 2.5 1.875ZM3.23406 16.8502C3.23409 16.8502 3.23453 16.8504 3.23531 16.8507L3.23406 16.8502ZM3.14977 16.7659C3.14977 16.766 3.14961 16.7656 3.14932 16.7647L3.14977 16.7659ZM17.094 6.21058C17.3459 6.44658 17.3588 6.8421 17.1228 7.09399L13.857 10.5795C13.849 10.5881 13.8403 10.5975 13.831 10.6076C13.7416 10.7046 13.5943 10.8643 13.4006 10.9529C13.2358 11.0282 13.0543 11.0593 12.8739 11.043C12.6617 11.0239 12.4697 10.9223 12.3531 10.8606C12.341 10.8542 12.3297 10.8482 12.3192 10.8427L9.60476 9.43329C9.59485 9.42815 9.58554 9.42331 9.57674 9.41875C9.56996 9.42598 9.56278 9.43364 9.55515 9.44178L6.28942 12.9273C6.05342 13.1792 5.6579 13.1921 5.40601 12.9561C5.15412 12.7201 5.14124 12.3246 5.37725 12.0727L8.64297 8.58713C8.65101 8.57855 8.65969 8.56913 8.669 8.55904C8.75844 8.46206 8.9057 8.30237 9.09945 8.21378C9.26418 8.13845 9.44571 8.10738 9.62612 8.12363C9.8383 8.14275 10.0303 8.24437 10.1469 8.30609C10.159 8.31251 10.1703 8.3185 10.1808 8.32392L12.8952 9.73338C12.9052 9.73852 12.9145 9.74335 12.9233 9.74792C12.9301 9.74069 12.9372 9.73303 12.9449 9.72489L16.2106 6.23934C16.4466 5.98745 16.8421 5.97457 17.094 6.21058Z" fill="#262626"/>
                            </svg>
                                My Progress
                            </p>
                        </div>

                        <div className='assignment-body'>

                            <div className='assignment-pill'>
                                <div className='assignment-type'>
                                    <p>All the students of higher semester...</p>
                                    <span className='green-pill'>Fee</span>
                                </div>
                                <Button  children={'View'} classname={'primaryBtn'} isdisabled={false}/>
                            </div>

                            <div className='assignment-pill'>
                                <div className='assignment-type'>
                                    <p>This document can be attached to the...</p>
                                    <span>Meeting</span>
                                </div>
                                <Button  children={'View'} classname={'primaryBtn'} isdisabled={false}/>
                            </div>

                            <div className='assignment-pill'>
                                <div className='assignment-type'>
                                    <p>You can automatically send email...</p>
                                    <span className='red-pill'>Feedback</span>
                                </div>
                                <Button  children={'View'} classname={'primaryBtn'} isdisabled={false}/>
                            </div>

                        </div>

                    </div>                    

                </div>

                <div className='bottom-wrapper mt-10'>
                    <Calendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    />
                </div>

            </div>
        </div>
    </>
    )
}

export default DashBoard