import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../environment/environment";

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};

/**  getFeedback List API*/
export const getAllFeedbackList = createAsyncThunk(
  "feedback/getAllFeedbackList",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      var search = obj?.searchTitle ?? "";
      var feedbackType = obj?.searchParams ?? "";
      var statusType = obj?.status ?? "";
      var pageSize = obj?.pageSize ?? "";
      var pageNo = obj?.pageNo ?? "";

      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        `/getAllFeedbacks?searchTitle=${search}&searchParams=${feedbackType}&status=${statusType}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllCourseWiseFeedbacks = createAsyncThunk(
  "feedback/getAllCourseWiseFeedbacks",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      var search = obj?.searchTitle ?? "";
      var feedbackType = obj?.searchParams ?? "";
      var statusType = obj?.status ?? "";
      var pageSize = obj?.pageSize ?? "";
      var pageNo = obj?.pageNo ?? "";
      var courseId = obj.courseId ?? "";
      // var proId = obj?.proId ?? "";
      // var proMasterId = obj?.proMasterId ?? "";

      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/courseId/" +
        courseId +
        `/getAllCourseWiseFeedbacks?searchTitle=${search}&searchParams=${feedbackType}&status=${statusType}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllStudent = createAsyncThunk(
  "feedback/getAllStudent",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      var search = obj?.searchTitle ?? "";
      var program = obj?.programName ?? "";
      var batch = obj?.batchName ?? "";
      var pageSize = obj?.pageSize ?? "";
      var pageNo = obj?.pageNo ?? "";

      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        `/getAllStudent?searchTitle=${search}&program=${program}&batch=${batch}&pageSize=${pageSize}&pageNo=${pageNo}`,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllProgramsDetails = createAsyncThunk(
  "feedback/getAllProgramsDetails",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/getAllPrograms",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllBatchByProgramId = createAsyncThunk(
  "feedback/getAllBatchByProgramId",
  async (programId, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/getAllBatchByProgramId?programId=" +
        programId,

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllCourseAdmin = createAsyncThunk(
  "courseData/getAllCourseAdmin",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/programMaster/" +
        obj.proMasterId +
        "/program/" +
        obj.proId +
        "/getAllCourseAdmin",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllSessionByCourseId = createAsyncThunk(
  "feedback/getAllSessionByCourseId",
  async ({ programId, courseId }, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/program/" +
        programId +
        "/course/" +
        courseId +
        "/getAllSessionByCourseId"
        ,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const createFeedback = createAsyncThunk(
  "feedback/createFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();

      obj.organizationId = orgId;
      obj.adminId = adminId;
      const payload = { obj };

      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/createFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getFeedbackDetailById = createAsyncThunk(
  "feedback/getFeedbackDetailById",
  async (feedbackId, thunkAPI) => {
    try {
      // console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/" +
        feedbackId +
        "/getParticularFeedbackData",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/** PUBLISH FEEDBACK */
export const publishFeedback = createAsyncThunk(
  "feedback/publishFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      obj.organizationId = orgId;
      obj.adminId = adminId;
      var feedbackId = obj.feedId;
      const payload = { obj };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${feedbackId}/publishFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

/** GET ATTEMPTED STUDENTS FOR FEEDBACK */
export const getFeedbackAttemptedStudents = createAsyncThunk(
  "feedback/getFeedbackAttemptedStudents",
  async (feedbackId, thunkAPI) => {
    try {
      console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/admin/" +
        adminId +
        "/" +
        feedbackId +
        "/getFeedbackStudents",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      console.log("response-----", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFeedbackStudentAnswer = createAsyncThunk(
  "feedback/getFeedbackStudentAnswer",
  async (obj, thunkAPI) => {
    var feedbackId = obj.feedbackId;
    var studentId = obj.studentId;
    try {
      // console.log("printing data", feedbackId);
      setTokenValues();
      const response = await fetch(
        environment.baseURL +
        "/api/org/" +
        orgId +
        "/student/" +
        studentId +
        "/" +
        feedbackId +
        "/getParticularFeedbackAnswer",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      let data = await response.json();
      // console.log("response-----", data);
      if (response.status === 200) {
        // console.log("inside if condition");
        return data.Data;
      } else {
        // console.log("inside else condition");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const editFeedback = createAsyncThunk(
  "feedback/editFeedback",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      obj.organizationId = orgId;
      obj.adminId = adminId;
      console.log("dfhhhdfhdf ", obj);
      var id = obj.feedId;
      const payload = { obj };
      const response = await fetch(
        `${environment.baseURL}/api/org/${orgId}/admin/${adminId}/${id}/editFeedback`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    isFetching: false,
    feedbackAnswerIsFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    successMessage: "",
    feedbackList: [],
    programDetails: [],
    studentList: [],
    totalStudentsCount: null,
    batchDetail: [],
    AllCourseData: [],
    sessionDetail: [],
    feedbackDetail: null,
    attemptedStudentList: [],
    studentAnswer: [],
    CourseFeedbackList: [],
    CourseQuizList: [],
    feedBackProgramDetails : []
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.feedbackList = [];
      state.feedbackDetail = null;
      return state;
    },
    clearStates: (state) => {
      state.programDetails = [];
      state.batchDetail = [];
      state.AllCourseData = [];
      state.sessionDetail = [];
    }
  },
  extraReducers: {
    [getAllFeedbackList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.feedbackList = payload.Data;
      //   state.feedbackCount = payload.Count;
      return state;
    },
    [getAllFeedbackList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllFeedbackList.pending]: (state) => {
      console.log("inside pending state getAllFeedbackList", state);
      state.isFetching = true;
    },
    [getAllCourseWiseFeedbacks.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.CourseFeedbackList = payload?.Data;
      state.CourseQuizList = payload?.QuizData;
      state.feedBackProgramDetails = payload?.programData
      return state;
    },
    [getAllCourseWiseFeedbacks.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllCourseWiseFeedbacks.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllStudent.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.studentList = payload?.Students;
      state.totalStudentsCount = payload?.totalCount;
      return state;
    },
    [getAllStudent.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllStudent.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getAllProgramsDetails.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.programDetails = payload;
      return state;
    },
    [getAllProgramsDetails.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllProgramsDetails.pending]: (state) => {
      state.isFetching = true;
      console.log("inside pending state student", state);
    },
    [getAllBatchByProgramId.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.batchDetail = payload;
      return state;
    },
    [getAllBatchByProgramId.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllBatchByProgramId.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getAllCourseAdmin.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.AllCourseData = payload;
      return state;
    },
    [getAllCourseAdmin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllCourseAdmin.pending]: (state) => {
      state.isFetching = true;
    },
    [getAllSessionByCourseId.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.sessionDetail = payload;
      return state;
    },
    [getAllSessionByCourseId.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getAllSessionByCourseId.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [createFeedback.fulfilled]: (state, { payload }) => {
      state.createdSuccess = true;
      console.log("printing payload", payload);
      // state.feedbackResponse = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [createFeedback.rejected]: (state, { payload }) => {
      console.log("payload rejected", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createFeedback.pending]: (state) => {
      console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getFeedbackDetailById.fulfilled]: (state, { payload }) => {
      console.log("printing payload id", payload);
      state.feedbackAnswerIsFetching = false;
      // state.isSuccess = true;
      state.feedbackDetail = payload;
      return state;
    },
    [getFeedbackDetailById.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.feedbackAnswerIsFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [getFeedbackDetailById.pending]: (state) => {
      console.log("inside pending state", state);
      state.feedbackAnswerIsFetching = true;
    },

    [publishFeedback.fulfilled]: (state, { payload }) => {
      console.log("printing payload extend", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = "Feedback Published";
      // state.studentList = payload;
      return state;
    },
    [publishFeedback.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message;
      // state.feedbackList = payload;
    },
    [publishFeedback.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },

    [getFeedbackAttemptedStudents.fulfilled]: (state, { payload }) => {
      console.log("printing payload deleting", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.attemptedStudentList = payload;
      // state.studentList = payload;
      return state;
    },
    [getFeedbackAttemptedStudents.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
      // state.feedbackList = payload;
    },
    [getFeedbackAttemptedStudents.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [getFeedbackStudentAnswer.fulfilled]: (state, { payload }) => {
      // console.log("printing payload deleting", payload);
      state.isFetching = false;
      // state.isSuccess = true;
      state.studentAnswer = payload;
      // state.studentList = payload;
      return state;
    },
    [getFeedbackStudentAnswer.rejected]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.Message;
      // state.feedbackList = payload;
    },
    [getFeedbackStudentAnswer.pending]: (state) => {
      // console.log("inside pending state student", state);
      state.isFetching = true;
    },
    [editFeedback.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.studentList = payload;
      return state;
    },
    [editFeedback.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
      // state.feedbackList = payload;
    },
    [editFeedback.pending]: (state) => {
      console.log("inside pending state student", state);
      state.isFetching = true;
    }
  },
});

export const { clearState } = feedbackSlice.actions;

export const { clearStates } = feedbackSlice.actions;

export const feedbackData = (state) => state.feedback;
