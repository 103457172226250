import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from '../environment/environment'

let orgId;
let token;
let adminId;

let setTokenValues = () => {
  orgId = localStorage.getItem("orgId");
  token = localStorage.getItem("token");
  adminId = localStorage.getItem("adminId");
};
export const addGrade = createAsyncThunk(
  "create/addGrade",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      // const obj = {
      //   assignmentId: AssignmentId,
      //   studentId: studentId,
      //   obtainedMarks: obtainedMarks,
      //   courseId: courseId
      // }
      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/addParticularStudentAssignment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        return data.Data

      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addParticularCourseStudentMarks = createAsyncThunk(
  "create/addParticularCourseStudentMarks",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      // const obj = {
      //   assignmentId: AssignmentId,
      //   studentId: studentId,
      //   obtainedMarks: obtainedMarks,
      //   courseId: courseId
      // }
      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/addParticularCourseStudentMarks`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        return data.Data

      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addParticularStudentGroupAssignmentMarks = createAsyncThunk(
  "create/addParticularStudentGroupAssignmentMarks",
  async ({ groupId, obtainedMarks,comment}, thunkAPI) => {
    try {
      setTokenValues();
      const obj = {
        groupId: groupId,
        obtainedMarks: obtainedMarks,
        comment:comment
       
      }
      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/addParticularStudentGroupAssignmentMarks`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        return data.Data

      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const getSpecificAssignment = createAsyncThunk(
  "assignmentBatch/getSpecificAssignment",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + `/api/org/${orgId}/admin/${adminId}/assignment/${id}/getSpecificAssignment`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteSpecificAssignment = createAsyncThunk(
  "create/deleteSpecificAssignment",
  async (id, thunkAPI) => {
    try {
      setTokenValues();

      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/assignment/${id}/deleteSpecificAssignment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        return data.Data

      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const addCourseGroupAssignment = createAsyncThunk(
  "create/addCourseGroupAssignment",
  async ({ name, totalPoint, description, dueDate, instructorDate, courseId, assignmentSubmissionType, programMasterId, programId, isPublished,groupAssignmentArray, sessionId,assignmentType  }, thunkAPI) => {
    try {
      setTokenValues();
      const obj = {
        name: name,
        totalPoint: totalPoint, 
        organizationId: orgId,
        instructorDueDate: instructorDate,
        assignmentDueDate: dueDate,
        programId: programId,
        programMasterId: programMasterId,
        description: description,
        isGroupedAssignment: true,
        assignmentSubmissionType: assignmentSubmissionType,
        isPublished: isPublished,
        groupAssignmentArray :groupAssignmentArray,
        sessionId:sessionId ,
        assignmentType:assignmentType
      }

      console.log(obj);

      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/course/${courseId}/addGroupCourseAssignment`,
    
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        return data.Data

      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createAssignment = createAsyncThunk(
  "create/createAssignment",
  async ({ name, number, brief, dueDate, instructorDate, courseId, submissionType, programMasterId, programId, isPublished, isGroupedAssignment, sessionId,assignmentType }, thunkAPI) => {
    try {
      setTokenValues();
      const obj = {
        name: name,
        totalPoint: number,
        organizationId: orgId,
        courseId: courseId,
        instructorDueDate: instructorDate,
        assignmentDueDate: dueDate,
        programId: programId,
        programMasterId: programMasterId,
        description: brief,
        isGroupedAssignment: isGroupedAssignment,
        assignmentSubmissionType: submissionType,
        isPublished: isPublished,
        assignmentType:assignmentType,
        sessionId:sessionId
      }

      console.log(obj);

      const response = await fetch(`${environment.baseURL}/api/org/${orgId}/admin/${adminId}/course/${courseId}/addCourseAssignment`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ assignmentArray: [obj] }),
        }
      );
      let data = await response.json();
      console.log("response", response);
      if (response.status === 200) {
        return data.Data

      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const getSpecificAssignmentDetails = createAsyncThunk(
  "assignmentBatch/getSpecificAssignmentDetails",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/admin/" + adminId + "/assignment/" + id + "/getSpecificAssignmentDetails",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getOrganizationProgramsWithBatch = createAsyncThunk(
  "assignmentBatch/organizatonProgramsWithBatch",
  async (_, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/adminId/" + adminId + "/getProgramList",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// export const getSpecificCourseGroupAssignments = createAsyncThunk(
//   "assingment/getSpecificCourseGroupAssignments",
//   async (id, thunkAPI) => {
//     try {
//       setTokenValues();
//       const response = await fetch(
//         environment.baseURL + "/api/org/" + orgId + "/admin/" + adminId + `/course/${id}/getSpecificCourseGroupAssignments`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: token,
//           },
//         }
//       );
//       let data = await response.json();
//       console.log("response", data);
//       if (response.status === 200) {
//         return data.Data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (error) {
//       return thunkAPI.rejectWithValue({ error: error.message });
//     }
//   }
// )
;
export const getSpecificAssignmentGroupDetails = createAsyncThunk(
  "assingment/getSpecificAssignmentGroupDetails",
  async (id, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/admin/" + adminId + `/assignment/${id}/getSpecificAssignmentGroupDetails`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAssignmentList = createAsyncThunk(
  "assingment/getAssignmentList",
  async ({courseId,sessionId,type}, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        environment.baseURL + "/api/org/" + orgId + "/admin/" + adminId + `/course/${courseId}/session/${sessionId}/type/${type}/getCourseWiseAssignments`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let data = await response.json();
      console.log("response", data);
      if (response.status === 200) {
        return data.Data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllStudent = createAsyncThunk("groupassignment/getAllStudent", async (courseId,thunkAPI) => {
  try {
    setTokenValues();
    const response = await fetch(
      environment.baseURL + "/api/org/" + orgId + "/admin/" + adminId + `/course/${courseId}/getGroupAssignmentStudents`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    let data = await response.json();
    console.log("response", data);
    if (response.status === 200) {
      return data.Data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
})

// Student List
export const getSpecificGroupAssignmentStudents = createAsyncThunk("groupassignment/getSpecificGroupAssignmentStudents", async (obj,thunkAPI) => {
  try {
    setTokenValues();
    const response = await fetch(
      environment.baseURL + "/api/org/" + orgId + "/admin/" + adminId + `/group/${obj}/getSpecificGroupAssignmentStudents`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    let data = await response.json();
    console.log("response", data);
    if (response.status === 200) {
      return data.Data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
})



export const assignmentSlice = createSlice({
  name: "assignment",
  initialState: {
    programNameList: [],
    assignmentList: [],
    studentList: [],
    AllStudent: [],
    assignmentDetails: [],
    groupAssignmentList:[],
    groupStudentList:[],
    programName: "",
    assignmentName: "",
    isFetching: false,
    isSuccess: false,
    remarksAdded:false,
    isError: false,
    errorMessage: "",
    getSpecificStudentsData:[]
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.remarksAdded=false
      return state;
    },
  },
  extraReducers: {
    [getOrganizationProgramsWithBatch.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.programNameList = payload;
      state.isFetching = false;
      return state;
    },
    [getOrganizationProgramsWithBatch.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getOrganizationProgramsWithBatch.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getAssignmentList.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.assignmentList = payload;
      state.isFetching = false;
      return state;
    },
    [getAssignmentList.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAssignmentList.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    // [getSpecificCourseGroupAssignments.fulfilled]: (state, { payload }) => {
    //   console.log("printing payload", payload);
    //   state.groupAssignmentList = payload;
    //   state.isFetching = false;
    //   return state;
    // },
    // [getSpecificCourseGroupAssignments.rejected]: (state, { payload }) => {

    //   state.isFetching = false;
    //   state.isError = true;
    //   state.errorMessage = payload?.Message || "Something Went Wrong";
    // },
    // [getSpecificCourseGroupAssignments.pending]: (state) => {
    //   // console.log("inside pending state", state);
    //   state.isFetching = true;
    // },

    [getAllStudent.fulfilled]: (state, { payload }) => {
      console.log("printing payload", payload);
      state.AllStudent = payload;
      state.isFetching = false;
      return state;
    },
    [getAllStudent.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getAllStudent.pending]: (state) => {
      state.isFetching = true;
    },
    [createAssignment.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true
      return state;
    },
    [createAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [createAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [addCourseGroupAssignment.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true
      return state;
    },
    [addCourseGroupAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addCourseGroupAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteSpecificAssignment.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true
      return state;
    },
    [deleteSpecificAssignment.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [deleteSpecificAssignment.pending]: (state) => {
      state.isFetching = true;
    },
    [getSpecificAssignmentDetails.fulfilled]: (state, { payload }) => {

      state.studentList = payload;
      state.isFetching = false;
      return state;
    },
    [getSpecificAssignmentDetails.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificAssignmentDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [getSpecificAssignmentGroupDetails.fulfilled]: (state, { payload }) => {

      state.groupStudentList = payload;
      state.isFetching = false;
      return state;
    },
    [getSpecificAssignmentGroupDetails.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificAssignmentGroupDetails.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },


    [getSpecificAssignment.fulfilled]: (state, { payload }) => {

      state.assignmentDetails = payload;
      state.isFetching = false;
      return state;
    },
    [getSpecificAssignment.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificAssignment.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },
    [addGrade.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.remarksAdded=true
      state.isSuccess = true
      return state;
    },
    [addGrade.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addGrade.pending]: (state) => {
      // state.isFetching = true;
    },
    [addParticularCourseStudentMarks.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true
      return state;
    },
    [addParticularCourseStudentMarks.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addParticularCourseStudentMarks.pending]: (state) => {
      // state.isFetching = true;
    },
    [addParticularStudentGroupAssignmentMarks.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.remarksAdded=true
      return state;
    },
    [addParticularStudentGroupAssignmentMarks.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.remarksAdded=false
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [addParticularStudentGroupAssignmentMarks.pending]: (state) => {
      // state.isFetching = true;
      state.remarksAdded=false
    },
    [getSpecificGroupAssignmentStudents.fulfilled]: (state, { payload }) => {
      state.getSpecificStudentsData = payload;
      state.isFetching = false;
      return state;
    },
    [getSpecificGroupAssignmentStudents.rejected]: (state, { payload }) => {

      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.Message || "Something Went Wrong";
    },
    [getSpecificGroupAssignmentStudents.pending]: (state) => {
      // console.log("inside pending state", state);
      state.isFetching = true;
    },

  },
});


export const { clearState } = assignmentSlice.actions;

export const assignmentSelector = (state) => state.assignment;
