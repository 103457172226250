import React, { useState } from "react";
import "./SingleSelectStyle.css";

const SingleSelect = (props) => {
  const [checkedValue, setValueChange] = useState("");
  const handleOnChangeInput = (e) => {
    setValueChange(e.target.value);
  };

  return (
    <div className="singleselect mt-30 col-3">
      {props.item.option.map((item, index) => (
        <label
          key={index}
          className={
            checkedValue === item.value
              ? "singleselectlabel active"
              : "singleselectlabel"
          }
        >
          <input
            // onClick={() => setActive(0)}
            onChange={handleOnChangeInput}
            className="singleselect-btn"
            type="radio"
            checked={checkedValue === item.value}
            value={item.value}
            disabled={props?.isDisabled}
          />
          <span className="singleselectradio" />
          {item.value}
        </label>
      ))}
    </div>
  );
};

export default SingleSelect;
