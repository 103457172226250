import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
// import PrimaryButtonImg from '../../globalComponents/buttons/primaryButtonImg/PrimaryButtonImg'
import './FeedbackReportStyle.css'
const FeedbackReport = ({ isOpen, setIsOpen, data,type }) => {

    return (
        <div className={`popupOuter ${isOpen ? 'active' : ''}`}>
            <div className="feedbackReportContainer">
                <div className="popupHeader">
                    <p className="popupTitle">{type} Report</p>
                    <img onClick={() => setIsOpen(false)} src="/assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    {/* <div className="popupBodyHeader">
                        <div className="popupBodyHeaderLeft">
                            <p className="heading">Applied Finance</p>
                            <p className="reportText">Report of students who have attempted the Feedback &bull; <span className="dangerText">{data.length} Students</span></p>
                        </div>
                        <div className="popupBodyHeaderRight">
                            <PrimaryButtonImg isdisabled={data.length > 0 ? false : true} classname={'secondaryBtn'} children={'Export CSV'} img={'/assets/icons/download-01.svg'} />
                        </div>
                    </div> */}
                    <div className="popupTableOuter">
                        <table className="popupTable" cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th>Students</th>
                                    <th>Cohort</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.Student?.firstName + " " + item?.Student?.lastName}</td>
                                        <td>{item?.Student?.ProgramDetail?.batch}</td>
                                        <td><PrimaryButton onClick={() => window.open(`/feedbackAnswer/${item?.feedbackId}/${item?.Student?.id}?type=${type}`)} classname={'primaryBtnSmall'} children={'View'} /></td>
                                    </tr>
                                ))}
                                {/* <tr>
                                    <td>Anand Krishnan</td>
                                    <td>Cohort - 1</td>
                                    <td><PrimaryButton onClick={() => navigate('/feedbackanswer')} classname={'primaryBtnSmall'} children={'View'} /></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackReport