import React from 'react'
import PrimaryButton from '../../globalComponents/buttons/primaryButton/PrimaryButton'
import './ConfirmationPopupStyle.css'

const ConfirmationPopup = ({title,boldText,text,btnType,btnText,isOpen, onClick}) => {

    return (
        <div className={`popupOuter ${isOpen ? 'active' : ''}`}>
            <div className="confirmationPopupContainer">
                <div className="popupHeader">
                    <p className="popupTitle">{title}</p>
                    <img onClick={onClick} src="/assets/icons/x-close.svg" alt="" />
                </div>
                <div className="popupBody">
                    <p className="popupText"> <b>{boldText}</b>{' '}{text} </p>
                    <div className="popupBtnGrp">
                        <PrimaryButton onClick={onClick} classname={btnType} children={btnText}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPopup