import React from 'react'
import PrimaryButton from '../../components/globalComponents/buttons/primaryButton/PrimaryButton'
import CustomDatePicker from '../../components/globalComponents/customDatePicker/CustomDatePicker'
import Dropdown from '../../components/globalComponents/dropdown/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createAnnouncement, announcementSliceFunction, clearState } from '../../reducers/announcementSlice'
import { useState } from 'react'
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import ErrorToast from '../../components/popups/Toast/ErrorToast'
import PageLoader from '../../components/globalComponents/PageLoader/PageLoader'
import { useEffect } from 'react'



const CreateAnnouncement = () => {

    const dispatch = useDispatch();
    const Navigate = useNavigate()
    const [isShowError, setIsShowError] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [announcementFor, setAnnouncementFor] = useState('')
    const [announcementType, setAnnouncementType] = useState('')
    const [announcementDate, setAnnouncementDate] = useState('')
    const [desc, setDesc] = useState('')
    const { isFetching, isSuccess } = useSelector(announcementSliceFunction)
    console.log(isFetching, "11112222")

    const optionsList = ["Fee", "Club and Event", "Feedback", "Leave Request"];

    const typeList = ["Faculty", "Student"];
    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const handleSubmit = () => {
        if (announcementType !== '' && announcementDate !== '' && announcementFor !== '' && removeTags(desc) !== '' ) {
            let obj = {
                announcementType: announcementType,
                announcementDate: announcementDate,
                announcementDescription: desc,
                announcementFor: announcementFor

            }
            dispatch(createAnnouncement(obj))
            // dispatch(clearState())
          


        } else {
            // alert("Please enter the all required field")
            setIsShowError(true);
            setErrMessage("fields must not be empty !")
           
            console.log(removeTags(desc),"wfdwfh");
        }

    }

    useEffect(() => {
        if (isSuccess) {
            dispatch(clearState());
            Navigate("/announcement", { exact: true })
        }
        // eslint-disable-next-line
    }, [isSuccess])

  

    return (
        <>
            {isFetching && <PageLoader />}
            <div className="pageHeader">
                <ErrorToast
                    show={isShowError}
                    setShow={() => setIsShowError(false)}
                    message={errMessage}
                />
                <div className="backBtn" onClick={() => Navigate(-1)}>
                    <img src="../assets/icons/back-btn.svg" alt="" />
                    Back to Page
                </div>
                <div className="pageTogglers">
                    <div className="pageToggler active">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.50004 19C6.33337 19 6.25004 19 6.16671 19C5.25004 18.9167 4.50004 18.3334 4.16671 17.4167C4.16671 17.3334 4.08337 17.25 4.08337 17.0834L2.75004 11.8334C2.75004 11.8334 2.75004 11.8334 2.75004 11.75C1.75004 10.9167 1.08337 9.66669 1.08337 8.33336C1.08337 5.91669 3.08337 4.00002 5.41671 4.00002H8.58337C9.66671 4.00002 11.1667 3.50002 12.9167 2.50002C13.8334 2.00002 14.3334 1.75002 14.75 1.83336C15.1667 1.91669 15.5 2.08336 15.75 2.41669C16 2.83336 16 3.25002 16 4.33336V4.91669C17.6667 5.25002 18.9167 6.66669 18.9167 8.41669C18.9167 10.1667 17.6667 11.5834 16 11.9167V12.5C16 13.5 16 14 15.75 14.4167C15.5 14.75 15.1667 15 14.75 15C14.25 15.0834 13.8334 14.8334 12.9167 14.3334C11.3334 13.5 10 13 9.00004 12.9167V16.6667C9.00004 16.9167 9.00004 17 9.00004 17.0834C8.91671 18.1667 8.00004 19 6.91671 19.1667C6.83337 18.9167 6.66671 19 6.50004 19ZM6.50004 17.6667C6.66671 17.6667 6.75004 17.6667 6.83337 17.6667C7.33337 17.5834 7.75004 17.25 7.75004 16.75C7.75004 16.6667 7.75004 16.5834 7.75004 16.4167V12.6667H5.41671C5.00004 12.6667 4.58337 12.5834 4.16671 12.5L5.25004 16.75C5.25004 16.9167 5.33337 16.9167 5.33337 17C5.50004 17.4167 5.83337 17.6667 6.25004 17.6667C6.25004 17.75 6.33337 17.6667 6.50004 17.6667ZM9.00004 11.5C10.25 11.5834 11.75 12.1667 13.5834 13.0834C14.0834 13.3334 14.5834 13.5834 14.75 13.6667C14.75 13.6667 14.8334 13.6667 14.8334 13.5834C14.8334 13.5 14.8334 13 14.8334 12.4167V4.25002C14.8334 3.66669 14.8334 3.16669 14.75 3.08336C14.75 3.08336 14.6667 3.00002 14.5834 3.00002C14.5 3.00002 14 3.25002 13.5 3.58336C11.75 4.58336 10.1667 5.08336 8.91671 5.16669V11.5H9.00004ZM5.41671 5.16669C3.66671 5.16669 2.33337 6.58336 2.33337 8.33336C2.33337 10.0834 3.75004 11.5 5.50004 11.5H7.83337V5.16669H5.41671ZM16 6.16669V10.5834C17 10.3334 17.6667 9.41669 17.6667 8.41669C17.6667 7.41669 17 6.41669 16 6.16669Z" fill="#006CD4" />
                        </svg>
                        Announcement
                    </div>
                </div>
                <div className='pageHeaderBtnGrop'>

                </div>
            </div>
            <div className="createAssignmentContainerOuter">
                <div className="createAssignmentContainer">
                    <div className="createAssignmentForm">
                        <Dropdown label={'Announcements for'} isImportant={true} options={typeList} onChange={setAnnouncementFor} />
                        <Dropdown label={'Announcements type'} isImportant={true} options={optionsList} onChange={setAnnouncementType} />
                        <CustomDatePicker label={'Announcements Date'} isImportant={true} onChange={setAnnouncementDate} minDate={new Date} />
                        <div>Description</div>
                        <SunEditor
                            height='143'
                            onChange={setDesc}
                            setOptions={{
                                buttonList: [
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "list",
                                        "align",
                                        "fontSize",
                                        "formatBlock",
                                        "fullScreen",
                                        "preview",
                                        "image"
                                    ]]
                            }}
                        />
                    </div>

                    <div className="createAssignmentFooter">
                        <PrimaryButton classname={'primaryBtn'} children={'Submit'} onClick={handleSubmit} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default CreateAnnouncement