import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllStudent } from "../../../reducers/assignmentSlice";
import { assignmentSelector } from "../../../reducers/assignmentSlice";
import Checkbox from "../../globalComponents/checkboxes/Checkbox";
import "./AddStudentsPopupStyle.css";
const AddStudentsPopup = ({
  isOpen,
  view,
  setIsOpen,
  values,
  data,
  setValues,
  ind,
  onlyShowData,
  getSpecificStudentsData,
  getStudent,
  disabled,
}) => {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const { AllStudent } = useSelector(assignmentSelector);

  useEffect(() => {
    if (!onlyShowData && view === "createGroupAssignment") {
      dispatch(getAllStudent(courseId));
    }
    // eslint-disable-next-line
  }, []);

  // const selectAllStudents = () => {
  //     // if(AllStudent.length==values.length){}
  //      if(values[ind]){
  //     if (AllStudent?.Students && (AllStudent?.Students?.length != values[ind].length || values[ind].length == 0)) {
  //         const arr= [];

  //         AllStudent?.Students?.forEach(element => {
  //             arr.push(element.id)
  //         });

  //         setValues(val => ({...val,[ind]: []}));
  //             // onChange(prev => ({ ...prev, [ind]: [...prev[ind], e.target.value] }))
  //             // onChange(prev => ({ ...prev, [ind]:[e.target.value] }))
  //             setValues(val => ({...val,[ind]: [...arr]}))

  //     }
  //     else {
  //         setValues(val => ({...val,[ind]: []}))
  //     }
  // }
  // else{
  //     const arr= [];
  //     AllStudent?.Students?.forEach(element => {
  //         arr.push(element.id)
  //     });
  //     setValues(val => ({...val,[ind]: [...arr]}))
  // }
  // }
  console.log(AllStudent);
  // const user = useContext(UserContext);

  // console.log(user)

  return (
    <>
      {view === "createGroupAssignment" && (
        <div className={`popupOuter ${isOpen && "active"}`}>
          {onlyShowData ? (
            <div className="addStudentPopupContainer">
              <div className="popupHeader">
                <p className="title">Students List (Group)</p>
                <img
                  onClick={() => setIsOpen(false)}
                  src="/assets/icons/x-close.svg"
                  alt=""
                />
              </div>
              <div className="popupBody">
                <div className="addStudentTableContainer">
                  <table
                    className="addStudentTable"
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th>Students</th>
                        <th>Gmail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getSpecificStudentsData?.length > 0 &&
                        getSpecificStudentsData?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.Student?.firstName +
                                " " +
                                item.Student?.lastName}
                            </td>
                            <td>{item.Student?.officialEmail}</td>
                          </tr>
                        ))}
                      {/* <tr>
                                            <td><Checkbox/></td>
                                            <td>Anand Krishnan</td>
                                            <td>Anand Krishnan@gmail.com</td>
                                        </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="addStudentPopupContainer">
              <div className="popupHeader">
                <p className="title">Add Students (Group)</p>
                <img
                  onClick={() => setIsOpen(false)}
                  src="/assets/icons/x-close.svg"
                  alt=""
                />
              </div>
              <div className="popupBody">
                <div className="addStudentTableContainer">
                  <table
                    className="addStudentTable"
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        {/* <th>{!Object.keys(values).length &&<Checkbox disabled={false} value={values} onChange={setValues} select={true}  hide={true} disable={true} />}</th> */}
                        <th></th>

                        <th>Students</th>
                        <th>Gmail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllStudent?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {
                              <Checkbox
                                value={values}
                                label={item?.Student.id}
                                onChange={setValues}
                                hide={true}
                                ind={ind}
                                getStudent={getStudent}
                                disable={disabled}
                              />
                            }
                          </td>
                          <td>
                            {item?.Student.firstName +
                              " " +
                              item?.Student.lastName}
                          </td>
                          <td>{item?.Student.officialEmail}</td>
                        </tr>
                      ))}
                      {/* <tr>
                                    <td><Checkbox/></td>
                                    <td>Anand Krishnan</td>
                                    <td>Anand Krishnan@gmail.com</td>
                                </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {view === "Student List" && (
        <div className={`popupOuter ${isOpen && "active"}`}>
          <div className="addStudentPopupContainer">
            <div className="popupHeader">
              <p className="title">Students List ({data?.length})</p>
              <img
                onClick={() => setIsOpen(false)}
                src="/assets/icons/x-close.svg"
                alt=""
              />
            </div>
            <div className="popupBody">
              <div className="addStudentTableContainer">
                <table
                  className="addStudentTable"
                  cellPadding={0}
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th>Students</th>
                      <th>Gmail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 &&
                      data?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item?.firstName}{" "}
                            {item?.lastName !== null ? item?.lastName : ""}
                          </td>
                          <td>{item?.officialEmail}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddStudentsPopup;
