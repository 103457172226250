import React, { useEffect, useState } from "react";
import "./UploadBox.css";
// import * as filestack from 'filestack-js';
// const client = filestack.init('AXgXGi3jhSyiQo8NOKNfWz');

const UploadBox = (props) => {
  const [selectedFile, setSelectedFile] = useState("Upload Media");
  const [isFilePicked, setIsFilePicked] = useState(false);
  // const [progress, setProgress] =useState('');
  const hiddenFileInput = React.useRef(null);
  // const token = {};

  // const onProgressUpdate = (obj) => {
  //   setProgress(obj.totalPercent);
  // }

  useEffect(() => {
    if(props.value){
      if(props.value.answer === ''){
        return;
      }
      setSelectedFile(props.value.answer);
      setIsFilePicked(true);
    }
}, [props?.value]);

  const onClickUpload = (event) => {
    if (event.target.files.length > 0) {
      // const files = event.target.files[0];
      props.onLoading(true);
      // client.upload(files,  { onProgress : onProgressUpdate }, {}, token)
      // .then(res => {
      //   setSelectedFile(event.target.files[0].name);
      //   setIsFilePicked(true);
      //   props.onLoading(false);
      //   props.onUpload(res.url);
      // })
      // .catch(err => {
      //   props.onLoading(false);
      //   setIsFilePicked(false);
      // });
    }
  };

  const onClickDeleteUpload = () => {
    setSelectedFile("Upload Media");
    setIsFilePicked(false);
    props.onUpload('');
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="uploadbox_wrapper">
      <div className={isFilePicked ? "upload_shell active" : "upload_shell"}>
        {!isFilePicked && (
          <input
            className="uploadbox"
            onChange={onClickUpload}
            ref={hiddenFileInput}
            id= {props.item.id}
            type="file"
            disabled={props.isDisabled}
          />
        )}
        <div className="upload_inner">
          <label htmlFor="uploadbox" className="active">
            {selectedFile}
          </label>

          <div className="uploadicons">
            {!isFilePicked ? (
              <img
                onClick={handleClick}
                src="../..//assets/icons/upload.svg"
                alt=""
              />
            ) : (
              <img
                onClick={onClickDeleteUpload}
                src="../..//assets/icons/close.svg"
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadBox;
